import moment from 'moment';
import Cookies from "universal-cookie";
import { PST_ZONE } from "Pages/Order/Order/utility/util";

import React, { useContext, useEffect, useState } from 'react';
import { CSVLink } from "react-csv";
import ReactPaginate from 'react-paginate';
import {
  details, exportTimeHistory, updateCheckInOutEntry, usersAdminHistory
} from "./../../../api/clock";
// Services
import { getUserLookup } from "./../../../api/user";
import CalendarIcon from './../../../Assets/images/calendar.svg';
import CloseIcon from './../../../Assets/images/close.svg';
import ExportIcon from './../../../Assets/images/export.svg';
import SearchIcon from './../../../Assets/images/search.svg';
// Images
import SortIcon from './../../../Assets/images/sort.svg';
import Date from "./../../../Components/FormControl/Date/Date";
import Select from "./../../../Components/FormControl/Select/Select";
// Components
import Header from './../../../Components/Header/Header';
import ClockDetail from './../../../Components/Modal/ClockDetail/ClockDetail';
// Context
import { AppContext } from "./../../../Context/AppContext";
// Services
// import Clock from './../../../Services/Clock/Clock';
// import Users from './../../../Services/Users/Users';
import styles from './index.module.scss';
import { orderDesignerBulkPrintCard } from 'api/order';
import ReactExport from "react-data-export";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const TimeClockHistory = () => {
  const [clockDetails, setClockDetails] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [fromDate, setFromDate] = useState(
    moment().startOf("isoWeek").subtract(1, 'days').format("DD-MM-YYYY")
  );
  const [toDate, setToDate] = useState(
    moment().endOf("isoWeek").subtract(1, 'days').format("DD-MM-YYYY")
  );

  const [clockDetailsList, setClockDetailsList] = useState([]);
  const [sortType, setSortType] = React.useState("desc");
  const [totalCount, setTotalCount] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [deleteClockEntryId, setDeleteClockEntryId] = useState([]);

  const [employeeList, setEmployeeList] = React.useState([]);
  const [selectedEmployee, setSelectedEmployee] = React.useState(0);
  const [exportHeader, setExportHeader] = React.useState([]);
  const [exportData, setExportData] = React.useState([]);

  const { showToastMessage, setIsLoading } = useContext(AppContext);
  // const clockService = new Clock();
  // const userService = new Users();

  useEffect(() => {
    handleGetEmployeeLookup();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleGetEmployeeLookup = async () => {
    // const response = await userService.getUserLookup();
    const response = await getUserLookup("true");

    if (response && response.status === 200) {
      setEmployeeList(response.data.data);
    } else showToastMessage(undefined, response.data.message, "error", false);
  };

  const handleGetClockDetailsList = async (event) => {
    if (event) event.preventDefault();
    handleExportTimeHistory(event);

    let reqBody = {
      user_id: selectedEmployee,
      sortBy: "first_name",
      sortType,
      currentPage,
      start_date: fromDate,
      end_date: toDate,
    };
    setIsLoading(true);

    // const response = await clockService.usersAdminHistory(reqBody);
    const response = await usersAdminHistory(reqBody);

    if (response && response.status === 200) {
      setClockDetailsList(response.data.data.rows);
      setTotalCount(response.data.data.count);
    } else showToastMessage(undefined, response.data.message, "error", false);

    setIsLoading(false);
  };

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const handleClearSearch = async (event) => {
    event.preventDefault();

    setFromDate(moment().startOf("isoWeek").subtract(1, 'days').format("DD-MM-YYYY"));
    setToDate(moment().endOf("isoWeek").subtract(1, 'days').format("DD-MM-YYYY"));
    setSelectedEmployee(0);
    setCurrentPage(0);

    let reqBody = {
      user_id: 0,
      sortBy: "first_name",
      sortType,
      currentPage: 0,
      start_date: moment().startOf("isoWeek").subtract(1, 'days').format("DD-MM-YYYY"),
      end_date: moment().endOf("isoWeek").subtract(1, 'days').format("DD-MM-YYYY"),
    };
    setIsLoading(true);

    handleExportTimeHistory(event);

    // const response = await clockService.usersAdminHistory(reqBody);
    const response = await usersAdminHistory(reqBody);

    if (response && response.status === 200) {
      setClockDetailsList(response.data.data.rows);
      setTotalCount(response.data.data.count);
    } else showToastMessage(undefined, response.data.message, "error", false);

    setIsLoading(false);
  };

  const handleGetClockDetails = async (event, id) => {
    event.preventDefault();

    if (id) {
      setIsLoading(true);
      //   const response = await clockService.details(id);
      const response = await details(id);

      if (response && response.status === 200) {
        setClockDetails(response.data.data);
      } else showToastMessage(undefined, response.data.message, "error", false);

      setIsLoading(false);
      setShowModal(true);
    }
  };
 
  const cookies = new Cookies();
  const timezone = cookies.get("defaultTimezone") ? cookies.get("defaultTimezone") : PST_ZONE ;
  const onChangeValue = async (data) => {  
    let changeRequestClockObj = { ...clockDetails };
    
    let index = clockDetails.clock_time.findIndex(
      (clockTimeObj) => clockTimeObj.id === data.id
      );
      if (index > -1) {
     
        if(data.type==="regular_hours"){
          changeRequestClockObj.clock_time[index]["regular_hours"] = parseFloat(data.regular_hours);
        } 
        if(data.type==="ot1"){
          changeRequestClockObj.clock_time[index]["ot1"] = parseFloat(data.ot1);
        }
        if(data.type==="ot2"){
          changeRequestClockObj.clock_time[index]["ot2"] = parseFloat(data.ot2);
        }

      let changeDay;
      let changeTime = moment(data.time).startOf("minute").format("HH:mm");

      if (data.type === "in") {
        changeDay = moment
          .unix(changeRequestClockObj.clock_time[index].clock_in)
          .tz(timezone)
          .format("YYYY-MM-DD");
        let time = moment
          .tz(changeDay + " " + changeTime, timezone)
          .utc()
          .unix("X");
        changeRequestClockObj.clock_time[index]["clock_in"] = time;
      } else if (data.type === "out") {
        changeDay = moment
          .unix(changeRequestClockObj.clock_time[index].clock_out)
          .tz(timezone)
          .format("YYYY-MM-DD");
        let time = moment
          .tz(changeDay + " " + changeTime, timezone)
          .utc()
          .unix("X");
        changeRequestClockObj.clock_time[index]["clock_out"] = time;
      }

      setClockDetails(changeRequestClockObj);
    }
  };

  const handleExportTimeHistory = async (event) => {
    if (event) event.preventDefault();

    setIsLoading(true);
    let reqBody = {
      user_id: selectedEmployee,
      start_date: fromDate,
      end_date: toDate,
    };

    // const response = await clockService.exportTimeHistory(reqBody);
    const response = await exportTimeHistory(reqBody);

    if (response && response.status === 200) {
      setExportHeader(response.data.data.header);
      setExportData([response.data.data]);
    } else showToastMessage(undefined, response.data.message, "error", false);

    setIsLoading(false);
  };

  useEffect(() => {
    handleGetClockDetailsList();
  }, [sortType, currentPage]); // eslint-disable-line react-hooks/exhaustive-deps

  //Manage the edit & delete timesheet report 
  const handleChangeClockDetails = async (event) => {
    event.preventDefault();
    let flag = 0;
    let errorMsg="";
    let indexArr = ["1ˢᵗ","2ⁿᵈ","3ʳᵈ","4ᵗʰ","5ᵗʰ","6ᵗʰ","7ᵗʰ","8ᵗʰ","9ᵗʰ","10ᵗʰ"]
    const entryValidation = clockDetails.clock_time.map((item,index)=>{
      //in this condition, we are checking the check-out time must be greater than the check-in time
      if(item.clock_out!=0 && item.clock_in>item.clock_out && !flag==1){
        flag=1
        errorMsg = `${indexArr[index]} entry check-out time is must be greater than check-in time`
      }else{
        if(index+1 < clockDetails.clock_time.length && !flag==1){
          //in this condition, we are checking the check-in is greater than the above entry check out 
          if(clockDetails.clock_time[index].clock_out>clockDetails.clock_time[index+1].clock_in){
            flag=1
            errorMsg = `${indexArr[index+1]} entery check-in time must be greater than ${indexArr[index]} entry check-out time`
          }
        }
      }
    })
    if(flag===1 && !errorMsg==""){
      showToastMessage(undefined, errorMsg, "error", false);
    }else{
      const postData = {
        deleted_id:deleteClockEntryId,
        ...clockDetails
      }
      const response = await updateCheckInOutEntry(postData);
  
      if (response && response.status === 200) {
      showToastMessage(undefined, response.data.message, "success", false);
      //get updated clock details list
      handleGetClockDetailsList();
      setShowModal(false)
      } else showToastMessage(undefined, response.data.message, "error", false);
      setIsLoading(false);
    }
  };
  
  return (
    <React.Fragment>
      <Header title="Timesheet Report" />

      <div id="MainContent" className="main-content">
        <div className="row">
          <div className="col-sm-4 col-xs-12">
            <Select
              label="Employee"
              value={selectedEmployee}
              onChange={(data) => setSelectedEmployee(data)}
              options={employeeList}
              disabled={false}
            />
          </div>
          <div className="col-sm-2 col-xs-12">
            <Date
              label="Weekly From Date"
              value={fromDate}
              dateFormat="DD-MM-YYYY"
              type="date"
              fieldIcon={CalendarIcon}
              onChange={(fromDate) =>
                setFromDate(moment(fromDate).format("DD-MM-YYYY"))
              }
            />
          </div>
          <div className="col-sm-2 col-xs-12">
            <Date
              label="Weekly To Date"
              value={toDate}
              dateFormat="DD-MM-YYYY"
              type="date"
              fieldIcon={CalendarIcon}
              onChange={(toDate) =>
                setToDate(moment(toDate).format("DD-MM-YYYY"))
              }
            />
          </div>
          <div className="col-sm-3 col-xs-8">
            <div className="td-actions pt-4">
              <a
                href="/"
                onClick={(e) => handleGetClockDetailsList(e)}
                className={`link cursor-pointer ${styles.searchIconWrapper}`}
              >
                <img className={styles.searchIcon} src={SearchIcon} alt="" />
              </a>
              <a
                href="/"
                onClick={(e) => handleClearSearch(e)}
                className={`link cursor-pointer ml-3 ${styles.clearIconWrapper}`}
              >
                <img className={styles.clearIcon} src={CloseIcon} alt="" />
              </a>
            </div>
          </div>
          <div className="col-sm-1 col-xs-4 pull-right">
            <div className="td-actions pt-4 text-right">
                
                <ExcelFile 
                  filename="timeHistory" 
                  element={<span className={`link cursor-pointer ml-3 ${styles.searchIconWrapper}`}><img className={styles.searchIcon} src={ExportIcon} alt="" /></span>}>
                  <ExcelSheet dataSet={exportData} name="timeHistory"/>
                </ExcelFile>
            </div>
          </div>
        </div>
        <div className="table-responsive my-5">
          <table className="table">
            <thead>
              <tr>
                <th className="align-top">
                  <div
                    className="d-flex align-items-center flex-nowrap cursor-pointer"
                    onClick={(e) => {
                      sortType === "desc"
                        ? setSortType("asc")
                        : setSortType("desc");
                    }}
                  >
                    <span className="text-nowrap">Employee Name</span>
                    <span
                      className={sortType === "desc" ? styles.desc : styles.asc}
                    >
                      <img
                        className="ml-2 cursor-pointer position-absolute"
                        src={SortIcon}
                        alt="Sorting"
                      />
                    </span>
                  </div>
                </th>
                {clockDetailsList &&
                  clockDetailsList.length > 0 &&
                  clockDetailsList[0].dates.map((dateObj, index) => {
                    return (
                      <th className="text-center" key={index}>
                        <span className="d-block">
                          {dateObj.substring(0, 3)}
                        </span>
                        <span className="d-inline-block">
                          {dateObj.substring(3)}
                        </span>
                      </th>
                    );
                  })}
                <th className="text-center">Regular Hours</th>
                <th className="text-center">Total OT (1.5)</th>
                <th className="text-center">Total OT (2)</th>
                <th className="text-center">Total Hours</th>
              </tr>
            </thead>
            <tbody>
              {clockDetailsList &&
                clockDetailsList.length > 0 &&
                clockDetailsList.map((clockDetailsObj, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        {clockDetailsObj.first_name} {clockDetailsObj.last_name}
                      </td>
                      {clockDetailsObj.clock_time.length > 0 &&
                        clockDetailsObj.clock_time.map(
                          (clockTimeObj, index) => {
                            return (
                              <td
                                className="text-center cursor-pointer"
                                onClick={(event) =>
                                  handleGetClockDetails(event, clockTimeObj.id)
                                }
                                key={index}
                              >
                                {clockTimeObj.duration
                                  ? clockTimeObj.duration
                                  : "-"}
                              </td>
                            );
                          }
                        )}
                      <td className="text-center">
                        {clockDetailsObj.total_regular_hours
                          ? clockDetailsObj.total_regular_hours
                          : "-"}
                      </td>
                      <td className="text-center">
                        {clockDetailsObj.total_ot1
                          ? clockDetailsObj.total_ot1
                          : "-"}
                      </td>
                      <td className="text-center">
                        {clockDetailsObj.total_ot2
                          ? clockDetailsObj.total_ot2
                          : "-"}
                      </td>
                      <td className="text-center">
                        {clockDetailsObj.total_duration
                          ? clockDetailsObj.total_duration
                          : "-"}
                      </td>
                    </tr>
                  );
                })}
              {clockDetailsList && clockDetailsList.length === 0 && (
                <tr>
                  <td className="text-center" colSpan="8">
                    <span className="no-data-message">No data available.</span>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {totalCount > 10 && (
            <ReactPaginate
              previousLabel={"‹"}
              nextLabel={"›"}
              pageCount={parseInt(Math.ceil(totalCount / 10))}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
            />
          )}
        </div>
      </div>

      <ClockDetail
        showModal={showModal}
        clockDetails={clockDetails}
        handleCloseModal={(event) => {
          event.preventDefault();
          setShowModal(false);
          setDeleteClockEntryId([])
        }}
        isEditable={true}
        isDeleteable={true}
        handleSubmitClick={handleChangeClockDetails}
        onChangeValue={(data)=>onChangeValue(data)}
        deleteDataArray={(data)=>setDeleteClockEntryId(data)}
        handleRemove={(data)=>setClockDetails({...clockDetails,clock_time:data})}
      />
    </React.Fragment>
  );
};

export default TimeClockHistory;
