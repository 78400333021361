import { AppContext } from "Context/AppContext";
import { useOrder } from "Context/OrderContext/useOrder";
import { useFormikContext } from "formik";
import { DEFAULT_STORE_ID } from "Helpers/Constants";
import { useContext, useEffect, useState } from "react";
import ProductsApi from "Services/Products/Products";
export function useAddonsCall() {
  const { setIsLoading, showToastMessage } = useContext(AppContext);
  const [render, setRender] = useState(0);
  const [currentSelected, setCurrentSelected] = useState(null);
  const [productsToAdd, setProductsToAdd] = useState([]);
  const [productList, setProductList] = useState([]);
  const [menu, setMenu] = useState([]);
  const [firstLoad, setFirstLoad] = useState(true);
  const { orderState, addAddonRecipent } = useOrder();
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  let itemsPerPage = 30;
  const productsService = new ProductsApi();
  const [sort, setSort] = useState(null);
  const { values } = useFormikContext();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      // await getMenu();
      await getProducts();
      setIsLoading(false);
      setFirstLoad(false);
    })();
  }, []);

  useEffect(() => {
    if (!firstLoad) {
      (async () => {
        setIsLoading(true);
        await getProducts();
        setIsLoading(false);
      })();
    }
  }, [render]);

  const getMenu = async () => {
    try {
      const response = await productsService.getMenus();
      setMenu(response.data.data);
      setPage(0);
    } catch (error) {
      console.log(error);
    }
  };

  const getProducts = async (query = "", page = 0) => {
    let filters = [];
    let sortBy = [];
    filters.push({
      id: "type",
      value: "ADD_ONS",
    });

    // if (values.delivery_information.order_type !== 1) {
    //   if (!values.delivery_information.is_local) {
    //     filters.push({
    //       id: "is_local_only",
    //       value: "0",
    //     });
    //   }
    // }

    sortBy.push({
      id: "sequence_number",
      asc: "asc",
    });

    if (sort) {
      sortBy.push(sort);
    }
    
    let postData = {
      currentPage: page,
      itemsPerPage,
      globalSearch: query,
      sortBy: sortBy,
      filters: filters,
    };

    //delivery type then pass zipcode and store id if pickup order
    if(values.delivery_information.order_type === 0){
      postData['zipcode'] = values.delivery_information.zipcode;
    }else{
      postData['selectedStore'] = values.billing_info?.zip_code_store_id;
    }
    const response = await productsService.getProducts(postData);
    if (page) {
      setProductList([...productList, ...response.data.data.rows]);
    } else {
      setProductList(response.data.data.rows);
      setTotalCount(response.data.data.count);
    }
  };
  return {
    menu,
    setPage,
    render,
    setRender,
    setIsLoading,
    getProducts,
    setSort,
    page,
    totalCount,
    productsToAdd,
    productList,
    showToastMessage,
    orderState,
    setCurrentSelected,
    addAddonRecipent,
    currentSelected,
    setProductsToAdd,
  };
}
