import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { DateTime } from "luxon";
// SCSS
import styles from "./index.module.scss";
// Component
import Select from "../../FormControl/Select/Select";
import DatePickerWithInput from "Components/NewOrder/DatePicker/DatePickerWithInput";
import ChangeToOndemandApi from "Services/ChangeToOndemand/ChangeToOndemand";
import { AppContext } from "Context/AppContext";
import TranferOnfleetToBurq from "./transferOnfleetToburq";
import { useHistory } from "react-router";
import { getAsapDeliveryTime } from "api/order";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const ChangetoOnDemand = ({ showModal, pageTitle, handleCancelClick, close, orderDetails }) => {
  const { showToastMessage, lastOrderEditPath, setIsLoading } = useContext(AppContext);
  const [timeslectionDropdown, setTimeslectionDropdown] = useState(false);
  const [options, setOptions] = useState([]);
  const ChangeToOndemandService = new ChangeToOndemandApi();
  let history = useHistory();

  const [deliveryDate, setDeliveryDate] = useState(null)
  const OnChangeHander = async (day) => {
    if (day) { setTimeslectionDropdown(true) }
    let bodySend = {
      address_type_id: orderDetails.order_recipients[0].address_type_id,
      delivery_date: moment(day).format("MM/DD/YYYY"),
      full_address: orderDetails.order_recipients[0].recipient_address,
      order_by_time: moment().unix(),
      zip_code: orderDetails.order_recipients[0].zipcode
    }
    setDeliveryDate(bodySend.delivery_date)
    setTimeSelected("none")
    setDateSelected("none")
    await getAsapDeliveryTimeOption(bodySend)
    await ChangeToOndemandService.getChanageToOndemandDetails(bodySend).then((data) => {
      if (data && data.status === 200) {
        // if (data.data.data.costList) {
          // setchangetoOnDemandData(data.data.data)
          setTimeslectionDropdown(false)
          setSelectTime(null)
          setBurqOptions(data.data.data)
          // } else {
          // setchangetoOnDemandData(null)
          // setSelectTime(null)
          // setTimeslectionDropdown(false)
          // showToastMessage(undefined, "Delivery is currently unavailable for the selected date", "error", false);
        // }
      } else {
        showToastMessage(undefined, "Delivery slot are currently not available", "error", false);
      }
    }).catch((error) => {
      showToastMessage(undefined, error.data.message, "error", false);
    })
  }

  //get asap option data from API and Set that into options
  const getAsapDeliveryTimeOption = async (postdata) => {
    const defaultOption = {
      id:0,
      name: "ASAP",
      delivery_time_type:"asap",
      data:{
        id:0,
        delivery_matrix_id: 0,
        delivery_order_type: null,
        delivery_time_type:"asap",
        delivery_time:"asap",
        burq_quote_id: "asap",
      }
    }
    const data = await getAsapDeliveryTime(postdata);
    const asapOption = data.data.data;
    if (data && data.status === 200 && asapOption?.costList.length > 0) {
      defaultOption.name = "ASAP " + " " + "for $" + asapOption.costList[0].price;
      defaultOption.data = asapOption.costList[0];
      defaultOption.delivery_time_type = asapOption.costList[0]?.delivery_time_type
      setOptions([{...defaultOption}]);
    } else {
      setOptions([{...defaultOption}]);
    }
    return true;
  };

  //Set other burq delivery options into options
  const setBurqOptions = (data)=>{
    let options = [];
    data.costList.map((cost, idx) => {
      let delivery_info = {
        id: idx+1,
        name: cost.delivery_time_type + " " + "for $" + cost.price,
        delivery_time_type: cost.delivery_order_type,
        data: cost
      }
      options.push(delivery_info)
    })
    setOptions((prev)=>[...prev, ...options])
    return true
  }

  const changeOndemandToBurq = async (event) => {
    setIsLoading(true)
    let sendBody = {
      order_id: orderDetails.id,
      order_type: orderDetails.order_type ?? 0,
      delivery_type_id: orderDetails.delivery_type_id ?? "",
      is_local_order: orderDetails.is_local_order ?? 1,
      is_subscribe_order: orderDetails.is_subscribe_order ?? 0,
      is_burq_order: 0,
      delivery_date: moment(deliveryDate).format("YYYY-MM-DD") ?? "",
      delivered_time_id: order_info.delivery_time ?? "",
      delivered_time: order_info.delivery_time_type === "asap" ? "asap" :`${order_info.delivery_time_type} ${DateTime.
        fromSeconds(order_info.delivery_time).
        setZone("UTC").toFormat("h':'mm a")}`,
      delivery_date_time: order_info.delivery_time === "asap" ? moment(deliveryDate).format("YYYY-MM-DD") : 
        `${moment(deliveryDate).format("YYYY-MM-DD")} ${DateTime.
        fromSeconds(order_info.delivery_time).
        setZone("UTC").toFormat("H':'mm")}`,
      delivery_time_type: order_info.delivery_time_type ?? "",
      burq_quote_id: order_info.burq_quote_id ?? "",
    }
    await ChangeToOndemandService.updateChangeToOndemand(sendBody).then(data => {
      if (data && data.status === 200) {
        showToastMessage(undefined, "Order type updated successfully", "success", false, 9000)
        close(false)
        setOpen(false)
        setIsLoading(false);
        history.push(lastOrderEditPath);
      } else {
        showToastMessage(undefined, "Something went wrong", "error", false);
        setOpen(false)
        setIsLoading(false);
      }
    }).catch((error) => {
      showToastMessage(undefined, error.data.message, "error", false);
      setIsLoading(false);
      setOpen(false)
      close(false)
    })
  }
  const [selectTime, setSelectTime] = useState(null)
  // console.log('selectTime: ', selectTime);
  const handleDeliveryMatrix = (count) => {
    setSelectTime(count)
    setTimeSelected("none")
    setDateSelected("none")
  }
  const [isTimeSelect, setTimeSelected] = useState("none")
  const [isDateSelected, setDateSelected] = useState("none")
  const [order_info, setOrderInfo] = useState(null)
  const [open, setOpen] = useState(false)
  const handleValidation = (event) => {
    if (deliveryDate !== null) {
      setDateSelected("none")
      let order_details;
      options.filter((data, idx) => {
        if (selectTime == idx) {
          order_details = data.data
        }
      })
      setOrderInfo(order_details)
      setTimeSelected("block")
    } else {
      setTimeSelected("block")
      setDateSelected("block")
    }
    if (selectTime !== null && deliveryDate === null) {
      setTimeSelected("none")
      setDateSelected("block")
    }else if(selectTime !== null && deliveryDate !== null){
      setTimeSelected("none")
      setDateSelected("none")
      setOpen(true)
    }
  }
  return (
    <React.Fragment>
      {selectTime !== null ? <TranferOnfleetToBurq
        open={open}
        setOpen={setOpen}
        changeOndemandToBurq={changeOndemandToBurq}
      /> : ""}
      <Dialog
        open={showModal}
        TransitionComponent={Transition}
        onClose={handleCancelClick}
        classes={{
          paper: styles.modalDialog,
        }}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
        PaperProps={{
          style: {
            minHeight: "28vh",
            minWidth: "45vw",
            overflow: "visible",
          },
        }}>
        <DialogContent className={`${styles.modalContent} `}>
          <div className={`text-center ${styles.modalBody}`}>
            <h5 className={`text-center ${styles.modalTitle}`}>
              {pageTitle}
            </h5>
            <div className={`row px-1 py-1 `}>
              <div className="col-md-6" style={{ textAlign: "left", position: "relative" }}>
                <DatePickerWithInput
                  label="Delivery Date"
                  blockDates={[]}
                  value={deliveryDate ? deliveryDate : null}
                  onChange={OnChangeHander}
                  datePickerclass={styles.datePicker}
                  showDateclass={true}
                />
                <span className="mt-3" style={{ color: "red", display: isDateSelected, position: "absolute" }}>Please select delivery date</span>
              </div>
              <div className="col-md-6" style={{ textAlign: "left", position: "relative" }}>
                {options || timeslectionDropdown ?
                  (
                    timeslectionDropdown ?
                      <Select
                        label="Calculating..."
                        value={""}
                        onChange={""}
                        options={[]}
                      /> :
                      <Select
                        label="Delivery Time"
                        value={selectTime}
                        onChange={handleDeliveryMatrix}
                        options={options}
                      />
                  )
                  : <Select
                    label="Delivery Time"
                    value={selectTime}
                    onChange={handleDeliveryMatrix}
                    options={[]} />}
                <span style={{ color: "red", display: isTimeSelect, position: "absolute" }}>Please select delivery time</span>
              </div>
            </div>
            <div>
              <a
                data-dismiss="modal"
                onClick={handleValidation}
                className={`btn gray-btn d-inline-block mb-3 mr-2`}
                style={{ color: "white", marginTop: 53 }}>
                Save
              </a>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default ChangetoOnDemand;
