import React from "react";
import Date from "../../../Components/FormControl/Date/Date";
// import CalendarIcon from "../../../Assets/images/calendar.svg";
// import Text from "../../../Components/FormControl/Text/Text";
// import Search from "../../../Assets/images/productSearch.svg";
// import moment from "moment";
import { DateTime } from "luxon";
import { PST_ZONE } from "Pages/Order/Order/utility/util";
import { DATE_TYPE } from "Helpers/Constants";
const SearchItem = (props) => {
  // const [fromDate, setFromDate] = useState("");
  // const [toDate, setToDate] = useState("");
  // console.log(props);
  const isDisabled = props.timeValue === "Weekly" || props.timeValue === "Monthly" || props.timeValue === "Custom" ? false : true;

  return (
    <div className="row mb-3 ml-2 mr-2">
      <div className="col-6">
        <Date
          type="date"
          label="From Date"
          // fieldIcon={CalendarIcon}
          value={DateTime.fromISO(props.startDate)
          .toFormat("ccc LLL dd, yyyy")}
          required={true}
          dateFormat="ddd MMM DD, YYYY"
          onChange={(data) => props.changeDuration(data._d, DATE_TYPE.FROM_DATE)}
          disabled={isDisabled}
          // checkPassDate={true}
        />
      </div>

      <div className="col-6">
        <Date
          type="date"
          label="To Date"
          // fieldIcon={CalendarIcon}
          value={DateTime.fromISO(props.endDate).toFormat("ccc LLL dd, yyyy")}
          required={true}
          dateFormat="ddd MMM DD, YYYY"
          onChange={(data) => props.changeDuration(data._d, DATE_TYPE.TO_DATE)}
          disabled={isDisabled}
          // checkPassDate={true}
        />
      </div>
      <div className="col-4">
        {/* <button className="btn gray-btn d-inline-block mb-1 mr-2 mt-3">
          Search
        </button> */}
      </div>
    </div>
  );
};

export default SearchItem;
