import React from "react";
import styles from "./ProductsDrawer.module.scss";
import noImageInProduct from "../../Assets/images/noImageInProduct.png";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
// import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
const ProductCard = (props) => {
  return (
    <React.Fragment>
      <div className={styles.card}>
        <img
          src={props.item.product_images[0]?.image ?? noImageInProduct}
          className={styles.image}
          alt=""
        />
        {props.isVarianNeeded && props.item.isSelected && (
          <CheckCircleIcon
            color="green"
            style={{ marginTop: "15px" }}
            className={`${styles.selectedBox}`}
          ></CheckCircleIcon>
        )}
        {props.item.is_on_sale && (
          <div className={`${styles.saleCircle}`}>Sale</div>
        )}
        {props.item.is_non_local_only && (
          <div className={`${styles.localCircle}`}>Non Local</div>
        )}
        <div className={styles.price}>{props.item.priceRange}</div>
      </div>
      {props.isVarianNeeded ? (
        <div className={styles.name}>{props.item.title}</div>
      ) : (
        <div className={`d-flex`}>
          <div className={styles.name} style={{ width: "80%" }}>
            {props.item.title}
          </div>
          {props.item.isSelected ? (
            <CheckCircleIcon
              color="disabled"
              style={{ marginTop: "15px" }}
              className={`${styles.circlechecked}`}
            ></CheckCircleIcon>
          ) : (
            <div className={styles.circle} style={{ marginTop: "15px" }}></div>
          )}
        </div>
      )}
      {/* <div className={styles.name}>{props.item.title}</div> */}
      {props.isVarianNeeded ? (
        <div className={styles.cardBottom}>
          <div className={styles.category}>
            {props.item.defalt_variation?.title}
          </div>
          <div className={styles.commission}>
            Comm. ${props.item.defalt_variation?.commision}
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default ProductCard;
