export function postReqProduct(productState) {

      let product_cat_data = [];
      productState.product_categories?.map((d, i) => (
            product_cat_data.push(d.category_hierarchy_id)
      ));


      let product_similar_data = [];
      productState.similar_products?.map((d, i) => (
            product_similar_data.push(d.id)
      ));


      let product_image_data = productState.product_images;
      // productState.product_images?.map((d, i) => {

      //       if (productState.setProductAllData != "" && productState.setProductAllData != undefined) {
      //             //for if any image uploaded before
      //             let selectedImage = productState.setProductAllData.product_images;
      //             let indexOfarrayOfProduct = selectedImage.findIndex(
      //                   (selectedImage) => {
      //                         return selectedImage.image === d.image;
      //                   }
      //             );
      //             if (indexOfarrayOfProduct == -1) {
      //                   product_image_data.push(d.image);
      //             }
      //       } else {
      //             //for new image upload
      //             product_image_data.push(d.image);
      //       }

      // });


      let product_delete_data = [];
      productState.delete_product_image?.map((d, i) => (
            product_delete_data.push(d.image)
      ));

      let product_notes_data = [];
      if (productState.product_notes !== undefined && productState.product_notes.length > 0) {
            productState.product_notes?.map((d, i) => {
                  if (d.user_id !== '' && d.notes !== '' && d.date) {
                        let tempNote;
                        if (d.id !== undefined && d.id != null) {
                              tempNote = {
                                    "user_id": d.user_id,
                                    "notes": d.notes,
                                    "date": new Date(d.date).getTime() / 1000,
                              }
                        } else {
                              tempNote = {
                                    "id": d.id,
                                    "user_id": d.user_id,
                                    "notes": d.notes,
                                    "date": new Date(d.date).getTime() / 1000,
                              }
                        }
                        return product_notes_data.push(tempNote);
                  }
            });
      }

      if (productState.banners !== undefined && productState.banners.length > 0) {
            productState.banners?.map((d, i) => {
                  if (productState.banners[i]['id'] === null || productState.banners[i]['id'] === undefined || productState.banners[i]['id'] === 0) {
                        delete productState.banners[i]['id'];
                        delete productState.banners[i]['parent_id'];
                  }
            });
      }
      let product_banner_data = (productState.banners !== undefined && productState.banners.length > 0) ? productState.banners : [];

      delete productState.seo['id']
      let product_seo_data = productState.seo;

      if (productState.product_variations !== undefined && productState.product_variations.length > 0) {
            productState.product_variations?.map((d, i) => {
                  if (productState.product_variations[i]['id'] == null || productState.product_variations[i]['id'] === undefined || productState.product_variations[i]['id'] === 0) {
                        delete productState.product_variations[i]['id'];
                  }
                  if (productState.product_variations[i]['status'] === "") {
                        productState.product_variations[i]['status'] = 1;
                  }
                  if (productState.product_variations[i]['inventory'] === "") {
                        productState.product_variations[i]['inventory'] = 100;
                  }
                  if (productState.product_variations[i]['is_default_selected'] === "") {
                        productState.product_variations[i]['is_default_selected'] = 0;
                  }
                  if (productState.product_variations[i]['is_sold_out'] === "") {
                        productState.product_variations[i]['is_sold_out'] = 0;
                  }
            });
      }

      let product_variation_data = productState.product_variations;

      let post_Req = {
            type: productState.product_primary_info.type,
            inventory_remaining: (productState.product_primary_info.inventory_remaining !== "") ? productState.product_primary_info.inventory_remaining : null,
            title: productState.product_primary_info.title,
            sequence_number: (productState.product_primary_info.sequence_number !== "") ? productState.product_primary_info.sequence_number : 0,
            sku_id: productState.product_primary_info.sku_id,
            keywords: productState.product_primary_info.keywords,
            customer_note: productState.product_primary_info.customer_note,
            description: productState.product_primary_info.description,
            care_description: productState.product_primary_info.care_description,
            declaimer: productState.product_primary_info.declaimer,
            did_you_know: productState.product_primary_info.did_you_know,
            after_care: productState.product_primary_info.after_care,
            delivery_info: productState.product_primary_info.delivery_info,
            lead_time: (productState.product_primary_info.lead_time !== "") ? productState.product_primary_info.lead_time : 0,
            non_local_lead_time: (productState.product_primary_info.non_local_lead_time !== "") ? productState.product_primary_info.non_local_lead_time : 0,

            is_eligible_for_commission: (productState.product_flag_info_value.is_eligible_for_commission !== "") ? productState.product_flag_info_value.is_eligible_for_commission ?? false : false,
            is_limited_availability: (productState.product_flag_info_value.is_limited_availability !== "") ? productState.product_flag_info_value.is_limited_availability ?? false : false,
            is_sold_out: (productState.product_flag_info_value.is_sold_out !== "") ? productState.product_flag_info_value.is_sold_out ?? false : false,
            temporary_unavailable: (productState.product_flag_info_value.temporary_unavailable !== "") ? productState.product_flag_info_value.temporary_unavailable ?? false : false,
            is_exclude_from_search: (productState.product_flag_info_value.is_exclude_from_search !== "") ? productState.product_flag_info_value.is_exclude_from_search ?? false : false,
            is_local_only: (productState.is_local_only !== "") ? productState.is_local_only ?? false : false,
            is_non_local_only: (productState.is_non_local_only !== "") ? productState.is_non_local_only ?? false : false,

            slug: productState.product_navigation_info.slug,
            blurb: productState.product_navigation_info.blurb,
            status: productState.product_primary_info.status,

            product_categories: product_cat_data,
            similar_products: product_similar_data,
            product_images: product_image_data,
            deleted_images: product_delete_data,
            banners: product_banner_data,
            seo: product_seo_data,
            product_variations: product_variation_data,
            product_notes: product_notes_data,
      };

      return { ...post_Req };
}