import React from "react";
import Select from "../../../../Components/FormControl/Select/Select";
import Text from "../../../../Components/FormControl/Text/Text";
import AddIcon from "../../../../Assets/images/add.svg";
import styles from "../index.module.scss";
import DeleteIcon from "./../../../../Assets/images/delete.svg";

const AddRequestRow = (props) => {
  if (props?.requestInventory?.length && props?.requestInventory?.length > 0) {
    return props?.requestInventory?.map((val, idx) => {
      return (
        <tr
          key={val.index}
          className={props.viewRequest ? styles.viewRequestColor : ""}
        >
          <td className="text-center col-md-4 col-xs-12 p-0 pr-3" style={{ width: "40%" }}>
            {!props.viewRequest ? (
              <div className="">
                {props.isLoading ? (
                  <Select
                    label="Loading..."
                    value={""}
                    onChange={""}
                    options={[]}
                  />
                ) : (
                  <Select
                    placeholder={"Select Raw Material"}
                    parentClass={"text-left"}
                    options={props.rawMaterials}
                    value={val?.inventory_raw_material_id}
                    onChange={(data) =>
                      props.onChange("requestInventory", data, idx)
                    }
                    key={val?.inventory_raw_material}
                    disableUnderline={false}
                    disabled={props.viewRequest}
                  />
                )}
              </div>
            ) : (
              <div className="pl-3">
                <Text
                  value={val?.inventory_raw_material}
                  required={true}
                  id={"rawmaterials"}
                  disabled={true}
                />
              </div>
            )}
          </td>
          <td className="text-center p-0">
            <Text
              value={val?.raw_matrial_unit}
              required={true}
              id={"unit"}
              disabled={true}
            />
          </td>
          <td className="text-center p-0 pl-3">
            <Text
              value={val?.inventory_available_quantity}
              required={true}
              id={"avalableQty"}
              disabled={true}
            />
          </td>
          <td className="text-center p-0 pl-3">
            <Text
              value={val.raw_material_price}
              required={true}
              id={"price"}
              disabled={true}
            />
          </td>
          <td className="text-center p-0 pl-3">
            <Text
              value={val.requested_quantity}
              required={true}
              onChange={(qty) => {
                ///^[0-9]*(\.[0-9]{0,2})?$/
                let regex = /^\d*\.?\d*$/;
                if (regex.test(qty)) {
                  props.setValues("requested_quantity", qty, idx);
                } else {
                  props.setValues("requested_quantity", "", idx);
                }
              }}
              id={"requestQty"}
              disabled={props.viewRequest}
            />
          </td>
          {!props.viewRequest && (
            <td className="d-flex justify-content-start">
              {idx === 0 ? (
                <>
                  {props?.requestInventory?.length && props?.requestInventory?.length > 1 && (
                    <div onClick={() => props.delete(val)}>
                      <span>
                        <img
                          className={`cursor-pointer ${styles.icon}`}
                          src={DeleteIcon}
                          alt=""
                        />
                      </span>
                    </div>
                  )}
                  <div onClick={props.add}>
                    <span className={`link cursor-pointer ${styles.iconWrapper} ml-3`}>
                      <img className={styles.icon} src={AddIcon} alt="" />
                    </span>
                  </div>
                </>
              ) : (
                <div onClick={() => props.delete(val)}>
                  <span>
                    <img
                      className={`cursor-pointer ${styles.icon}`}
                      src={DeleteIcon}
                      alt=""
                    />
                  </span>
                </div>
              )}
            </td>
          )}
          {props?.viewRequest && (
            <td className="text-center p-0 pl-3">
              <Text
                value={val?.filled_quantity}
                required={true}
                id={"status"}
                disabled={true}
              />
            </td>
          )}
        </tr>
      );
    });
  }
};

export default AddRequestRow;
