import { Box, Tab, Tabs, Typography } from '@material-ui/core';
import React from 'react';
import { withRouter } from 'react-router';
// // Components
import Header from '../../../Components/Header/Header';
import RevenueBreakdownReport from './RevenueBreakdownReport';
import RevenueHouseAccountBreakdownReport from './RevenueHouseAccountBreakdownReport';

const RevenueBreakdown = (props) => {
  // Handle Tab Button Click
  const [tabId, setTabId] = React.useState(0);
  const handleTabChange = (event, newTabId) => {
    setTabId(newTabId);
  };

  return (
    <React.Fragment>
      <Header title="Revenue Breakdown" />
      <div id="MainContent" className="main-content">
        <div className="container-fluid order-container">
          <Tabs value={tabId} onChange={handleTabChange} className="mb-4">
            <Tab key={0} id={0} label={`Store Revenue`} />
            <Tab key={1} id={1} label={`House Account Revenue`} />
          </Tabs>

          <TabPanel tabId={tabId} index={0}>
            <RevenueBreakdownReport />
          </TabPanel>

          <TabPanel tabId={tabId} index={1}>
            <RevenueHouseAccountBreakdownReport />
          </TabPanel>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(RevenueBreakdown);

function TabPanel(props) {
  const { children, tabId, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={tabId !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {tabId === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
