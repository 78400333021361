import React from "react";
// SCSS
import styles from "./index.module.scss";
// COMPONENTS
import CheckBox from "../FormControl/CheckBox/CheckBox";

import { useProduct } from "../../Context/ProductCrud/useProduct";

function DeliveryInfo(props) {
  
  const {
    productState,
  } = useProduct();

  const flagData = productState.is_local_only ?? false;
  const nonLocalOnly = productState.is_non_local_only ?? false;

  return (
    <div className={`${styles.productsWrapper}`}>
      <div className="">
        <div className="">
          <div className={styles.title} id="is-local">Delivery Info</div>
          <div className={`row`}>
            <div className={`col-md-12 text-left mt-2 mb-4 ${styles.checkBox}`}>
              {/* <CheckBox
                label="Local Only"
                value={flagData}
                required={true}
                submitStatus={props.submitStatus}
                onChange={(data) => props.onChange(data)}
                id="is_local_only"
                disabled={props.isDisableFields}
              /> */}
              
              <CheckBox
                label="Available for nonlocal delivery"
                value={nonLocalOnly}
                required={true}
                submitStatus={props.submitStatus}
                onChange={(data) => props.setIsNonLocal(data)}
                id="is_non_local_only"
                disabled={props.isDisableFields}
               />
               <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeliveryInfo;
