import { callPostApi } from "api/api";
import { AppContext } from "Context/AppContext";
import { OrderConfigContext } from "Context/OrderConfigContext";
import { useFormikContext } from "formik";
import moment from "moment";
import { useContext, useMemo, useState } from "react";
import { useOrderAttributeProvider } from "../context/order";
import {
  CUSTOM_ANYTIME,
  pstToUTCDateTime,
  timeStamptoPSTime,
  utcDateTimeToTime,
  CUSTOM_DEFAULT_TIME
} from "../utility/util";
import { useDate } from "./useDates";

export function useDelivery() {
  const { module } = useOrderAttributeProvider();
  
  const [enableTimeEdit, setEnableTimeEdit] = useState(
    module === "edit-order" ? false : true
  );
  const { showToastMessage } = useContext(AppContext);
  const formik = useFormikContext();
  let subscription_order = formik.values.is_subscribe_order === 1;
  const config = useContext(OrderConfigContext);
  const [matrix, setMatrix] = useState([]);
  const [timeslectionDropdown, setTimeslectionDropdown] = useState(false);
  const { blockoutDates, tooltipDays, upchargeDatesArray } = useDate();
  let show = useMemo(() => {
    return {
      two_field: formik.values.delivery_information.delivery_type_id > 2,
      all_field: formik.values.delivery_information.delivery_type_id === 4,
    };
  }, [formik.values.delivery_information.delivery_type_id]);

  function CommonDeliveryFunc(timingResponse,day,dat,upcharge,isBruq =false,customObjPush=false){
    //Check rollover
    if(timingResponse?.data?.data?.costList?.length === 0){
      customObjPush = true
    }
    if (
      timingResponse?.data?.data?.changeDeliveryDateToNextDay 
      // timingResponse?.data?.data?.costList?.length === 0
    ) {
      customObjPush = true
      showToastMessage(
        undefined,
        `Delivery is currently unavailable for the selected date`,
        "error",
        false
      );
      setMatrix([])
      return;
    }
    
    // Create dropdown value
    let matrix = timingResponse.data.data.costList.map((mat) => {
      let charge = mat.price;
      let actual_delivered_time = mat.delivery_time;
      let delivery_time_type = mat.delivery_time_type;
      let id = mat.id ?? 0;
      let pst_time = timeStamptoPSTime(mat.delivery_time);
      let delivery_date_time = pstToUTCDateTime(day, mat.delivery_time);
      let utc_time = utcDateTimeToTime(delivery_date_time);
      let name = `${delivery_time_type} ${pst_time} for $${charge}`;
      let delivery_time = `${delivery_time_type} ${utc_time}`;
      if (delivery_time_type === "Anytime") {
        name = `${delivery_time_type} for $${charge}`;
      }
      let burq_quote_id = mat?.burq_quote_id ?? ""
      if (id === 0 && mat?.burq_quote_id && mat?.burq_quote_id != "") {
        name = `${delivery_time_type} for $${charge}`;
        id = mat?.delivery_time
      }
      return {
        charge,
        delivery_time_type,
        id,
        delivery_date_time,
        delivery_time,
        name,
        actual_delivered_time,
        burq_quote_id
      };
    });
    // (formik.values.delivery_information.address_type_id === 1 || formik.values.delivery_information.address_type_id === 2) &&  delivery_time_type === "Anytime"
    if (formik.values.delivery_information.address_type_id > 2) {
      matrix = matrix?.filter(
        (c) => (c.delivery_time_type !== "Anytime")
      );
    }
    // console.log("matrix=>", matrix);
    // console.log("matrix=>",matrix);
    // console.log("tempObj=>",tempObj);
  //  if(!customObjPush){
  //    let tempObj = {
  //      actual_delivered_time: '',
  //      charge: 0,
  //      delivery_date_time: "",
  //      delivery_time: "",
  //      delivery_time_type: "",
  //      id: -1,
  //      name: "Custom",
  //    }
  //    matrix.push(tempObj);
  //  }
    if(isBruq){
      setMatrix((prev)=>[...prev,...matrix])
    }else{
      setMatrix(matrix);
    }
    if (!enableTimeEdit) {
      setEnableTimeEdit(true);
    }

    formik.setValues((v) => {
      return {
        ...v,
        delivery_information: {
          ...dat,
          ...upcharge,
          delivery_time_id: isBruq ? v?.delivery_information?.delivery_time_id:null ?? null,
          delivery_date_time: isBruq ? v?.delivery_information?.delivery_date_time: null ?? "",
          delivery_time: isBruq ? v?.delivery_information?.delivery_time:null ?? "",
          delivery_time_type: isBruq ? v?.delivery_information?.delivery_time_type:null ?? "",
          charge: isBruq ? v?.delivery_information?.charge:null ?? 0,
          actual_delivered_time:isBruq ? v?.delivery_information?.actual_delivered_time:null ?? "",
        },
      };
    });

  }
  const handleDateChange = async (day) => {
    //Pickup Order
    //console.log("day data",day)
    //console.log("day delivery info",formik.values)
    let dat = setDate(day);
    if (formik.values.delivery_information.order_type.toString() === "1") {
      setEnableTimeEdit(true);
      dat.upcharge_id = 0;
      dat.upcharge_amount = 0;
      formik.setValues((v) => {
        return {
          ...v,
          delivery_information: {
            ...dat,
            delivery_time_id: null,
            // delivery_date_time: "",
            // delivery_time: "",
            delivery_time_type: "",
            burq_quote_id:"",
            burq_quote_expires:""
          },
        };
      });
      return;
    }

    //Check if zip code and address type avaialable
    if (
      !formik.values.delivery_information.address_type_id ||
      formik.values.delivery_information.zipcode.length === 0
    ) {
      showToastMessage(
        undefined,
        `Please fill out address type and zip code before selecting delivery date.`,
        "error",
        false
      );
      return;
    }

    //Check Product is added before delivery matrix is selected.
    let length = 0;
    formik.values.recipients.forEach((element) => {
      length = length + element.products.length;
    });

    //comment for no validation apply for csr of lead time & holiday cutoff
    // if (length > 0 && formik.values.custom_order === 0) {
    //   //Check Holiday Cutoff
    //   if (!subscription_order) {
    //     if (holidayApplicable(day)) {
    //       return;
    //     }
    //   }

    //   //Check Leadtime
    //   let lap = await isLeadTimeApplicable(day);
    //   if (lap) {
    //     showToastMessage(
    //       undefined,
    //       `Delivery is not unavailable for the selected date due to unavailability of product.`,
    //       "error",
    //       false
    //     );
    //   }
    // }

    //Check if upcharge
    let upcharge = {};
    // console.log(upchargeDatesArray);
    let up = upchargeDatesArray.find(
      (c) =>
        moment(c.date).format("MM-DD-YYYY") === moment(day).format("MM-DD-YYYY")
    );
    if (up) {
      upcharge = {
        upcharge_id: up?.id,
        upcharge_amount: up?.charge,
      };
    } else {
      upcharge = {
        upcharge_id: 0,
        upcharge_amount: 0,
      };
    }

    formik.setValues((v) => {
      return {
        ...v,
        delivery_information: {
          ...dat,
        },
      };
    });


    //get all option for Burq data fatching with delivery matrix
    // Check if it's not subscription order, order type is delivery, delivery address type is delivery service
    // delivery date time no blank, address line1, city, state,zip availabe 
    // and it's only for create order
    // and order is for single recipient
    
    //get total product price
    // Calculate total price of products
    let totalProductPrice = 0;
    await formik?.values?.recipients.map(async (recipientsObj) => {
      await recipientsObj?.products.map((productObj) => {
        totalProductPrice += productObj.total_price
      })
    })
    let allCurrentData = formik.values;
     let addressData = ""
    if(allCurrentData?.delivery_information?.delivery_type_id == 2 && 
      allCurrentData?.delivery_information?.address_line_1 != "" && 
      allCurrentData?.delivery_information?.zipcode != "" && 
      allCurrentData?.delivery_information?.state != "" && 
      allCurrentData?.delivery_information?.city != "" && 
      allCurrentData?.is_subscribe_order == 0 &&
      allCurrentData?.delivery_information?.order_type == 0 && 
      allCurrentData?.recipients?.length < 2 &&
      totalProductPrice <= 400 &&
      module !== 'edit-order'
      ){
         //console.log("match all condition for burq")
           //Burq implementation. only single recipient, only for delivery order
            addressData = (allCurrentData?.delivery_information?.address_line_1).trim()+", "
            addressData = addressData+(allCurrentData?.delivery_information?.city).trim()+", "
            addressData = addressData+(allCurrentData?.delivery_information?.state).trim()+", "
            addressData = addressData+(allCurrentData?.delivery_information?.zipcode).trim()+", "
      }
      setTimeslectionDropdown(true)
    //Get Delivery Matrix
    try {
      const timingResponse = await callPostApi({
        url: "delivery-matrix/list-by-date",
        body: {
          zip_code: formik.values.delivery_information.zipcode,
          address_type_id: formik.values.delivery_information.address_type_id,
          delivery_date: moment(day).format("MM/DD/YYYY"),
          order_by_time: moment().unix(),
          // notCheckRolloverFalg: true,
          full_address:addressData,
          address_2: formik.values.delivery_information.address_line_2 ?? "",
          attn: formik.values.delivery_information.attn ?? ""
        },
      });
      if(timingResponse?.data?.data){
        CommonDeliveryFunc(timingResponse,day,dat,upcharge,false,timingResponse?.data?.data.costList.length > 0 ? false :true)
      }
      setTimeslectionDropdown(false);
      let timingBurqResponse;
      if(!timingResponse?.data?.data.nonLocal){
        if(module !== 'edit-order'){
           timingBurqResponse = await callPostApi({
            url: "delivery-matrix/list-by-date-burq",
            body: {
              zip_code: formik.values.delivery_information.zipcode,
              address_type_id: formik.values.delivery_information.address_type_id,
              delivery_date: moment(day).format("MM/DD/YYYY"),
              order_by_time: moment().unix(),
              notCheckRolloverFalg: true,
              full_address:addressData,
              address_2: formik.values.delivery_information.address_line_2 ?? "",
              attn: formik.values.delivery_information.attn ?? ""
            },
          });
          if(timingBurqResponse?.data?.data){
            CommonDeliveryFunc(timingBurqResponse,day,dat,upcharge,true,timingResponse.data.data.costList.length > 0 ? false : timingBurqResponse.data.data.costList.length > 0 ? true : false)
          }
        }
      }

        let tempObj = {
          actual_delivered_time: '',
          charge: 0,
          delivery_date_time: "",
          delivery_time: "",
          delivery_time_type: "",
          id: -1,
          name: "Custom",
        }
        setMatrix((prev)=>[...prev,tempObj])
        // matrix.push(tempObj);
      
      if(timingResponse?.data?.data.costList.length <= 0 && timingBurqResponse?.data?.data.costList.length <= 0){
        showToastMessage(
          undefined,
          `Delivery is currently unavailable for the selected date`,
          "error",
          false
        );
        return;
      }
    } catch (error) {
      console.log(error);
      alert("Facing technical issue with delivery-matrix");
    }

    //UTC LIST
  };

  //check holiday cutoff
  const holidayApplicable = (day) => {
    let sec = moment(day).unix();
    let appliedHoliday = config?.holidayCutOff?.filter(
      (c) => c.start_date <= sec && c.end_date > sec
    );

    if (appliedHoliday.length === 0) {
      return false;
    }

    let appliedCategories = config?.holidayCutOff?.map((c) => {
      return {
        category: c.holiday_cutoff_exclude_categories.map(
          (z) => z.category_hierarchy_id
        ),
      };
    });

    let products = checkCutOff(appliedCategories);
    if (products.length !== 0) {
      showToastMessage(
        undefined,
        `${products.join()} is not available due to holiday period.`,
        "error",
        false
      );
      return true;
    }

    return false;
  };

  //check cutoff
  function checkCutOff(apl) {
    let productTitle = [];
    formik.values?.recipients?.forEach((recipient) => {
      recipient?.products?.forEach((product) => {
        let productCatHeirs = product?.product_categories?.map(
          (c) => c.category_hierarchy_id
        );
        apl.forEach((a) => {
          a.category.forEach((id) => {
            if (!productCatHeirs.includes(id)) {
              productTitle.push(product.title);
            }
          });
        });
      });
    });
    return productTitle;
  }

  //check if lead time is applicable
  async function isLeadTimeApplicable(day) {
    let prodIds = new Set();
    formik.values.recipients.forEach((recipent) => {
      recipent.products.forEach((product) => {
        prodIds.add(product.id);
      });
    });

    let ids = Array.from(prodIds);

    let productBlockResponse = await callPostApi({
      url: "blockout-dates/list",
      body: {
        product_id: ids,
      },
    });

    if (productBlockResponse?.data?.data?.rows?.length === 0) {
      return false;
    } else {
      let curr = moment(day).unix();
      if (
        productBlockResponse.data.data.rows[0].start_blocking_on <= curr &&
        productBlockResponse.data.data.rows[0].stop_repeating_on > curr
      ) {
        return true;
      }
    }
    return false;
  }

  const setDate = (day) => {
    let delivery_information = {
      ...formik.values.delivery_information,
    };
    delivery_information.delivery_date = moment(day).format("YYYY-MM-DD");

    //SET DEFAULT TIME 3 PM
    let pstCustomTime = moment(moment(day).format('YYYY-MM-DD') + ' ' + CUSTOM_DEFAULT_TIME).format("hh:mm A");
    let delivery_date_time_custom = pstToUTCDateTime(
      delivery_information.delivery_date,
      null,
      pstCustomTime
    );
    let delivery_time_custom = utcDateTimeToTime(delivery_date_time_custom);

    delivery_information.delivery_time = delivery_time_custom;
    delivery_information.delivery_date_time = delivery_date_time_custom;

    return delivery_information;
  };

  const handleDeliveryType = (value) => {
    setEnableTimeEdit(true);
    const payment_type = module !== 'edit-order' ? (formik.values.delivery_information.delivery_type_id > 4 ? '' : formik.values.payment_type) : formik.values.payment_type;
    formik.setValues((v) => {
      if (v.delivery_information.delivery_type_id === 1 && value.id !== 1) {
        setMatrix([]);
        return {
          ...v,
          payment_type,
          delivery_information: {
            ...v.delivery_information,
            delivery_type_id: value.id,
            delivery_date: null,
            order_type: 0,
            delivery_time: "",
            charge: 0,
            upcharge_id: 0,
            upcharge_amount: 0,
            delivery_time_id: null,
            delivery_type: value.name,
            storeId:0,
            burq_quote_id:"",
            burq_quote_expires:""
          },
        };
      } else if (value.id === 1) {
        setMatrix([]);
        return {
          ...v,
          delivery_information: {
            ...v.delivery_information,
            delivery_type_id: value.id,
            delivery_date: null,
            order_type: 1,
            delivery_time: "07:00 AM",
            charge: 0,
            upcharge_id: 0,
            upcharge_amount: 0,
            delivery_time_id: null,
            delivery_type: value.name,
            storeId:1,
            burq_quote_id:"",
            burq_quote_expires:""
          },
          payment_type
        };
      } else {
        if(module !== 'edit-order'){
           setMatrix([]);
           return {
            ...v,
            delivery_information: {
              ...v.delivery_information,
              delivery_type_id: value.id,
              order_type: 0,
              delivery_type: value.name,
              delivery_time_id: null,
              delivery_date: null,
              delivery_time: "",
              delivery_time_type: "",
              burq_quote_id:"",
              burq_quote_expires:""
  
            },
            payment_type
          };
        }else{
          return {
            ...v,
            delivery_information: {
              ...v.delivery_information,
              delivery_type_id: value.id,
              order_type: 0,
              delivery_type: value.name,
            },
            payment_type
          };
        }
        
      }
    });
  };

  const handleTimeChange = (value) => {
   //console.log("on time changes",value)
    if (!formik.values.delivery_information.delivery_date) {
      showToastMessage(
        undefined,
        `Please fill out delivery date before selecting time`,
        "error",
        false
      );
      return;
    }
    let delivery_information = { ...formik.values.delivery_information };

    let pst = moment(value).format("hh:mm A");
    let delivery_date_time = pstToUTCDateTime(
      delivery_information.delivery_date,
      null,
      pst
    );
    let delivery_time = utcDateTimeToTime(delivery_date_time);
    let upcharge_id = 0;
    let upcharge_amount = 0;
    let charge = 0;

    delivery_information.delivery_time = delivery_time;
    delivery_information.upcharge_id = upcharge_id;
    delivery_information.upcharge_amount = upcharge_amount;
    delivery_information.charge = charge;
    delivery_information.delivery_date_time = delivery_date_time;
    formik.setValues((v) => {
      return {
        ...v,
        delivery_information,
      };
    });
  };

  const handleDeliveryChargeChange = (value) => {
    // if (!formik.values.delivery_information.delivery_date && value !== 0 && value !== null && value !== '') {
    //   showToastMessage(
    //     undefined,
    //     `Please fill out delivery date before selecting time`,
    //     "error",
    //     false
    //   );
    //   return;
    // }
    let delivery_information = { ...formik.values.delivery_information };
    // debugger
    delivery_information.charge = parseFloat(value);
    // console.log("value=>",value);
    // console.log("delivery_information.charge=>",delivery_information.charge);
    formik.setValues((v) => {
      return {
        ...v,
        delivery_information,
      };
    });
  };

  const handleDeliveredBy = (value) => {

    let delivery_information = { ...formik.values.delivery_information };
    //console.log("value=>",value);
    // console.log("delivery_information.charge=>",delivery_information.charge);
    if (value === 'Anytime') {
      let pst = moment(moment().format('YYYY-MM-DD') + ' ' + CUSTOM_ANYTIME).format("hh:mm A");
      let delivery_date_time = pstToUTCDateTime(
        delivery_information.delivery_date,
        null,
        pst
      );
      let delivery_time = utcDateTimeToTime(delivery_date_time);
      delivery_information.delivery_time = delivery_time;
      delivery_information.delivery_date_time = delivery_date_time;
    }

    delivery_information.delivery_time_type = value;

    formik.setValues((v) => {
      return {
        ...v,
        delivery_information,
      };
    });
  };

  const handleDeliveryMatrix = ({
    charge,
    delivery_time_type,
    id,
    delivery_time,
    delivery_date_time,
    actual_delivered_time,
    name,
    burq_quote_id,
    burq_quote_expires
  }) => {
    //console.log("burq_quote_id v before",formik.values)
   //console.log("burq_quote_id",burq_quote_id)
    let delivery_information = { ...formik.values.delivery_information };
    delivery_information.delivery_time_type = delivery_time_type;
    delivery_information.charge = charge;
    delivery_information.delivery_time_id = id;
    delivery_information.delivery_date_time = delivery_date_time;
    delivery_information.delivery_time = delivery_time;
    delivery_information.actual_delivered_time = actual_delivered_time;
    delivery_information.burq_quote_id = burq_quote_id?? ""
    delivery_information.burq_quote_expires = burq_quote_expires?? ""

    //IF CSR SELECT CUSTOM OPTION THNE WE ADD 3:00 PM BY DEFAULT
    if (id === -1) {
      let pstCustomTime = moment(moment().format('YYYY-MM-DD') + ' ' + CUSTOM_DEFAULT_TIME).format("hh:mm A");
      let delivery_date_time_custom = pstToUTCDateTime(
        delivery_information.delivery_date,
        null,
        pstCustomTime
      );
      let delivery_time_custom = utcDateTimeToTime(delivery_date_time_custom);

      delivery_information.delivery_time = delivery_time_custom;
      delivery_information.delivery_date_time = delivery_date_time_custom;
    }
    
    formik.setValues((v) => {
      return {
        ...v,
        delivery_information,
      };
    });

  };

  return {
    show,
    matrix,
    setMatrix,
    handleDeliveryMatrix,
    handleDateChange,
    handleDeliveryType,
    handleTimeChange,
    blockoutDates,
    tooltipDays,
    enableTimeEdit,
    setEnableTimeEdit,
    handleDeliveryChargeChange,
    handleDeliveredBy,
    timeslectionDropdown
  };
}
