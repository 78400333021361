import React, { createContext, useContext, useState, useEffect } from "react";
import SweetAlert from "react-bootstrap-sweetalert";

// Cookies
import Cookies from "universal-cookie";
import socket from "../socket"
import { getProfile } from "../api/user";
// Services
import Notification from "./../Services/Notification/Notification";
import { getCount } from "./../api/notification";

export const AppContext = createContext();

function AppProvider({ children }) {
  const [notificationCount, setNotificationCount] = useState(0);
  const [isLogin, setIsLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState(null);
  const [alert, setAlert] = useState(null);
  const [role, setRole] = useState(null);
  const [timezone, setTimezone] = useState(null);
  const [lastOrderEditPath, setLastOrderEditPath] =
    useState("/admin/orders/list");

  const cookies = new Cookies();
  const [modules, setModules] = useState(
    // convertPermissionsIntToBoolean(cookies.get("modules") || [])
    convertPermissionsIntToBoolean(
      JSON.parse(localStorage.getItem("modules")) || []
    )
  );
  const notificationService = new Notification();

  const login = async (token, role, timezone, modules) => {
    setToken(token);
    setRole(role);
    setTimezone(timezone);
    setIsLogin(true);
    setModules(convertPermissionsIntToBoolean(modules));
    cookies.set("defaultTimezone", timezone, { path: "/" });
    cookies.set("accessToken", token, { path: "/" });
    cookies.set("role", role, { path: "/" });
    // cookies.set("modules", modules, { path: "/" });
    localStorage.setItem("modules", JSON.stringify(modules));
    let response = await getProfile();
    if (response && response.status === 200) {
      socket.connect();
      socket.emit("join", response.data.data.id)
    };
  };

  const logout = () => {
    setToken(null);
    setRole(null);
    setTimezone(null);
    setIsLogin(false);
    setModules([]);

    cookies.remove("role", { path: "/" });
    cookies.remove("defaultTimezone", { path: "/" });
    cookies.remove("accessToken", { path: "/" });
    // cookies.remove("modules", { path: "/" });
    localStorage.removeItem("modules");
    localStorage.removeItem("assignStore");
    localStorage.removeItem("selectedStore");

    if (window.zE) {
      window.zE("webWidget", "hide");
      window.zE("webWidget:on", "close")
    }

    socket.disconnect();
  };

  const changeModules = (modules) => {
    setModules(convertPermissionsIntToBoolean(modules));
    // cookies.set("modules", modules, { path: "/" });
    localStorage.setItem("modules", JSON.stringify(modules));
  };

  useEffect(() => {
    let accessToken = cookies.get("accessToken");
    let timezone = cookies.get("defaultTimezone");
    let role = cookies.get("role");
    // let modules = cookies.get("modules");
    let modules = JSON.parse(localStorage.getItem("modules"));

    if (accessToken) {
      setToken(accessToken);
      setIsLogin(true);

      handleGetNotificationCount();
    }

    if (role) setRole(role);
    if (timezone) setTimezone(timezone);
    if (modules) setModules(convertPermissionsIntToBoolean(modules));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (token) setToken(token);
  }, [token]);

  useEffect(() => {
    if (role) setRole(role);
  }, [role]);

  useEffect(() => {
    if (timezone) setTimezone(timezone);
  }, [timezone]);

  useEffect(() => {
    if (modules) setModules(modules);
  }, [modules]);

  useEffect(async () => {
    if (isLogin) {
      console.log("isLogin",isLogin)
      let response = await getProfile();
      if (response && response.status === 200) {
        socket.emit("join", response.data.data.id)
      };
    };
  },[isLogin]);

  const showToastMessage = (
    title,
    message,
    confirmButtonStyle,
    showCancel,
    timeOut
  ) => {
    setAlert(
      <SweetAlert
        customClass={confirmButtonStyle === "info" ? "swt-alert-info" : ""}
        success={confirmButtonStyle === "success" ? true : false}
        error={confirmButtonStyle === "error" ? true : false}
        info={confirmButtonStyle === "info"}
        showCancel={showCancel}
        confirmBtnBsStyle={confirmButtonStyle}
        title={title || ""}
        onConfirm={hideToastMessage}
        onCancel={hideToastMessage}
        timeout={timeOut ?? 8000}
      >
        {message}
      </SweetAlert>
    );
  };

  const handleGetNotificationCount = async () => {
    // let response = await notificationService.getCount();
    let response = await getCount();
    setNotificationCount(response.data?.data?.count);
  };

  const hideToastMessage = () => {
    setAlert(null);
  };

  const handleChangeTimezone = (timezone) => {
    cookies.set("defaultTimezone", timezone, { path: "/" });

    setTimezone(timezone);
  };

  return (
    <AppContext.Provider
      value={{
        isLogin,
        alert,
        role,
        notificationCount,
        timezone,
        modules,
        isLoading,
        setIsLoading,
        handleGetNotificationCount,
        login,
        logout,
        handleChangeTimezone,
        changeModules,
        showToastMessage,
        lastOrderEditPath,
        setLastOrderEditPath,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

/* API FOR ROLES RETURN VALUE IN INT 1/0 SO IN UI WE NEED TO CONVERT TO BOOLEAN FOR CONDITION SO WE WILL CONVERT HERE BEFORE SETTING IN CONTEXT */
function convertPermissionsIntToBoolean(modules) {
  return modules.map((module) => {
    return {
      ...module,
      permissions: {
        ...module.permissions,
        read_access: Boolean(module.permissions.read_access),
        write_access: Boolean(module.permissions.write_access),
        delete_access: Boolean(module.permissions.delete_access),
      },
    };
  });
}

const useAuth = () => useContext(AppContext);

export { AppProvider, useAuth };
