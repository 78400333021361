import { makeStyles, TextField } from "@material-ui/core";
import RadioBox from "Components/FormControl/RadioBox/RadioBox";
import Select from "Components/FormControl/Select/NewSelect";
import DatePickerWithInput from "Components/NewOrder/DatePicker/DatePickerWithInput";
import { OrderConfigContext } from "Context/OrderConfigContext";
import { useFormikContext } from "formik";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import Datetime from "react-datetime";
import { useOrderAttributeProvider } from "../context/order";
import { useDelivery } from "../hooks/useDelivery";
import {
  CUSTOM_ANYTIME,
  utcDateTimeToPSTTime,
  utctimeToPSTTime,
  validateNotAllowStoreOrder,
} from "../utility/util";
import Text from "Components/FormControl/Text/Text";
import {storeList} from "../../../../api/stores";
import { PAYMENT_METHOD_DETAIL, ORDER_SOURCE, PAYMENT_METHODS } from "Helpers/Constants";
import { AppContext } from "Context/AppContext";
import { useDeliveryAddress } from "Context/DeliveryAddress/usedeliveryAddress";
const useStyles = makeStyles({
  time: {
    paddingTop: "16px",
    marginBottom: "16px",
  },
});

const Delivery = () => {
  const config = useContext(OrderConfigContext);
  const classes = useStyles();
  const orderDelivery = [
    { id: "Before", title: "Before" },
    { id: "Anytime", title: "AnyTime" },
    { id: "After", title: "After" },
    { id: "By", title: "By" },
  ];

  const orderDeliveryWithOutAnytime = [
    { id: "Before", title: "Before" },
    { id: "After", title: "After" },
    { id: "By", title: "By" },
  ];

  const formik = useFormikContext();
  const {
    show,
    handleDateChange,
    handleDeliveryType,
    handleTimeChange,
    blockoutDates,
    tooltipDays,
    matrix,
    setMatrix,
    handleDeliveryMatrix,
    enableTimeEdit,
    setEnableTimeEdit,
    handleDeliveryChargeChange,
    handleDeliveredBy,
    timeslectionDropdown
  } = useDelivery();

  const { showToastMessage } = useContext(AppContext);
  const { setDeliveryAddress,deliveryAddressState } = useDeliveryAddress();

  let tempDataFormik = formik.values.delivery_information.charge ? formik.values.delivery_information.charge : 0;
  const [tempCharge, setTempCharge] = useState(tempDataFormik);

  const { active, module } = useOrderAttributeProvider();
  // selected store list
  const [selectedStore, setSelectedStore] = useState([]);

  // console.log("tempDataFormik",tempDataFormik);
  useEffect(() => {
    setTempCharge(tempDataFormik);
  }, [tempDataFormik]);

  useEffect(() => {
    handleDeliveryChargeChange(parseFloat(tempCharge));
  }, [tempCharge]);

  const isvalidateNotAllowStoreOrder = (payment_method, paypoint, storeId)=>{
    const validateNotAllowStores = validateNotAllowStoreOrder(payment_method,paypoint, storeId)
    if(validateNotAllowStores){
      showToastMessage(
        undefined,
        "Currently, we are not accepting online orders for the selected location. However, we will start soon.", 
        "error", 
        false
      );
      const defaultStore = config.storeList.find((store) => store.id === deliveryAddressState.storeId);
      formik.setValues((v) => ({
        ...v,
        delivery_information: {
          ...v.delivery_information,
          storeId: deliveryAddressState.storeId
        },
        billing_info: {
          ...v.billing_info,
          zip_code_store_id: deliveryAddressState.storeId,
          payment_method: PAYMENT_METHOD_DETAIL[deliveryAddressState.storeId]
        },
        payabli_paypoint: defaultStore.payabli_paypoint,
        payment_type: module === "edit-order" || module === "replace-order" ? v.payment_type : "",
      }));
      return;
    }
  }

// we are Manage Order Transection data and filter 0 order id transection
  const order_payment_transactions_data =  formik.values?.order_payment_transactions?.filter((val) => val.order_id === 0) || []
  const payment_method = order_payment_transactions_data?.length > 0 && order_payment_transactions_data[0]?.payment_method
  const isDisebled = formik.values.source === ORDER_SOURCE.WALKIN.toLowerCase() && payment_method === PAYMENT_METHODS.PAYABLI ? true : false
  const deliveryInformation = formik.values.delivery_information;
  const isNonlocalZipcode = deliveryInformation.zipcode.length === 5 && deliveryInformation.is_local === 0 ? true : false

  //Reset delivery information when order type change pickup to delivery
  //And also update the order details according to the selected store
  const resetDeliveryInformation = () => {
    formik.setValues((v) => ({
      ...v,
      delivery_information: {
        ...v.delivery_information,
        address_type_id:null,
        attn: "",
        address_type: "",
        address_line_1: "",
        address_line_2: "",
        city: "",
        state: "",
        zipcode: "",
        order_type: 0,
        delivery_time: "",
        charge: 0,
        upcharge_id: 0,
        upcharge_amount: 0,
        delivery_type_id: 2,
        delivery_type: config?.deliveryTypesList?.find((c) => c.id === 2)?.name ?? "",
        delivery_date: null,
        delivery_time_id: null,
        delivery_date_time: "",
        storeId: v.billing_info.zip_code_store_id || 0
      },
      //PAYABLI
      billing_info: {
        ...v.billing_info,
        // zip_code_store_id: null,
        payment_method: "",
      },
      payment_type: module === "edit-order" || module === "replace-order" ? v.payment_type : "",
    }));
    //Update latest delivery information in delivery address context
    setDeliveryAddress(formik.values.delivery_information);
  }

  return (
    <div className="row">
      <div className="col-4">
        <RadioBox
          //  we are manage fields using Order payment_method and order Type 
          disabled={isDisebled || active > 0 || formik.values.is_burq_order === 1}
          selectedValue={formik.values.delivery_information.order_type.toString()}
          label="Delivery Order"
          value="0"
          onChange={() => {
            setEnableTimeEdit(true);
            setMatrix([]);
            resetDeliveryInformation();
          }}
        />
        {
          (!formik?.values?.is_subscribe_order || module != "new-order") &&
          (
            <RadioBox
              //  we are manage fields using Order payment_method and order Type 
              disabled={isDisebled || isNonlocalZipcode || active > 0 || formik.values.is_burq_order === 1}
              selectedValue={formik.values.delivery_information.order_type.toString()}
              label="Pickup Order"
              value="1"
              labelClass={isNonlocalZipcode ? "d-none" : ""}
              onChange={() => {
                setMatrix([]);
                setEnableTimeEdit(true);
                formik.setValues((v) => ({
                  ...v,
                  delivery_information: {
                    ...v.delivery_information,
                    is_local:1,
                    order_type: 1,
                    charge: 0,
                    upcharge_id: 0,
                    upcharge_amount: 0,
                    delivery_time: "",
                    delivery_date: null,
                    delivery_date_time: "",
                    delivery_type_id: 1,
                    delivery_type: config?.deliveryTypesList?.find((c) => c.id === 1)?.name ?? "",
                    delivery_time_id: null,
                    storeId: v.billing_info.zip_code_store_id || 1
                  },
                  //PAYABLI
                  billing_info: {
                    ...v.billing_info,
                    zip_code_store_id: v.billing_info.zip_code_store_id || 1,
                    payment_method: PAYMENT_METHOD_DETAIL[v.billing_info.zip_code_store_id || 1]
                  },
                  payabli_paypoint:  v.billing_info.payment_method === PAYMENT_METHODS.PAYABLI ? v.payabli_paypoint : null,
                  payment_type: module === "edit-order" || module === "replace-order" ? v.payment_type : "",
                }));
              }}
            />
          )
        }

      </div>
      {
        formik.values.delivery_information.order_type && module != "edit-order" && !formik?.values?.is_subscribe_order ?
          <div className="col-4">
            <Select
              label="Select Store"
              value={formik.values.delivery_information.storeId}
              required={false}
              //  we are manage fields using Order payment_method and order Type 
              disabled={isDisebled || !formik.values.delivery_information.order_type}
              onChange={(data) => {

                formik.setValues((v) => ({
                  ...v,
                  delivery_information: {
                    ...v.delivery_information,
                    storeId: v.cart_products.product_variation_storeIds.length > 0 ? v.cart_products.product_variation_storeIds[0] : data.id
                  },
                  //PAYABLI
                  billing_info: {
                    ...v.billing_info,
                    zip_code_store_id: data?.id,
                    payment_method: data?.paymentgateway
                  },
                  payabli_paypoint: data?.paymentgateway === PAYMENT_METHODS.PAYABLI ?  data?.payabli_paypoint : null,
                  payment_type: module === "edit-order" || module === "replace-order" ? v.payment_type : "",
                }));
                isvalidateNotAllowStoreOrder(data.paymentgateway, data?.payabli_paypoint, data?.id)
              }}
              options={config.storeList}
            />
          </div>
          : <div className="col-4"></div>
      }
      <div className="col-2"></div>

      <div className="col-md-4">
        <DatePickerWithInput
          //  we are manage fields using Order payment_method and order Type 
          disabled={isDisebled || active > 0 || formik.values.is_burq_order === 1}
          label="Delivery Date"
          // blockDates={blockoutDates} // open when you want to check block out days
          blockDates={[]}
          tooltipDays={tooltipDays}
          value={
            formik.values.delivery_information.delivery_date
              ? moment(
                formik.values.delivery_information.delivery_date
              ).toDate()
              : null
          }
          onChange={handleDateChange}
        />
      </div>
      <div className="col-md-4">
        <Select
          //  we are manage fields using Order payment_method and order Type 
          disabled={isDisebled || active > 0 || formik.values.is_burq_order === 1}
          options={formik.values.is_subscribe_order && module === "new-order" ? config.deliveryTypesList.filter(elem => elem.id === 2) : config.deliveryTypesList}
          label="Delivery Type"
          onChange={handleDeliveryType}
          value={formik.values.delivery_information.delivery_type_id}
        />
      </div>
      <div className="col-md-4">
        {!enableTimeEdit && (
          <TextField
            className="w-100"
            disabled
            label={
              formik.values.delivery_information.order_type === 0
                ? "Delivery time"
                : "Pickup time"
            }
            value={
              formik.values.delivery_information.order_type === 0
                ? formik.values.delivery_information.delivery_time_id === -1
                  ? "Custom"
                  : `${formik.values.delivery_information.delivery_time_type
                  }  ${formik.values.delivery_information.delivery_time_type ===
                    "Anytime"
                    ? ""
                    : utcDateTimeToPSTTime(
                      formik.values.delivery_information
                        .delivery_date_time
                    )
                  }`
                : `${utcDateTimeToPSTTime(
                  formik.values.delivery_information.delivery_date_time
                )}`
            }
          />
        )}
        {enableTimeEdit && (
          <>
            {/* we are manage fields using Order payment_method and order Type  */}
            {isDisebled || formik.values.delivery_information.order_type === 1 ? (
              <Datetime
                value={utctimeToPSTTime(
                  (formik.values?.delivery_information?.delivery_date_time) ? formik.values.delivery_information.delivery_date_time : null, formik.values.delivery_information.delivery_date, formik.values.delivery_information.delivery_time
                )}
                // value={utctimeToPSTTime(
                //   formik.values.delivery_information.delivery_date,formik.values.delivery_information.delivery_time
                // )}
                className={classes.time}
                dateFormat={false}
                input={true}
                inputProps={{ placeholder: "Select your pickup time" }}
                onChange={handleTimeChange}
              />
            ) : (
              <>
                {timeslectionDropdown ? (
                  <Select
                    label="Calculating..."
                    value={formik.values.delivery_information.delivery_time_id}
                    onChange={""}
                    options={[]}
                  />
                ) : (
                  <Select
                    label="Delivery Time"
                    value={formik.values.delivery_information.delivery_time_id}
                    onChange={handleDeliveryMatrix}
                    options={matrix}
                  />
                )}
              </>
            )}
          </>
        )}
      </div>
      {formik.values.delivery_information.delivery_time_id === -1 && (
        <>
          <div className="col-md-4">
            <Select
              value={formik.values.delivery_information.delivery_time_type}
              label="Delivered By"
              onChange={(data) => {
                handleDeliveredBy(data.id);
                // formik.setValues((v) => ({
                //   ...v,
                //   delivery_information: {
                //     ...v.delivery_information,
                //     delivery_time_type: data.id,
                //   },
                // }));
              }}
              options={
                formik.values.delivery_information.address_type_id > 2
                  ? orderDeliveryWithOutAnytime
                  : orderDelivery
              }
              id="delivery_time_type"
              error={
                formik.touched?.delivery_information?.delivery_time_type &&
                Boolean(formik.errors?.delivery_information?.delivery_time_type)
              }
            />
          </div>
          {formik.values.delivery_information.delivery_time_type !==
            "Anytime" && (
              <div className="col-md-4">
                <Datetime
                  value={utctimeToPSTTime(
                    (formik.values?.delivery_information?.delivery_date_time) ? formik.values.delivery_information.delivery_date_time : null, formik.values.delivery_information.delivery_date, formik.values.delivery_information.delivery_time
                  )}
                  className={classes.time}
                  dateFormat={false}
                  input={true}
                  inputProps={{ placeholder: "Select your delivery time" }}
                  onChange={handleTimeChange}
                />
              </div>
            )}

          <div className="col-md-4">
            {/* <TextField
              value={formik.values.delivery_information.charge}
              className={classes.time}
              dateFormat={false}
              input={true}
              inputProps={{ placeholder: "Add Delivery Charge" }}
              onChange={handleDeliveryChargeChange}
            /> */}
            <Text
              type="text"
              label="Delivery Charge"
              // disabled
              value={tempCharge.toString()}
              required={true}
              onChange={(value) => {
                if (/^[0-9]*(\.[0-9]*)?$/.test(value)) {
                  // handleDeliveryChargeChange(value);
                  setTempCharge(value);
                }
              }}
            />
          </div>
        </>
      )}
      {formik.values.is_subscribe_order === 1 && (
        <div className="col-md-4">
          <Select
            disabled={module === "new-order" ? false : true}
            options={config.frequencyList}
            value={formik.values.delivery_information.frequency}
            label="Subscription Type"
            onChange={(data) => {
              formik.setValues((v) => ({
                ...v,
                delivery_information: {
                  ...v.delivery_information,
                  frequency: data.id
                }
              }));
            }}
          />
        </div>
      )}
      {show.two_field && (
        <>
          <div className="col-md-4">
            <TextField
              disabled={active > 0}
              className="w-100 my-2"
              value={formik.values.delivery_information.shop_code}
              onChange={formik.handleChange}
              id="delivery_information.shop_code"
              error={
                formik.touched?.delivery_information?.shop_code &&
                Boolean(formik.errors?.delivery_information?.shop_code)
              }
              onBlur={formik.handleBlur}
              label="Shop Code"
            />
          </div>
          {show.all_field && (
            <div className="col-md-4">
              <TextField
                disabled={active > 0}
                className="w-100 my-2"
                value={formik.values.delivery_information.shop_name}
                onChange={formik.handleChange}
                id="delivery_information.shop_name"
                error={
                  formik.touched?.delivery_information?.shop_name &&
                  Boolean(formik.errors?.delivery_information?.shop_name)
                }
                onBlur={formik.handleBlur}
                label="Shop Name"
              />
            </div>
          )}
          {show.all_field && (
            <div className="col-md-4">
              <TextField
                disabled={active > 0}
                className="w-100 my-2"
                value={formik.values.delivery_information.person_name}
                onChange={formik.handleChange}
                id="delivery_information.person_name"
                error={
                  formik.touched?.delivery_information?.person_name &&
                  Boolean(formik.errors?.delivery_information?.person_name)
                }
                onBlur={formik.handleBlur}
                label="Person Name"
              />
            </div>
          )}
          <div className="col-md-4">
            <TextField
              disabled={active > 0}
              className="w-100 my-2"
              value={formik.values.delivery_information.external_order_number}
              onChange={formik.handleChange}
              id="delivery_information.external_order_number"
              error={
                formik.touched?.delivery_information?.external_order_number &&
                Boolean(
                  formik.errors?.delivery_information?.external_order_number
                )
              }
              onBlur={formik.handleBlur}
              label="External Order Number"
            />
          </div>
          {show.all_field && (
            <div className="col-md-4">
              <TextField
                disabled={active > 0}
                className="w-100 my-2"
                value={formik.values.delivery_information.phone_number}
                onChange={formik.handleChange}
                id="delivery_information.phone_number"
                error={
                  formik.touched?.delivery_information?.phone_number &&
                  Boolean(formik.errors?.delivery_information?.phone_number)
                }
                onBlur={formik.handleBlur}
                label="Phone Number"
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Delivery;
