import React from "react";
import classes from "./styles.module.scss";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
const Card = (props) => {
  let image = props.product_images[0]?.image;
  let def = props.product_images.find((c) => c.is_default_selected === 1);
  if (def) {
    image = def.image;
  }

  let defaultVariation = props.product_variations.find(
    (c) => c.is_default_selected
  );
  if (!defaultVariation) {
    defaultVariation = props.product_variations[0];
  }

  return (
    <div className={classes.Card}>
      <div className={classes.Image} onClick={props.onClick}>
        <div>
          <img src={image + "?auto=format,compress&w=500"} alt="" />
        </div>
        {/* {props.is_local_only && (
          <div className={classes.LocalDelivery}>
            <p> 
              LOCAL ONLY
            </p> 
          </div>
        )} */}
        {(props.is_sold_out === true || props.inventory_remaining === 0) && <p className={classes.SoldOut}>Sold Out</p>}
        <p className={classes.priceRange}>{props.priceRange}</p>
      </div>

      <div>
        <p className="d-flex justify-content-between">
          {props.title}
          <span style={{ marginTop: "1px" }}>
            {props.is_selected && (
              <CheckCircleOutlineIcon className="text-success" />
            )}
          </span>
        </p>
        <p className="d-flex justify-content-between">
          <span>{defaultVariation?.title}</span>
          <span>Comm. ${defaultVariation?.commission}</span>
        </p>
      </div>
    </div>
  );
};

export default Card;
