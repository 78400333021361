import React, { useReducer } from "react";
import DeliveryAddressReducer from "./deliveryAddressReducer";
import { deliveryAddressInitalState } from "./deliveryAddressState";

export const DeliveryAddressContext = React.createContext({
  deliveryAddressState: deliveryAddressInitalState,
  dispatch: () => null,
});

const DeliveryAddressProvider = (props) => {
  const [state, dispatch] = useReducer(
    DeliveryAddressReducer,
    deliveryAddressInitalState
  );

  return (
    <DeliveryAddressContext.Provider
      value={{ deliveryAddressState: state, dispatch }}
    >
      {props.children}
    </DeliveryAddressContext.Provider>
  );
};

export default DeliveryAddressProvider;
