import { createCustomerInfo } from "./createOrderObject";
import Cookies from "universal-cookie";
import { utcTimeToDate } from "./util";

const cookies = new Cookies();
let role = cookies.get("role");
export function editOrderObject(
  values,
  summary,
  user_id,
  orderDetail,
  action,
  note
) {
  let customer_inf = values.customer_info;
  let billing_info = values.billing_info;

  let order_transaction = {
    id: orderDetail.order_transaction.id ?? 0,
    customer_id: customer_inf.customer_id ?? 0,
    // customer_address_id: orderState.order_transaction.customer_address_id ?? 0,
    customer_card_id: orderDetail.order_transaction.customer_card_id ?? 0,
    subscription_discount: values.subscription_discount,
  };

  let customer_info = createCustomerInfo(customer_inf, billing_info);
  customer_info.customer_billing_first_name =
    orderDetail?.order_transaction?.customer_billing_first_name;
  customer_info.customer_billing_last_name =
    orderDetail?.order_transaction?.customer_billing_last_name;
  let order = formatOrderObject(
    values,
    summary,
    orderDetail.csr_id,
    orderDetail,
    action,
    user_id
  );

  let internalnotes = note?.trim()
    ? {
        order_id: orderDetail.id,
        notes: note,
      }
    : undefined;

  return {
    customer_info,
    ...order,
    order_transaction,
    internalnotes,
  };
}

export function formatOrderObject(
  values,
  summary,
  user_id,
  orderDetail,
  action,
  modified_by
) {
  let delivery_information = values.delivery_information;
  let recipients = values.recipients;
  let customOrderFlag = 0;
  
  let receipient = recipients.map((rec) => {
    let products = rec.products.map((prod) => {
      customOrderFlag = !customOrderFlag ? prod?.custom_product_flag : customOrderFlag
      return {
        id: prod.sql_id ?? 0,
        order_id: orderDetail.id,
        order_recipients_id: rec?.sql_id ?? 0,
        product_id: prod.id,
        product_type: 0,
        qty: prod.qty,
        unit_price: +prod.selectedVariant.price.toFixed(2),
        total_price: +prod.total_price.toFixed(2) ?? 0,
        promocode: "",
        discount_price: 0,
        commission_price: +prod.selectedVariant.commission.toFixed(2),
        total_commission_price: +(
          prod.selectedVariant.commission * prod.qty
        ).toFixed(2),
        product_name: prod.title?.trim() ?? "",
        variation_id: prod.selectedVariant.id ?? 0,
        variation_image: prod.selectedVariant.image ?? "",
        variation_name: prod.selectedVariant.title?.trim() ?? "",
        custom_product_flag: prod?.custom_product_flag ?? 0,
        product_description:prod?.description ?? "",
        kintsugi_product_id:prod?.kintsugi_product_id ?? "",
        tax_rate: +prod?.tax_rate || 0,
        taxable_amount: +prod?.taxable_amount || 0,
        kintsugi_item_price: +prod.kintsugi_item_price?.toFixed(2) || 0,
        tax: +prod?.tax || 0,
        product_key: prod?.product_key ?? `prod_0_0`, // prod_{product_id}_{variant_id}_{date_time}
        store_variation_id: prod?.selectedVariant?.store_product_variations[0]?.id ?? 0,
        store_product_id : prod?.store_products[0]?.id ?? 0,
      };
    });

    let addons = rec.addons.map((prod) => {
      return {
        id: prod.sql_id ?? 0,
        order_id: orderDetail.id,
        order_recipients_id: rec?.sql_id ?? 0,
        product_id: prod.id,
        product_type: 1,
        qty: prod.qty,
        unit_price: +prod.selectedVariant.price.toFixed(2),
        total_price: +prod.total_price.toFixed(2) ?? 0,
        promocode: "",
        discount_price: 0,
        commission_price: +prod.selectedVariant.commission.toFixed(2),
        total_commission_price: +(
          prod.selectedVariant.commission * prod.qty
        ).toFixed(2),
        product_name: prod.title?.trim() ?? "",
        variation_id: prod.selectedVariant.id ?? 0,
        variation_image: prod.selectedVariant.image ?? "",
        variation_name: prod.selectedVariant.title?.trim() ?? "",
        product_description:prod?.description ?? "",
        kintsugi_product_id:prod?.selectedVariant?.kintsugi_product_id ?? "",
        tax_rate: +prod?.tax_rate || 0,
        taxable_amount: +prod?.taxable_amount || 0,
        kintsugi_item_price: +prod?.kintsugi_item_price?.toFixed(2) || 0,
        tax: +prod?.tax || 0,
        product_key: prod?.product_key ?? `prod_0_0`, // prod_{product_id}_{variant_id}_{date_time}
        store_variation_id: prod?.selectedVariant?.store_product_variations[0]?.id ?? 0,
        store_product_id : prod?.store_products[0]?.id ?? 0,
      };
    });
    return {
      id: rec.sql_id ?? 0,
      recipients_id: rec.recipient_id ?? 0,
      order_id: orderDetail.id,
      first_name: rec.first_name?.trim() ?? "",
      last_name: rec.last_name?.trim() ?? "",
      address_type: delivery_information.address_type ?? "",
      address_type_id: delivery_information.address_type_id ?? 0,
      attn: delivery_information.attn?.trim() ?? "",
      address_line_1: delivery_information.address_line_1?.trim() ?? "",
      address_line_2: delivery_information.address_line_2?.trim() ?? "",
      phone: delivery_information.phone.toString() ?? "",
      phone_country_code: delivery_information.phone_country_code.toString() ?? "",
      alt_phone: delivery_information.alt_phone?.toString() ?? "",
      alt_phone_country_code: delivery_information.alt_phone_country_code.toString() ?? "",
      city: delivery_information.city?.trim() ?? "",
      state: delivery_information.state?.trim() ?? "",
      country: delivery_information.country?.trim() ?? "",
      zipcode: delivery_information.zipcode?.trim() ?? "",
      latitude: "",
      longitude: "",
      special_note: "",
      whats_the_occasion: rec.whats_the_occasion ?? "",
      whats_the_occasion_id: rec.whats_the_occasion_id ?? 0,
      greetings_on_card: rec.greetings_on_card?.trim() ?? "",
      card_signature: rec.card_signature?.trim() ?? "",
      video_url: rec.video_url,
      video_message: rec.video_message,
      video_message_pass_code: rec.video_message_pass_code,
      video_message_qr_code: rec.video_message_qr_code,
      order_from: role === 1 ? 3 : role === 5 ? 2 : 0,
      deleted_recipient_product_id: rec.deleted_product_ids,
      recipient_product: [...products, ...addons],
      recipient_key: rec?.recipient_key ?? `reci_0_0`, // {reci_{order_index}_{recipient_index}}
    };
  });
  let existingRecipient = receipient.filter((c) => c.id !== 0);
  let newreceipient = receipient.filter((c) => c.id === 0);
  
  let order = {
    // is_custom_order: values.is_custom_order,
    // is_custom_order: values.custom_order,
    is_custom_order: customOrderFlag ?? 0,
    order_type_identifier: values.order_type_identifier,
    id: orderDetail.id,
    csr_id: user_id,
    created_by: user_id,
    order_type: delivery_information.order_type,
    source: orderDetail.source ?? "csr",
    driver_instruction: delivery_information.driver_instruction,
    delivery_date: utcTimeToDate(delivery_information.delivery_date_time),
    delivery_type: delivery_information.delivery_type,
    delivery_type_id: delivery_information.delivery_type_id,
    delivered_time_id:
      delivery_information.delivery_time_id === -1
        ? 0
        : delivery_information.delivery_time_id,
    delivered_time: delivery_information.delivery_time,
    is_custom_delivery_matrix:
      delivery_information.delivery_time_id === -1 ? 1 : 0,
    custom_delivery_time: orderDetail.custom_delivery_time,
    custom_delivery_time_ampm: orderDetail.custom_delivery_time_ampm,
    custom_delivery_time_before_after:
      orderDetail.custom_delivery_time_before_after,
    custom_delivery_time_amount: orderDetail.custom_delivery_time_amount,
    shopcode: delivery_information.shop_code,
    external_shop_name: delivery_information.shop_name,
    external_phone_number: delivery_information.phone_number,
    external_person_name: delivery_information.person_name,
    external_order_number: delivery_information.external_order_number,
    designer_instruction: delivery_information.designer_instruction,
    total_qty: summary.total_qty,
    sub_total: +summary.sub_total.toFixed(2),
    custom_reason_for_discount: values.discount.discount_reason,
    discount_percentage: values.discount.discount_percentage,
    discount_price: parseFloat(summary.discount_price).toFixed(2),
    upcharge_id: delivery_information.upcharge_id,
    upcharge_percentage: 0,
    upcharge_amount: delivery_information.upcharge_amount,
    delivery_charges_percentage: 0,
    delivery_charges: +summary.delivery_charges.toFixed(2),
    tax: +summary.tax.toFixed(2),
    is_tax_calculated_manually: values.is_tax_calculated_manually ?? 0,
    tax_rate: +values.tax_rate ?? 0,
    taxable_amount: +values.taxable_amount ?? 0,
    tax_id: values.tax_id,
    flower_total_amount: +summary.flower_total_amount.toFixed(2),
    petal_rewards_id: orderDetail.petal_rewards_id,
    petal_rewards_point_applied: orderDetail.petal_rewards_point_applied,
    petal_rewards_point_amount: orderDetail.petal_rewards_point_amount,
    add_fund: parseFloat(summary.add_fund).toFixed(2),
    refund: parseFloat(summary.refund).toFixed(2),
    reason_message: orderDetail.reason_message ?? "",
    reason_message_option: orderDetail.reason_message_option ?? 0,
    refund_flag: orderDetail.refund_flag ?? 0,
    gift_card_id: orderDetail.gift_card_id ?? 0,
    gift_card_detail: orderDetail.gift_card_detail ?? "",
    gift_card_amount: orderDetail.gift_card_amount ?? 0,
    earned_petal_rewards_point: orderDetail.earned_petal_rewards_point ?? 0,
    petal_rewards_earn_amount: orderDetail.petal_rewards_earn_amount ?? 0,
    total: +summary.total.toFixed(2) ?? 0,
    total_commission_amount:
      orderDetail?.order_transaction?.order_payment_transactions[0]
        ?.transaction_type === "house_account"
        ? 0
        : +summary.total_commission_amount.toFixed(2),
    internal_notes: orderDetail.internal_notes,
    order_status: orderDetail.order_status,
    modified_by: modified_by,
    is_fraud_order: orderDetail.is_fraud_order ? 1 : 0,
    not_available_at_home_key: orderDetail.not_available_at_home_key,
    not_available_at_home_option:
      orderDetail.not_available_at_home_option ?? "",
    is_local_order: delivery_information.is_local ? 1 : 0,
    verified_information: values.verified ? 1 : 0,
    keep_processing: values.keep_processing ? 1 : 0,
    deleted_recipient_id: values.deleted_recipient_ids,
    receipient: existingRecipient,
    is_subscribe_order: values.is_subscribe_order,
    frequency: delivery_information.frequency ?? 0,
    parent_order_id: values.parent_order_id,
    delivery_date_time: delivery_information.delivery_date_time,
    delivery_time_type: delivery_information.delivery_time_type,
    action: action,
  };

  return { order: [order], newreceipient };
}
