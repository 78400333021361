import { callPostApi } from "./api";

export async function getProductOrder(postData) {
    try {
        const response = await callPostApi({ url: "reports/product-orders", body: postData });
        return response;
    } catch (error) {
        throw error;
    }
}

export async function getProductOrderDetail(postData) {
    try {
        const response = await callPostApi({ url: "reports/product-orders-details", body: postData });
        return response;
    } catch (error) {
        throw error;
    }
}
export async function getCommissions(postData) {
    try {
        const response = await callPostApi({ url: "reports/commission", body: postData });
        return response;
    } catch (error) {
        throw error;
    }
}
export async function getCommissionsDetail(postData) {
    try {
        const response = await callPostApi({ url: "reports/commission-orders-details", body: postData });
        return response;
    } catch (error) {
        throw error;
    }
}
export async function getCancelDetails(postData) {
    try {
        const response = await callPostApi({ url: "reports/cancel-orders", body: postData });
        return response;
    } catch (error) {
        throw error;
    }
}

export async function getReplaceRefundIrderDetails(postData) {
    try {
        const response = await callPostApi({ url: "reports/replacements-refunds", body: postData });
        return response;
    } catch (error) {
        throw error;
    }
}

export async function getShopCodeData(postData) {

    try {
        const response = await callPostApi({ url: "reports/shopcode-orders", body: postData });
        return response;
    } catch (error) {
        throw error;
    }
}

export async function getProductivityData(postData) {

    try {
        const response = await callPostApi({ url: "reports/designer-productivity", body: postData });
        return response;
    } catch (error) {
        throw error;
    }
}

export async function getCsrProductivityData(data) {
  try {
    const response = await callPostApi({
      url: "reports/csr_productivity_report",
      body: data,
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export async function getSalesGrowthData(postData) {
    try {
        const response = await callPostApi({ url: "reports/sales-growth", body: postData });
        return response;
    } catch (error) {
        throw error;
    }
}

export async function getRevenueBreakdownData(postData) {
    try {
        const response = await callPostApi({ url: "reports/revenue-breakdown", body: postData });
        return response;
    } catch (error) {
        throw error;
    }
}

export async function getMostPopularProducts(postData) {
    try {
        const response = await callPostApi({ url: "reports/most-popular-products", body: postData });
        return response;
    } catch (error) {
        throw error;
    }
}


export async function getDashboardData(postData) {
    try {
        const response = await callPostApi({ url: "reports/dashboard", body: postData });
        return response;
    } catch (error) {
        throw error;
    }
}

export async function getStockArrangementReport(postData) {
    try {
        const response = await callPostApi({ url: "reports/stock-arrangements-report", body: postData });
        return response;
    } catch (error) {
        throw error;
    }
}

export async function getBusinessAccountReport(postData) {
  try {
    const response = await callPostApi({
      url: "reports/business_account_report",
      body: postData,
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export async function getCouponReports(postData) {
    try {
      const response = await callPostApi({
        url: "reports/coupon_usage_report",
        body: postData,
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
  export async function getFlowerBuyerData(postData) {
    try {
        const response = await callPostApi({ url: "reports/flower-buyer-report", body: postData });
        return response;
    } catch (error) {
        throw error;
    }
  }
    export async function getFlowerRecipeDetails(postData) {
        try {
            const response = await callPostApi({ url: "reports/flower-buyer-report/recipe_Details", body: postData });
            return response;
        } catch (error) {
            throw error;
        }
    }

export async function getFailedRefundReport(postData) {
    try {
        const response = await callPostApi({ url: "reports/payabli-failed-refund-transaction", body: postData });
        return response;
    } catch (error) {
        throw error;
    }
}