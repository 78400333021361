import { makeStyles } from "@material-ui/core";
import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useProcessingOrder } from "../hooks/useProcessingOrder";
import { useFormikContext } from "formik";
import ModalWithReasons from "./ModalWithReasons";
import { useOrderAttributeProvider } from "../context/order";
import Error from "./Error";
import { PAYMENT_METHODS } from "Helpers/Constants";
import { useCustomer } from "Context/Customer/useCustomer";
const useStyles = makeStyles({
  btn: {
    width: "123px",
    height: "50px",
    fontWeight: "bold",
    textTransform: "none",
    fontSize: 20,
    color: "#fff",
    "&:hover, &:focus": {
      color: "#fff !important",
      backgroundColor: "#c2887b",
    },
  },
});
const Submit = () => {
  //PAYABLI
  const { customerState, setPaymentMethodNonce } = useCustomer();
  const classes = useStyles();
  const formik = useFormikContext();
  const {
    modal,
    setModal,
  } = useOrderAttributeProvider();
  const { showErrorModal, processOrder, setShowErrorModal } =
    useProcessingOrder();
  console.log("formik", formik.values)
  const [reasonOptionValue, setReasonOptionValue] = useState(null);
  const [reasonMessageValue, setReasonMessageValue] = useState("");
  const handleProcessOrder = () => {
    if(formik.values.order_type_identifier == 1 && (!Boolean(reasonOptionValue) && !Boolean(reasonMessageValue))){
      setModal("replace-reason");
    }else{
      processOrder()
    }
  }
  const handleReplaceOrder = (val, msg) => {
    if (val && msg) {
      console.log("val , msg", val, msg);
      setReasonOptionValue(val);
      setReasonMessageValue(msg);
      formik.values.message = msg
      formik.values.message_option = val
      setModal("");
      processOrder();
    }
  }
  //PAYABLI
  useEffect(() => {
    if(formik.isValid && formik.values.payment_type === "credit_card" 
        && customerState.paymentNonce){
      //Create new order with credit card payment method and payabli
      formik.values.order_type_identifier
        ? handleProcessOrder()
        : processOrder();
      setPaymentMethodNonce(null);
    }
  }, [customerState.paymentNonce && formik.values.payment_type === "credit_card"]);

  //PAYABLI
  const handlePayabliProcessOrder = () => {
    if(!formik.isValid){
      formik.submitForm();
      setShowErrorModal(true);
      setPaymentMethodNonce(null);
    } else if(formik.isValid && formik.values.payment_type === "credit_card" 
        && !customerState.paymentNonce){
      window.payabliInstance.payabliExec("method");
    }else if(formik.isValid && formik.values.payment_type !== "credit_card"){
      formik.values.order_type_identifier
      ? handleProcessOrder()
      : processOrder();
      setPaymentMethodNonce(null);
    }
  }

  return (
    <>
      <div className="text-center my-4">
        <Error
          open={showErrorModal}
          handleClose={() => setShowErrorModal(false)}
        />
        {/* //PAYABLI */}
        {formik.values.billing_info.payment_method ===
        PAYMENT_METHODS.PAYABLI ? (
          <Button
            type="button"
            className={classes.btn}
            variant="contained"
            color="primary"
            size="large"
            onClick={handlePayabliProcessOrder}
          >
            Save
          </Button>
        ) : (
          <Button
            type="button"
            className={classes.btn}
            variant="contained"
            color="primary"
            size="large"
            onClick={
              formik.values.order_type_identifier
                ? handleProcessOrder
                : processOrder
            }
          >
            Save
          </Button>
        )}
      </div>
      <ModalWithReasons
        showModal={modal === "replace-reason"}
        handleCancelClick={() => setModal("")}
        handleSubmitClick={handleReplaceOrder}
        pageTitle="Replace Order"
        placeholder="replacing"
      />
    </>
  );
};

export default Submit;
