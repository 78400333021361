import React, { useEffect } from "react";
import moment from "moment";
import { Button, Checkbox, Input, MenuItem, Popover } from "@material-ui/core";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FiberManualRecord, RadioButtonUnchecked } from "@material-ui/icons";
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
  useRowSelect,
} from "react-table";
import { matchSorter } from "match-sorter";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import HourglassEmptySharpIcon from "@material-ui/icons/HourglassEmptySharp";
import Pagination from "@material-ui/lab/Pagination";
import PropTypes from "prop-types";
import SearchIcon from "@material-ui/icons/Search";
import Select from "../FormControl/Select/Select";
// import DatePicker from "../NewOrder/DatePicker/DatePicker";
import classnames from "classnames";
import { isEqual } from "lodash";

import CarbonSettings from "./../../Assets/images/icons/carbon_settings-adjust.svg";
import "./Table.scss";
import CustomScroll from "react-custom-scroll";
import "react-custom-scroll/dist/customScroll.css";

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    rest.title = "";
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <FormControlLabel
        control={<Checkbox {...rest} />}
        // label="Toggle All"
        ref={resolvedRef}
        className={`customCheckBoxCss`}
      />
    );
  }
);
// Define a default UI for filtering
function GlobalFilter({ globalFilter, setGlobalFilter }) {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = (value) => {
    setGlobalFilter(value || "");
  };
  return (
    <Input
      value={value || ""}
      startAdornment={<SearchIcon />}
      onChange={(e) => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
      placeholder="Search records..."
    />
  );
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: {
    filterValue,
    setFilter,
    Header,
    isSelectFilter,
    isDateFilter,
    selectFilterOption,
    dateFormatValue,
  },
  visibleColumns,
}) {
  const [selectedDate, setSelectedDate] = React.useState("");
  const dateFilter = (day, DF) => {
    setFilter(day ? moment(day).format(DF ? DF : "YYYY-MM-DD") : "");
    setSelectedDate(day);
  };
  const filterColumn = visibleColumns.find((x) => x.Header === Header);
  return isSelectFilter ? (
    <Select
      value={filterValue}
      onChange={(data) => {
        setFilter(data || undefined);
      }}
      options={selectFilterOption || [{ id: "", title: "All" }]}
      parentClass="m-0 p-0"
      usedFor="Filter"
    />
  ) : isDateFilter ? (
    <>
      <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-adornedStart">
        <DatePicker
          selected={selectedDate}
          onChange={(date) => dateFilter(date, dateFormatValue)}
          isClearable={selectedDate ? true : false}
          placeholderText="mm/dd/yyyy"
          dateFormat="yyyy/MM/dd"
          className="MuiInputBase-input MuiInput-input"
        />
      </div>
      {/* <DatePicker
        className="customDatePickerW80"
        placeholder="mm/dd/yyyy"
        value={ddv.value}
        dateFormat="MM-DD-YYYY"
        showDateAll={true}
        onSelect={(e) => {daySelect(e,dateFormatValue)}}
        textFiledClass={`tablfilterDateAndTime`}
        id="date"
      />
      <label className="customDeleteIcon" onClick={()=>resetFilter()}>clear</label>  */}
    </>
  ) : (
    <Input
      value={filterValue || ""}
      startAdornment={<SearchIcon />}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={
        filterColumn && filterColumn.placeholder
          ? filterColumn.placeholder
          : `Search ${Header}`
      }
    />
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component
function Table({
  columns,
  data,
  fetchData,
  loading,
  noDataText = "No data available.",
  pageCount: controlledPageCount,
  defaultFilter,
  displayGlobalSearch = false,
  displayColumnVisibility = false,
  displayLastColumnSorting = false,
  isCustomSearch,
  selectedColumn,
  showCheckBox = false,
  selectedCheckBoxValue,
  manualPagination= true,
  updateMyData,
  resetPagination
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const filterTypes = React.useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize, globalFilter, sortBy, filters },
    visibleColumns,
    setGlobalFilter,
    pageCount,
    gotoPage,
    allColumns,
    getToggleHideAllColumnsProps,
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: defaultFilter.pageSize || 10,
        pageIndex: defaultFilter.pageIndex || 0,
        globalFilter: defaultFilter.globalFilter || "",
        sortBy: defaultFilter.sortBy || [],
        hiddenColumns: columns
          .filter((el) => !el.isVisible)
          .map((el) => el.accessor),
        filters: defaultFilter.filters || [],
      },
      stateReducer:(option)=>{
        if(resetPagination){
          return {
            ...option,
            pageIndex:defaultFilter.pageIndex || 0
          }  
        }
      },
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      manualPagination,
      manualSortBy: true,
      manualFilters: true,
      manualGlobalFilter: true,
      pageCount: controlledPageCount,
      updateMyData
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (showCheckBox) {
        hooks.visibleColumns.push((columns) => [
          // Let's make a column for selection
          {
            id: "selection",
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns,
        ]);
      }
    }
  );
  // console.log("selectedColumn=>", selectedColumn);
  //if visible column change the send data to relevent page for state save if want
  useEffect(() => {
    if (selectedColumn) {
      let columnDataState = {};
      allColumns.forEach((data) => {
        columnDataState[data.id] = data.isVisible;
      });
      selectedColumn(columnDataState);
    }
  }, [visibleColumns, selectedColumn]);

  // FOR GET SELECTED CHECKBOX DATA
  useEffect(() => {
    if (showCheckBox) {
      let onlyId = selectedFlatRows.map((data) => data.original.id);
      selectedCheckBoxValue(onlyId);
    }
  }, [showCheckBox, selectedFlatRows]);

  useEffect(() => {
    fetchData({ pageIndex, pageSize, sortBy, globalFilter, filters });
  }, [pageIndex, pageSize, sortBy]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let timer = setTimeout(() => {
      if (globalFilter !== "" && globalFilter !== undefined) {
        fetchData({ pageIndex, pageSize, sortBy, globalFilter, filters });
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [globalFilter]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const timer = setTimeout(() => {
      // const newFilter = filters.filter((x) => x.value.length > 3);
      //OPEN THIS CONTIDION WHEN WE WANT TO ADD FILTER IN STATUS DROPDOWN , NOTE : WHEN OPEN THIS NEED TO CHECK ALL API WHO HAVE FILTER IN THIS
      const customFilter = columns.filter((el) => el.isCustomFilter)
      const newFilter = filters.filter((x, index) => {
        const customFilterIndex = customFilter.findIndex((el) => el.accessor === x.id)
        if(customFilter[customFilterIndex]?.accessor === x.id){
          return x.value.length > customFilter[customFilterIndex]?.isCustomFilterLength
        }else if (x.id !== "status" && x.id !== "is_non_local_only") {
          return x.value.length > 3;
        }else {
          return x;
        }
      });
      if (!isEqual(defaultFilter.filters, newFilter)) {
        fetchData({
          pageIndex,
          pageSize,
          sortBy,
          globalFilter,
          filters: newFilter,
        });
        gotoPage(0);
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [filters]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isCustomSearch) {
      gotoPage(0);
    }
  }, [isCustomSearch]); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   debugger
  //   selectedColumn(allColumns)
  // }, [])

  const showLastColumnSorting = displayLastColumnSorting
    ? "-sortingLastColumn"
    : "";

  return (
    <React.Fragment>
      <div
        className={`ReactTable -striped -highlight ${showLastColumnSorting} pb-4 pb-xl-5`}
      >
        <div className="d-flex justify-content-end">
          {displayGlobalSearch && (
            <div>
              <GlobalFilter
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            </div>
          )}
          <div className="column-visibility-wrap">
            {displayColumnVisibility && (
              <React.Fragment>
                <Button
                  aria-controls="simple-menu"
                  onClick={handleClick}
                  style={{ textTransform: "none", fontWeight: "bold" }}
                  aria-describedby="simple-popover"
                >
                  <img
                    src={CarbonSettings}
                    alt="CarbonSettings"
                    className="mr-2"
                  />
                  Settings
                </Button>
                <Popover
                  id="simple-popover"
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  className="column-visibility-popover"
                >
                  <CustomScroll>
                    <div
                      style={{ minWidth: 200, maxHeight: 420 }}
                      className="column-visibility-menu"
                    >
                      <MenuItem>
                        <IndeterminateCheckbox
                          {...getToggleHideAllColumnsProps()}
                          color="default"
                        />
                      </MenuItem>
                      {allColumns.map((column, index) => (
                        <MenuItem key={index}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                icon={<RadioButtonUnchecked fontSize="small" />}
                                checkedIcon={
                                  <FiberManualRecord fontSize="small" />
                                }
                                {...column.getToggleHiddenProps()}
                                name="selectedColumns"
                                color="default"
                              />
                            }
                            label={column.Header}
                          />
                        </MenuItem>
                      ))}
                    </div>
                  </CustomScroll>
                </Popover>
              </React.Fragment>
            )}
          </div>
        </div>
        <div className="tableWrap">
          <table {...getTableProps()} className="rt-table table">
            <thead className="rt-thead -header">
              {headerGroups.map((headerGroup, headerGroupIndex) => (
                <tr
                  key={headerGroupIndex}
                  {...headerGroup.getHeaderGroupProps()}
                  className="rt-tr"
                >
                  {headerGroup.headers.map((column, key) => (
                    <th
                      key={key}
                      className={classnames("rt-th rt-resizable-header", {
                        "-cursor-pointer":
                          headerGroup.headers.length - 1 !== key &&
                          column.render("canSort"),
                        "-sort-asc": column.isSorted && !column.isSortedDesc,
                        "-sort-desc": column.isSorted && column.isSortedDesc,
                      })}
                      style={{
                        minWidth: column.colWidth ? column.colWidth : 100,
                      }}
                    >
                      <div
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        className="rt-resizable-header-content"
                      >
                        <span>{column.render("Header")}</span>
                      </div>

                      {headerGroup.headers.length - 1 ===
                      key ? null : column.canFilter ? (
                        <div className={`rt-filter-wapper`}>
                          {column.render("Filter")}
                        </div>
                      ) : null}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} className="rt-tbody">
              {loading ? (
                <tr>
                  <td colSpan={visibleColumns.length} className="text-center">
                    <div className="wrapper">
                      <HourglassEmptySharpIcon />
                      <p className="pl-2">Loading...</p>
                    </div>
                  </td>
                </tr>
              ) : page.length === 0 ? (
                <tr>
                  <td className="text-center" colSpan={visibleColumns.length}>
                    <span className="no-data-message">{noDataText}</span>
                  </td>
                </tr>
              ) : (
                page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr
                      key={i}
                      {...row.getRowProps()}
                      className={classnames(
                        "rt-tr",
                        { " -odd": i % 2 === 0 },
                        { " -even": i % 2 === 1 }
                      )}
                    >
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()} className="rt-td">
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
        <div className="pagination-bottom mt-3 d-flex justify-content-end">
          <Pagination
            className="custom-pagination"
            count={pageCount}
            page={pageIndex + 1}
            onChange={(e, value) => {
              gotoPage(value - 1);
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
}

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  fetchData: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  noDataText: PropTypes.string,
  defaultFilter: PropTypes.object.isRequired,
  displayGlobalSearch: PropTypes.bool,
  displayColumnVisibility: PropTypes.bool,
  displayLastColumnSorting: PropTypes.bool,
  showCheckBox: PropTypes.bool,
};

export default Table;
