/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState } from "react";
import { ProductPrimaryInfoForm } from "../../Hooks/product/productPrimaryInfoFormFromik";
import { useProduct } from "../../Context/ProductCrud/useProduct";
// Context
import { AppContext } from "./../../Context/AppContext";
// SCSS
import styles from "./index.module.scss";
// COMPONENTS
import Select from "../../Components/FormControl/Select/Select";
import Text from "../../Components/FormControl/Text/Text";
// import MultipalImage from "../Cms/MultipalImage";
import TextArea from "../FormControl/TextArea/TextArea";
import UtilsApi from "../../Services/Utils/Utils";
import { uploadImage } from "../../api/utils";
import ImageGallery from "../../CommonComponents/ImageGallery";
import WYSIWIGEditor from "Components/WYSIWIGEditor/WYSIWIGEditor";
import { CUSTOM_PRODUCT, SUBSCRIPTION_ITEM } from "Helpers/Constants";
function PrimaryInformation(props) {
  const utilsService = new UtilsApi();
  const { showToastMessage, setIsLoading } = useContext(AppContext);

  // const [description, setDescription] = useState({});
  // const [careDescription, setCareDescription] = useState({});
  // const [value, setValue] = React.useState();
  // const [submitStatus, setSubmitStatus] = useState(false);
  // const [productImages, setProductImages] = useState([]);
  // const [productPrimaryData, setProductPrimaryData] = useState([]);

  // const addImage = () => {
  //   setProductImages((productImages) => [...productImages, <MultipalImage />]);
  // };

  const {
    productState,
    setProductPrimaryData,
    checkProductPrimaryData,
    setProductImageData,
    updateProductImageData,
    deleteProductImageData,
    setDeleteProductImageData,
  } = useProduct();

  const [typeList, setTypeList] = useState([
    { id: "NON_ADD_ONS", title: "Non Add On" },
    { id: "ADD_ONS", title: "Add On" },
  ]);

  let productPrimaryInfoValue = {
    type: productState.setProductAllData.type ?? "NON_ADD_ONS",
    inventory_remaining:
      productState.setProductAllData.inventory_remaining ?? "",
    title: productState.setProductAllData.title ?? "",
    sequence_number: productState.setProductAllData.sequence_number ?? "",
    status: productState.setProductAllData.status ?? 1,
    sku_id: productState.setProductAllData.sku_id ?? "",
    keywords: productState.setProductAllData.keywords ?? "",
    customer_note: productState.setProductAllData.customer_note ?? "",
    lead_time: productState.setProductAllData.lead_time ?? "",
    non_local_lead_time: productState.setProductAllData.non_local_lead_time ?? "",
    // product_images: productState.setProductAllData.product_images ?? [],
    description: productState.setProductAllData.description ?? "",
    care_description: productState.setProductAllData.care_description ?? "",
    declaimer: productState.setProductAllData.declaimer ?? "",
    did_you_know: productState.setProductAllData.did_you_know ?? "",
    after_care: productState.setProductAllData.after_care ?? "",
    delivery_info: productState.setProductAllData.delivery_info ?? "",
  };

  const submit = (values) => {};
  const cbSubmit = (values) => {
    setProductPrimaryData(values);
  };

  const { formik: ProductPrimaryInfoFormik } = ProductPrimaryInfoForm(
    submit,
    productPrimaryInfoValue,
    cbSubmit,
    checkProductPrimaryData
  );

  const handleUploadImage = async (imageData, imageType = "OTHER") => {
    if (imageData && imageData.length > 0) {
      let imageFormdata = new FormData();
      imageData.forEach((image) => {
        imageFormdata.append("image", image);
      });
      // imageFormdata.append("image", imageData);
      imageFormdata.append("type", imageType);
      setIsLoading(true);
      // const response = await utilsService.uploadImage(imageFormdata);
      const response = await uploadImage(imageFormdata);

      if (response && response.status === 200) {
        let imageUrl = response.data.data[0]["url"];
        setProductImageData({
          image: imageUrl,
          is_default_selected: false,
          src: imageUrl,
        });
        setIsLoading(false);
      } else {
        setIsLoading(false);
        showToastMessage(undefined, response.data.message, "error", false);
      }
    }
  };

  const handleRemoveImage = (event, i) => {
    event.stopPropagation();
    setDeleteProductImageData(i);
    deleteProductImageData(i);
  };

  // console.log("product state ==>",productState);
  //Get product slug 
  const productSlug = productState.product_primary_info.slug
  const isSubscriptionAndCustomProduct = productSlug === SUBSCRIPTION_ITEM || productSlug === CUSTOM_PRODUCT;

  return (
    <form
      onSubmit={(e) => {
        ProductPrimaryInfoFormik.handleSubmit(e);
      }}
      className={`row`}
    >
      <div className={`${styles.productsWrapper}`} id="primary-information">
        <div className="">
          <div className="">
            <div className={styles.title}>Primary Information</div>
            {/* <span
              className={`link cursor-pointer ml-2 ${styles.searchIconWrapper}`}
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <img className={styles.searchIcon} src={AddIcon} alt="" />
            </span> */}
            <div className={`mt-2 md-4`}>
              <div className={`row`}>
                <div className={`col-md-6 col-xs-12`}>
                  <Select
                    label="Type"
                    parentClass="text-left"
                    value={ProductPrimaryInfoFormik.values.type}
                    required={false}
                    submitStatus={props.submitStatus}
                    onChange={(data) =>
                      ProductPrimaryInfoFormik.setValues((v) => {
                        return { ...v, type: data };
                      })
                    }
                    options={typeList}
                    disabled={props.isDisableFields || isSubscriptionAndCustomProduct}
                    blur={ProductPrimaryInfoFormik.handleBlur}
                    error={ProductPrimaryInfoFormik.errors.type}
                    touched={ProductPrimaryInfoFormik.touched.type}
                    id="type"
                  />
                </div>
                <div className={`col-md-6 col-xs-12`}>
                  <Text
                    type="text"
                    label="Inventory Remaining"
                    value={ProductPrimaryInfoFormik.values.inventory_remaining.toString()}
                    required={true}
                    submitStatus={props.submitStatus}
                    onChange={(data) => {
                     
                      if (!/^[0-9]*$/.test(data)) {
                        return;
                      }
                      let val = (data == "") ? '' : (+data).toString() || "";
                      ProductPrimaryInfoFormik.setValues((v) => {
                        return { ...v, inventory_remaining: val };
                      });
                    }}
                    onBlur={ProductPrimaryInfoFormik.handleBlur}
                    error={ProductPrimaryInfoFormik.errors.inventory_remaining}
                    touched={
                      ProductPrimaryInfoFormik.touched.inventory_remaining
                    }
                    id="inventory_remaining"
                    disabled={props.isDisableFields}
                    
                  />
                </div>
              </div>
              <div className={`row`}>
                <div className={`col-md-12 col-xs-12`}>
                  <Text
                    type="text"
                    label="Title"
                    value={ProductPrimaryInfoFormik.values.title}
                    required={true}
                    submitStatus={props.submitStatus}
                    onChange={(data) =>
                      ProductPrimaryInfoFormik.setValues((v) => {
                        return { ...v, title: data };
                      })
                    }
                    error={ProductPrimaryInfoFormik.errors.title}
                    touched={ProductPrimaryInfoFormik.touched.title}
                    id="title"
                    disabled={props.isDisableFields}
                  />
                </div>
                {/* comment by darshan for not give this in release on 1-7-2021 */}
                {/* <div className={`col-md-6 col-xs-12`}>
                  <Text
                    type="number"
                    label="Sequence Number"
                    value={ProductPrimaryInfoFormik.values.sequence_number}
                    required={true}
                    submitStatus={props.submitStatus}
                    onChange={(data) =>
                      ProductPrimaryInfoFormik.setValues((v) => {
                        return { ...v, sequence_number: +data };
                      })
                    }
                    error={ProductPrimaryInfoFormik.errors.sequence_number}
                    touched={ProductPrimaryInfoFormik.touched.sequence_number}
                    id="sequence_number"
                  />
                </div> */}
              </div>
              <div className={`row`}>
                <div className={`col-md-6 col-xs-12`}>
                  <Text
                    type="text"
                    label="SKU ID"
                    value={ProductPrimaryInfoFormik.values.sku_id}
                    required={true}
                    submitStatus={props.submitStatus}
                    onChange={(data) =>
                      ProductPrimaryInfoFormik.setValues((v) => {
                        return { ...v, sku_id: data };
                      })
                    }
                    id="sku_id"
                    disabled={props.isDisableFields}
                  />
                </div>
                {
                  isSubscriptionAndCustomProduct && (
                    <div className={`col-md-6 col-xs-12`}>
                      <label>Status</label>
                      <div className={styles.status}>
                        <div
                          className={styles.Active}
                          style={{ "font-weight": "bold" }}
                        >
                          Active
                        </div>
                      </div>
                    </div>
                  )
                }
                {!isSubscriptionAndCustomProduct && (ProductPrimaryInfoFormik.values.status === 1 ? (
                  <div className={`link cursor-pointer col-md-6 col-xs-12`}>
                    <label>Status</label>
                    <div className={styles.status}>
                      <div
                        className={styles.Active}
                        style={{ "font-weight": "bold" }}
                      >
                        Active
                      </div>
                      <div
                        className={styles.Inactive}
                        onClick={(data) =>
                          ProductPrimaryInfoFormik.setValues((v) => {
                            return { ...v, status: 0 };
                          })
                        }
                      >
                        Inactive
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={`link cursor-pointer col-md-6 col-xs-12`}>
                    <label>Status</label>
                    <div className={styles.status}>
                      <div
                        className={styles.Active}
                        onClick={(data) =>
                          ProductPrimaryInfoFormik.setValues((v) => {
                            return { ...v, status: 1 };
                          })
                        }
                      >
                        Active
                      </div>
                      <div
                        className={styles.Inactive}
                        style={{ "font-weight": "bold" }}
                      >
                        Inactive
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className={`row`}>
                <div className={`col-md-6 col-xs-12`}>
                  <Text
                    type="text"
                    label="Keywords"
                    value={ProductPrimaryInfoFormik.values.keywords}
                    required={true}
                    submitStatus={props.submitStatus}
                    onChange={(data) =>
                      ProductPrimaryInfoFormik.setValues((v) => {
                        return { ...v, keywords: data };
                      })
                    }
                    id="keywords"
                    disabled={props.isDisableFields}
                  />
                </div>

                <div className={`col-md-3 col-xs-12`}>
                  <Text
                    type="text"
                    label="Local Lead Time"
                    value={ProductPrimaryInfoFormik.values.lead_time.toString()}
                    // required={true}
                    submitStatus={props.submitStatus}
                    onChange={(data) => {
                      if (!/^[0-9]*$/.test(data)) {
                        return;
                      }
                      if (data.length > 8) {
                      } else {
                        let val = (+data).toString() || "0";
                        ProductPrimaryInfoFormik.setValues((v) => {
                          return { ...v, lead_time: val };
                        });
                      }
                    }}
                    id="lead_time"
                    disabled={props.isDisableFields}
                  />
                </div>
                <div className={`col-md-3 col-xs-12`}>
                  <Text
                    type="text"
                    label="Non Local Lead Time"
                    value={ProductPrimaryInfoFormik.values.non_local_lead_time.toString()}
                    // required={true}
                    submitStatus={props.submitStatus}
                    onChange={(data) => {
                      if (!/^[0-9]*$/.test(data)) {
                        return;
                      }
                      if (data.length > 8) {
                      } else {
                        let val = (+data).toString() || "0";
                        ProductPrimaryInfoFormik.setValues((v) => {
                          return { ...v, non_local_lead_time: val };
                        });
                      }
                    }}
                    id="non_local_lead_time"
                    disabled={props.isDisableFields}
                  />
                </div>
              </div>
              <div className={`row`}>
                {/* comment by darshan for not give this in release on 1-7-2021 */}
                {/* <div className={`col-md-12 col-xs-12`}>
                  <TextArea
                    type="text"
                    label="Customer Note"
                    rows={3}
                    value={ProductPrimaryInfoFormik.values.customer_note}
                    required={true}
                    submitStatus={props.submitStatus}
                    onChange={(data) =>
                      ProductPrimaryInfoFormik.setValues((v) => {
                        return { ...v, customer_note: data };
                      })
                    }
                    id="customer_note"
                  />
                </div> */}
              </div>
              <div className={`row`}>
                <div className={`col-md-12 mt-2 mb-4`}>
                  <div className={styles.productTitle}>Product Image</div>

                  <div className={`${styles.productWrapper}`}>
                    <ImageGallery
                      imageData={productState.product_images}
                      handleUploadImage={handleUploadImage}
                      handleRemoveImage={handleRemoveImage}
                      updateProductImageData={updateProductImageData}
                      setPageForView={productState.setPageForView}
                      isDisableFields={props.isDisableFields}
                    />
                  </div>
                </div>
              </div>

              <div className={`row`}>
                <label
                    className="bmd-label-floating"
                    style={{ marginLeft: "15px" }}
                  >
                    Description
                </label>
                <div className={`col-md-12 mt-2 mb-4`}>

                  <WYSIWIGEditor 
                    data={ProductPrimaryInfoFormik.values.description}
                    onChange={(data) =>
                      ProductPrimaryInfoFormik.setValues((v) => {
                        return { ...v, description: data };
                      })
                    }
                    error={ProductPrimaryInfoFormik.errors.description}
                    touched={ProductPrimaryInfoFormik.touched.description}
                    isDisableFields={props.isDisableFields}
                  />
                  {/* <TextArea
                    type="text"
                    label="Description"
                    required={true}
                    rows={3}
                    value={ProductPrimaryInfoFormik.values.description}
                    onChange={(data) =>
                      ProductPrimaryInfoFormik.setValues((v) => {
                        return { ...v, description: data };
                      })
                    }
                    id="description"
                    disabled={false}
                    error={ProductPrimaryInfoFormik.errors.description}
                    touched={ProductPrimaryInfoFormik.touched.description}
                  /> */}
                </div>
              </div>
              <div className={`row`}>
                <div className={`col-md-12 mt-2 mb-4`}>
                  <TextArea
                    type="text"
                    label="Care Description"
                    rows={3}
                    value={ProductPrimaryInfoFormik.values.care_description}
                    required={true}
                    submitStatus={props.submitStatus}
                    onChange={(data) =>
                      ProductPrimaryInfoFormik.setValues((v) => {
                        return { ...v, care_description: data };
                      })
                    }
                    id="care_description"
                    disabled={props.isDisableFields}
                  />
                </div>
              </div>
              <div className={`row`}>
                <div className={`col-md-12 mt-2 mb-4`}>
                  <TextArea
                    type="text"
                    label="Disclaimer"
                    rows={3}
                    value={ProductPrimaryInfoFormik.values.declaimer}
                    required={true}
                    submitStatus={props.submitStatus}
                    onChange={(data) =>
                      ProductPrimaryInfoFormik.setValues((v) => {
                        return { ...v, declaimer: data };
                      })
                    }
                    id="declaimer"
                    disabled={props.isDisableFields}
                  />
                </div>
              </div>
              <div className={`row`}>
                <div className={`col-md-12 mt-2 mb-4`}>
                  <TextArea
                    type="text"
                    label="Did you know"
                    rows={3}
                    value={ProductPrimaryInfoFormik.values.did_you_know}
                    required={true}
                    submitStatus={props.submitStatus}
                    onChange={(data) =>
                      ProductPrimaryInfoFormik.setValues((v) => {
                        return { ...v, did_you_know: data };
                      })
                    }
                    id="did_you_know"
                    disabled={props.isDisableFields}
                  />
                </div>
              </div>
              <div className={`row`}>
                <div className={`col-md-12 mt-2 mb-4`}>
                  <TextArea
                    type="text"
                    label="After Care"
                    rows={3}
                    value={ProductPrimaryInfoFormik.values.after_care}
                    required={true}
                    submitStatus={props.submitStatus}
                    onChange={(data) =>
                      ProductPrimaryInfoFormik.setValues((v) => {
                        return { ...v, after_care: data };
                      })
                    }
                    id="after_care"
                    disabled={props.isDisableFields}
                  />
                </div>
              </div>
              <div className={`row`}>
                <div className={`col-md-12 mt-2 mb-4`}>
                  <TextArea
                    type="text"
                    label="Delivery Info"
                    rows={3}
                    value={ProductPrimaryInfoFormik.values.delivery_info}
                    required={true}
                    submitStatus={props.submitStatus}
                    onChange={(data) =>
                      ProductPrimaryInfoFormik.setValues((v) => {
                        return { ...v, delivery_info: data };
                      })
                    }
                    id="delivery_info"
                    disabled={props.isDisableFields}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        className="d-none"
        id="primary_info_submit_button"
        type="submit"
      ></button>
    </form>
  );
}

export default PrimaryInformation;
