import React, { useState } from "react";
import AutocompleteInput from "../FormControl/Autocomplete/Autocomplete";
import classes from "./index.module.scss";
import CardContent from "@material-ui/core/CardContent";
import CloseIcon from "../../Assets/images/close-icon.svg";

const ProductCategory = (props) => {
  const [value, setValues] = useState("");
  const [allDataShow, setAllDataShow] = useState("ALL");
  const MAX_ITEMS = 10;
  const [submitStatus, setSubmitStatus] = useState(false);
  const [showCategoryErrorDiv, setShowCategoryErrorDiv] = useState("");

  const categoriesAllData = props.selectedCategoryList ?? "";
  const categories = props.selectedCategoryList ?? "";

  const deleteCategoryData = (index) => {
    props.deleteCategoryHierarchyData(index);
  };

  const renderCategory = () => {
    if (allDataShow === "ALL") {
      return categories;
    }
    return categories.slice(0, MAX_ITEMS);
  };

  const loadMoreData = (e) => {
    if (allDataShow === "ALL") {
      setAllDataShow("");
    } else {
      setAllDataShow("ALL");
    }
  };
  // console.log("props", props);
  return (
    <div className={`mt-4 mb-2 ${classes.productCard}`}>
      <CardContent>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
          className={`row`}
        >
          <div className={`col-md-12`}>
            {/* <Text
              type="text"
              placeholder="Add Category..."
              label="Category"
              //   value={bannerFormik.values.bannerText}
              required={true}
              onChange={(data) =>
                // bannerFormik.setValues((v) => {
                //   return { ...v, bannerText: data };
                // })
                console.log(data)
              }
              //   error={bannerFormik.errors.bannerText}
              disabled={false}
            /> */}
            <AutocompleteInput
              label="Add Category..."
              value={value}
              required={true}
              submitStatus={submitStatus}
              onChangeParent={(data) => {
                setShowCategoryErrorDiv("");
                if (data.categoryId) {
                  let indexOfarrayOfProduct = "-1";
                  if (
                    categoriesAllData !== "" &&
                    categoriesAllData !== undefined
                  ) {
                    indexOfarrayOfProduct = categoriesAllData.findIndex(
                      (categoriesAllData) => {
                        return categoriesAllData.category_hierarchy_id === data.category_hierarchy_id;
                      }
                    );
                  }

                  if (indexOfarrayOfProduct == -1) {
                    // console.log("data=>",data);
                    props.setCategoryHierarchyData(data);
                    setValues("");
                  } else {
                    setShowCategoryErrorDiv(1);
                    setValues("");
                  }
                } else {
                  if (data === "") {
                  }
                }
              }}
              onChangeParentManual={(data) => {}}
              disabled={props.isDisableFields}
              listData={props.categoryList}
              id="categorySearching"
            />
            {showCategoryErrorDiv === 1 ? (
              <p className={classes.paragraphBackground}>
                This Category is already Added
              </p>
            ) : (
              <p></p>
            )}
          </div>
        </form>
        {categories.length === 0 ? (
          <div></div>
        ) : (
          renderCategory()?.map((d, i) => {
            return (
              <div className={classes.addCategory} key={i}>
                <span>{d.title}</span>
                &nbsp;
                {props.setPageForView === 1 ? (
                  <span></span>
                ) : (
                  <img
                    className={`link cursor-pointer ${classes.closeIcon}`}
                    src={CloseIcon}
                    alt="Add"
                    onClick={(e) => {
                      !props.isDisableFields && deleteCategoryData(i);
                    }}
                  />
                )}
                &nbsp;
              </div>
            );
          })
        )}
        {categoriesAllData.length < 10 ? (
          <div className={"col-md-12"}></div>
        ) : (
          <div className={"col-md-12"}>
            <span
              
              onClick={(e) => {
                e.preventDefault();
                loadMoreData();
              }}
              className="btn gray-btn d-inline-block mb-1 mr-2"
            >
              {allDataShow === "ALL" ? "Show Less" : "Show More"}
            </span>
          </div>
        )}
      </CardContent>
    </div>
  );
};
export default ProductCategory;
