import Header from 'Components/Header/Header'
import CalendarIcon from "../../../Assets/images/calendar.svg";
import SearchIcon from "../../../Assets/images/search.svg";
import CloseIcon from "../../../Assets/images/close.svg";
import React, { useContext, useEffect, useState } from 'react'
import moment from "moment";
import Date from 'Components/FormControl/Date/Date';
import Select from "../../../Components/FormControl/Select/Select";
import styles from "./index.module.scss";
import RadioBox from 'Components/FormControl/RadioBox/RadioBox';
import { getFlowerBuyerData, getFlowerRecipeDetails } from 'api/report';
import TableContainer from 'Components/Table/TableContainer';
import RecipeIngredients from './RecipeIngredients';
import NonRecipeOrderProducts from './NonRecipeOrderProducts';
import ViewIcon from "../../../Assets/images/error-outline.svg"
import ProductRecipeDetailsModel from "../../../Components/Modal/FlowerBuyerReport/productRecipeDetailsModel"
import { AppContext } from 'Context/AppContext';
import CheckBox from 'Components/FormControl/CheckBox/CheckBox';
import { setAllStore } from 'Helpers/setAllStore';
const FlowerBuyerReport = () => {
  const { showToastMessage } = useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("delivery_date");
  const [fromDate, setFromDate] = useState(
    moment().endOf("day").format("ddd MMM DD, YYYY")
    );
    const [toDate, setToDate] = useState(
    moment().add(7, "days").startOf("day").format("ddd MMM DD, YYYY")
    );

  const [deliveryType,setDeliveryType]=useState(0)
  
  const [defaultFilter, setDefaultFilter] = useState({
    pageIndex: 0,
    pageSize: 200,
    sortBy: [],
    filters: [],
    globalFilter: "",
  });
  const [data,setData]=useState([])
  const [recipeIngredients,setRecipeIngredients] = useState([]);
  const [nonRecipeOrderProducts,setNonRecipeOrderProducts] = useState([])
  const [TotalLocalOrder,setTotalLocalOrder] = useState(0);
  const handleFromDate = async (fromDate) => {
    setFromDate(fromDate);
  };
  // Handle On End Date Change Validation
  const handleToDate = async (toDate) => {
    setToDate(toDate);
  };
  const [showModal,setShowModal] = useState(false);
  const [productRecipe,setProductRecipe] = useState({});

  // const [checkAllStore,setCheckAllStore] = useState(true);

  useEffect(()=>{
    fetchData(defaultFilter)
  },[])
  
  const fetchData = async ({
    pageIndex,
    pageSize,
    sortBy,
    globalFilter,
    filters,
    fromDateDefault,
    toDateDefault,
  }) => {

    /*
      # when all store checkbox is checked at that time we set page_wise_all_store_checkbox=0 in local storage & this logic write in setAllStore function
      
    */
      // setAllStore(checkAllStore);

    setDefaultFilter({ pageSize, pageIndex, filters, globalFilter, sortBy });
    setLoading(true);
    let postData = {
      currentPage: pageIndex,
      itemsPerPage: pageSize,
      globalSearch: globalFilter,
      deliveryType: deliveryType,
      searchBy: type,
      start_date: fromDateDefault ?? moment(fromDate).format("YYYY-MM-DD"),
      end_date: toDateDefault ?? moment(toDate).format("YYYY-MM-DD"),
    };

      const response = await getFlowerBuyerData(postData);
      if (response && response.status === 200) {
        setData(response.data.data.recipe_order_products);
        setNonRecipeOrderProducts(response.data.data.non_recipe_order_products.map((elem)=>({...elem,product_name:elem.product.title,
        unit_price:`$${elem.unit_price}`
        })))
        setRecipeIngredients(
          response.data.data.recipe_ingredients.map((elem)=>(
            {
              type:elem?.raw_material_type,
              name:elem?.raw_material_name,
              unit:elem.unit,
              qty:elem.total_qty,
              inventory_products_qty:elem?.inventory_products_qty,
              // predicted_qty: type === "orderDate" ? "-" : elem?.predicted_qty.toFixed(2),
              forecasting_qty: type === "orderDate" ? "-" : elem?.forecasting_qty.toFixed(2)
            })))
            setTotalLocalOrder(response.data.data.total_local_orders)
      }else {
        showToastMessage(undefined, response.data.message, "error", false);
      }

    setLoading(false);

    //when the all store checkbox is checked and we get all store data(after the API call) then we set the selected store id=dropdown selected store in local storage and this logic writes in setAllStore  function
    // if(checkAllStore){ setAllStore(false) }
  };

  const handleClearSearch = (event)=>{
    event.preventDefault();
    setFromDate(moment().subtract(30, "days").startOf("day").format("ddd MMM DD, YYYY"));
    setToDate(moment().endOf("day").format("ddd MMM DD, YYYY"));
    fetchData({
      ...defaultFilter,
      fromDateDefault: moment().subtract(30, "days").startOf("day").format("ddd MMM DD, YYYY"),
      toDateDefault: moment().endOf("day").format("ddd MMM DD, YYYY"),
    });
    setDeliveryType(0);
  }

  const viewRecipeDetails = async(data) =>{
    const postData = {
      product_id : data.product.id,
      variation_id : data.id,
      total_qty : data.total_qty
    }
    //get perticular recipe details
    const response = await getFlowerRecipeDetails(postData)
    if (response && response.status === 200) {
      setShowModal(true)
      setProductRecipe(response.data.data);
    }else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
  }

  const columns = React.useMemo(
    () => [
      {
        Header: "Product Name",
        accessor: "product.title",
        colWidth: 400,
        Cell: ({ value, row, column }) => {
          return `${row.original.product.title}`;
        },
      },
      {
        Header: "Varialtion",
        accessor: "title",
        colWidth: 200,
        
      },
      {
        Header: "Quantity",
        accessor: "total_qty",
        Cell: ({ value, row, column }) => {
          return `${row.original.total_qty}`;
        },
      },
      {
        Header: "Unit Price",
        accessor: "unit_price",
        Cell: ({ value, row, column }) => {
          return `$${row.original.unit_price}`;
        },
      },
      {
        Header: "Action",
        accessor: "",
        disableSortBy: true,
        Cell: ({ value, row, column }) => {
          return (
            <div className='text-right'>
                <img
                  className={`cursor-pointer text-right view-icon`}
                  src={ViewIcon}
                  alt="View Order"
                  onClick={() => {
                    viewRecipeDetails(row.original);
                  }}
                />
            </div>
          );
        },
      },
      
      
    ],
    []
  );
  return (
    <React.Fragment>
      <Header title="Flower Buyer Report" />

      <div id="MainContent" className="main-content">
        <div style={{ float: "left", width: "100%" }}>
          <div className={"row "}>
          <div className="col-12 d-flex align-items-center">
            <span
              className="mb-0 mt-2 pb-0 d-inline-bock mr-3"
              style={{ lineHeight: 1 }}
            >
              Filter By
            </span>
            <RadioBox
              selectedValue={type}
              onChange={() => {
                if (setType) {
                  setType("orderDate");
                }
              }}
              label="Order date"
              value="orderDate"
            />
            <RadioBox
              selectedValue={type}
              onChange={() => {
                if (setType) {
                  setType("delivery_date");
                }
              }}
              label="Delivery date"
              value="delivery_date"
            />
          </div>
            <div className="col-sm-3 col-xs-12">
              <Date
                label="Filter From "
                value={fromDate}
                dateFormat="ddd MMM DD, YYYY"
                type="date"
                fieldIcon={CalendarIcon}
                onChange={(fromDate) => {
                  setFromDate(moment(fromDate).format("ddd MMM DD, YYYY"));
                  handleFromDate(fromDate);
                }}
              />
            </div>
            <div className="col-sm-3 col-xs-12">
              <Date
                label="Filter To "
                value={toDate}
                dateFormat="ddd MMM DD, YYYY"
                type="date"
                fieldIcon={CalendarIcon}
                onChange={(toDate) => {
                  setToDate(moment(toDate).format("ddd MMM DD, YYYY"));
                  handleToDate(toDate);
                }}
              />
            </div>
            <div className="col-md-2" style={{ paddingLeft: "0px" }}>
              <Select
                label="Delivery Type"
                value={deliveryType}
                onChange={(data) => {
                  setDeliveryType(data);
                }}
                options={[
                  { id: 0, title: "All" },
                  { id: 1, title: "Pickup/Will-call" },
                  { id: 2, title: "Delivery Service" },
                ]}
                disabled={false}
              />
           </div>
           {/* <div className="col-sm-2 col-xs-12">
              <CheckBox
              value={checkAllStore}
              label="All Stores"
              onChange={(data)=>{setCheckAllStore(data)}}
              />
            </div> */}
            <div className="col-sm-2 col-xs-12">
              <div className="td-actions pt-4">
                <span
                  onClick={(e) => fetchData(defaultFilter)}
                  className={`link cursor-pointer ${styles.searchIconWrapper}`}
                >
                  <img className={styles.searchIcon} src={SearchIcon} alt="" />
                </span>
                <span
                  onClick={(e) => handleClearSearch(e)}
                  className={`link cursor-pointer ml-3 ${styles.clearIconWrapper}`}
                >
                  <img className={styles.clearIcon} src={CloseIcon} alt="" />
                </span>
              </div>
            </div>
          </div>
          <h3 style={{color:"#7A7A7A"}}>Local Orders : { TotalLocalOrder}</h3>
          <h3 className='bold'>Products with Recipe</h3>
          <TableContainer
            columns={columns}
            data={data}
            noDataText={'There is no data to display'}
            loading={loading}
            disableLastColumnFilter={true}
          />
          <NonRecipeOrderProducts data={nonRecipeOrderProducts} loading={loading}/>
          <RecipeIngredients data={recipeIngredients} loading={loading}/>

          <ProductRecipeDetailsModel
            showModal={showModal}
            handleCloseModal={(event) => {
              event.preventDefault();
              setShowModal(false);
            }}
            productRecipe={productRecipe}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default FlowerBuyerReport