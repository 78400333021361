import React, { useContext, useState } from "react";
import styles from "./index.module.scss";
import Text from "../FormControl/Text/TextInput";
import AddIcon from "../../Assets/images/add.svg";
import Arrow from "../../Assets/images/arrow.svg";
import TextArea from "../FormControl/TextArea/TextArea";
import Products from "../Products/Products";

import { FeaturedCategoryForm } from "../../Hooks/featuredCategoryFormFormik";

// Context
import { AppContext } from "./../../Context/AppContext";

const FeaturedCategoryFrom = (props) => {
  const { showToastMessage } = useContext(AppContext);

  const categoryData = props.categoryData ? props.categoryData : {};
  const featuredCategoryInitalValues = {
    id: categoryData.id ? categoryData.id : 0,
    parent_id: categoryData.parent_id ? categoryData.parent_id : 0,
    module: "home-page",
    name: categoryData.name ? categoryData.name : "",
    sequence_number: categoryData.sequence_number
      ? categoryData.sequence_number
      : 1,
    button_label: categoryData.button_label ? categoryData.button_label : "",
    button_url: categoryData.button_url ? categoryData.button_url : "",
    description: categoryData.description ? categoryData.description : "",
    children: categoryData.children ? categoryData.children : [],
    products: categoryData.products ? categoryData.products : [],
  };

  const [showFeaturedCategory, setShowFeaturedCategory] = useState(true);

  const addFeaturedCategory = () => {
    openAddProductDrawer(true);
  };

  const [addProduct, openAddProductDrawer] = useState(false);
  const handleCloseProduct = () => {
    openAddProductDrawer(false);
  };

  const submit = (values) => {};
  const cbSubmit = (values) => {
    let payloadData = {
      values,
      index: props.index,
    };
    props.setFeaturedCategoryData(payloadData);
  };

  const { formik: featuredCategoryFormik } = FeaturedCategoryForm(
    submit,
    featuredCategoryInitalValues,
    cbSubmit,
    props.checkFeaturedCategoryData
  );

  return (
    <React.Fragment>
      <form
        onSubmit={(e) => {
          if (!featuredCategoryFormik.isValid) {
            showToastMessage(
              undefined,
              `Somthing missing into featured product`,
              "error",
              false
            );
          }
          featuredCategoryFormik.handleSubmit(e);
        }}
      >
        <div className={`${styles.cardBody}`}>
          <div className={`card-body ${styles.featuredCard}`}>
            <div className={`row`}>
              <div className={`col-md-4 col-xs-12`}>
                <Text
                  type="text"
                  label="Header Text"
                  value={featuredCategoryFormik.values.name}
                  required={true}
                  onChange={(data) =>
                    featuredCategoryFormik.setValues((v) => {
                      return { ...v, name: data };
                    })
                  }
                  onBlur={featuredCategoryFormik.handleBlur}
                  touched={featuredCategoryFormik.touched.name}
                  error={featuredCategoryFormik.errors.name}
                  id="name"
                  disabled={props.isDisableFields}
                />
              </div>
              <div className={`col-md-4 col-xs-12`}>
                <Text
                  type="text"
                  label="Sequence Number"
                  value={featuredCategoryFormik.values.sequence_number}
                  required={true}
                  onChange={(data) => {
                    if (!/^[0-9]*$/.test(data)) {
                      return;
                    }
                    let val = (+data).toString() || "0";
                    featuredCategoryFormik.setValues((v) => {
                      return { ...v, sequence_number: val };
                    });
                  }}
                  onBlur={featuredCategoryFormik.handleBlur}
                  touched={featuredCategoryFormik.touched.sequence_number}
                  error={featuredCategoryFormik.errors.sequence_number}
                  id="sequence_number"
                  disabled={props.index === 0 || props.isDisableFields ? true : false}
                />
              </div>
              <div className={`col-md-3 col-xs-12`}>
                {
                  !props.isDisableFields && (
                    <a
                      href="/"
                      className={`link cursor-pointer ml-2 mt-1 ${styles.searchIconWrapper}`}
                      onClick={(e) => {
                        e.preventDefault();
                        addFeaturedCategory();
                      }}
                    >
                      <img className={styles.searchIcon} src={AddIcon} alt="Add" />
                    </a>
                  )
                }
              </div>
              <div className={`col-md-1 col-xs-12  ${styles.featuredItem}`}>
                <a
                  href="/"
                  className={`link cursor-pointer ml-2 mt-1`}
                  onClick={(e) => {
                    e.preventDefault();
                    setShowFeaturedCategory(!showFeaturedCategory);
                  }}
                >
                  {showFeaturedCategory ? (
                    <img
                      className={styles.toggalIconDown}
                      src={Arrow}
                      alt="Add"
                    />
                  ) : (
                    <img
                      className={styles.toggalIconUp}
                      src={Arrow}
                      alt="Add"
                    />
                  )}
                </a>
              </div>
            </div>
            <Products
              isVarianNeeded={false}
              hideFeaturedProduct={showFeaturedCategory}
              showSequenceNumber={true}
              addProduct={addProduct}
              openAddProductDrawer={openAddProductDrawer}
              handleCloseProduct={handleCloseProduct}
              productList={
                props.categoryData.products ? props.categoryData.products : []
              }
              addProductList={(data) => {
                let payloadData = {
                  values: data,
                  index: props.index,
                };
                // console.log("payloadData ===>", payloadData);
                props.addProductList(payloadData);
              }}
              removeProductList={(data) => {
                let payloadData = {
                  values: data,
                  index: props.index,
                };
                props.removeProductList(payloadData);
              }}
              setProductSequence={(index, data) => {
                let payloadData = {
                  product_index: index,
                  index: props.index,
                  values: data,
                };
                props.setProductSequence(payloadData);
              }}
              isDisableFields={props.isDisableFields}
            />
          </div>
        </div>
        <div className={`row ${styles.rowElement}`}>
          <div className={`col-md-6 col-xs-12`}>
            <Text
              type="tel"
              label="Button Label"
              value={featuredCategoryFormik.values.button_label}
              required={true}
              onChange={(data) =>
                featuredCategoryFormik.setValues((v) => {
                  return { ...v, button_label: data };
                })
              }
              onBlur={featuredCategoryFormik.handleBlur}
              touched={featuredCategoryFormik.touched.button_label}
              error={featuredCategoryFormik.errors.button_label}
              id="button_label"
              disabled={props.isDisableFields}
            />
          </div>
          <div className={`col-md-6 col-xs-12`}>
            <Text
              type="tel"
              label="Button URL"
              value={featuredCategoryFormik.values.button_url}
              required={true}
              onChange={(data) =>
                featuredCategoryFormik.setValues((v) => {
                  return { ...v, button_url: data };
                })
              }
              onBlur={featuredCategoryFormik.handleBlur}
              touched={featuredCategoryFormik.touched.button_url}
              error={featuredCategoryFormik.errors.button_url}
              id="button_url"
              disabled={props.isDisableFields}
            />
          </div>
        </div>
        <div className={`row ${styles.rowElement}`}>
          <div className={`col-md-12 col-xs-12`}>
            <TextArea
              label="Highlighted Text"
              rows={3}
              value={featuredCategoryFormik.values.description}
              required={true}
              onChange={(data) => {
                featuredCategoryFormik.setValues((v) => {
                  return { ...v, description: data };
                });
              }}
              onBlur={featuredCategoryFormik.handleBlur}
              touched={featuredCategoryFormik.touched.description}
              error={featuredCategoryFormik.errors.description}
              id="description"
              disabled={props.isDisableFields}
            />
          </div>
        </div>
        <button
          className="d-none featured-category-submit-button"
          id={`featured-category-submit-button-${props.index}`}
          type="submit"
        ></button>
      </form>
    </React.Fragment>
  );
};
export default FeaturedCategoryFrom;
