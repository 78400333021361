import React from "react";
import InputType from "../FormControl/Text/Text";
import TextArea from "../FormControl/TextArea/TextArea";
import SwitchBox from "../FormControl/SwitchBox/SwitchBox";
import CheckBox from "../FormControl/CheckBox/CheckBox";
import styles from "./index.module.scss";

import { SeoForm } from "../../Hooks/Seo/seoFormFormik";

const Seo = (props) => {
  const seoData = props.data ? props.data : {};

  const seoInitalValues = {
    is_robot: seoData.is_robot ? seoData.is_robot : false,
    is_no_follow: seoData.is_no_follow ? seoData.is_no_follow : false,
    is_no_index: seoData.is_no_index ? seoData.is_no_index : false,
    image_alt_text: seoData.image_alt_text ? seoData.image_alt_text : "",
    page_title: seoData.page_title ? seoData.page_title : "",
    meta_title: seoData.meta_title ? seoData.meta_title : "",
    meta_description: seoData.meta_description ? seoData.meta_description : "",
    canonical_url: seoData.canonical_url ? seoData.canonical_url : "",
  };

  const submit = (values) => {};
  const cbSubmit = (values) => {
    props.setSeoData(values);
  };

  const { formik: seoFormik } = SeoForm(
    submit,
    seoInitalValues,
    cbSubmit,
    props.checkSeoData
  );

  return (
    <React.Fragment>
      <form onSubmit={(e) => seoFormik.handleSubmit(e)} id={`seo-section`}>
        <>
          <div className={`${styles.seo}`}>
            <h3 className={styles.seotitle}>SEO</h3>
            <div className={styles.grid}>
              {/* <label className="bmd-label-floating">Robots</label>
              <SwitchBox
                value={seoFormik.values.is_robot}
                onChange={(data) => {
                  seoFormik.setValues((v) => {
                    return {
                      ...v,
                      is_robot: data,
                    };
                  });
                }}
              /> */}

              <CheckBox
                label="No Follow"
                value={seoFormik.values.is_no_follow}
                onChange={(data) => {
                  console.log(
                    "seoFormik.values.is_no_follow",
                    seoFormik.values.is_no_follow
                  );
                  seoFormik.setValues((v) => {
                    return {
                      ...v,
                      is_no_follow: data,
                    };
                  });
                }}
                disabled={props.isDisableFields}
              />
              <CheckBox
                label="No Index"
                value={seoFormik.values.is_no_index}
                onChange={(data) => {
                  seoFormik.setValues((v) => {
                    return {
                      ...v,
                      is_no_index: data,
                    };
                  });
                }}
                disabled={props.isDisableFields}
              />
            </div>
          </div>

          <InputType
            type="text"
            label="Image Alt Text"
            value={seoFormik.values.image_alt_text}
            required={true}
            onChange={(data) => {
              seoFormik.setValues((v) => {
                return {
                  ...v,
                  image_alt_text: data,
                };
              });
            }}
            onBlur={seoFormik.handleBlur}
            touched={seoFormik.touched.image_alt_text}
            error={seoFormik.errors.image_alt_text}
            id="image_alt_text"
            disabled={props.isDisableFields}
          />
          <InputType
            type="text"
            label="Page Title"
            value={seoFormik.values.page_title}
            required={true}
            onChange={(data) => {
              seoFormik.setValues((v) => {
                return {
                  ...v,
                  page_title: data,
                };
              });
            }}
            onBlur={seoFormik.handleBlur}
            touched={seoFormik.touched.page_title}
            error={seoFormik.errors.page_title}
            id="page_title"
            disabled={props.isDisableFields}
          />
          <InputType
            type="text"
            label="Meta Title"
            value={seoFormik.values.meta_title}
            required={true}
            onChange={(data) => {
              seoFormik.setValues((v) => {
                return {
                  ...v,
                  meta_title: data,
                };
              });
            }}
            onBlur={seoFormik.handleBlur}
            touched={seoFormik.touched.meta_title}
            error={seoFormik.errors.meta_title}
            id="meta_title"
            disabled={props.isDisableFields}
          />
          <TextArea
            label="Meta Description"
            rows={3}
            value={seoFormik.values.meta_description}
            required={true}
            onChange={(data) => {
              seoFormik.setValues((v) => {
                return {
                  ...v,
                  meta_description: data,
                };
              });
            }}
            onBlur={seoFormik.handleBlur}
            touched={seoFormik.touched.meta_description}
            error={seoFormik.errors.meta_description}
            id="meta_description"
            disabled={props.isDisableFields}
          />
          {/* <InputType
            type="text"
            label="Canonical Url"
            value={seoFormik.values.canonical_url}
            required={true}
            onChange={(data) => {
              seoFormik.setValues((v) => {
                return {
                  ...v,
                  canonical_url: data,
                };
              });
            }}
            onBlur={seoFormik.handleBlur}
            touched={seoFormik.touched.canonical_url}
            error={seoFormik.errors.canonical_url}
            id="canonical_url"
          /> */}
          <button className="d-none seo-submit-button" type="submit"></button>
        </>
      </form>
    </React.Fragment>
  );
};

export default Seo;
