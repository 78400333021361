import { useFormikContext } from "formik";
import { useOrderAttributeProvider } from "../context/order";
import { decrement, deleteProds, deleteProductStoreIds, increment } from "../utility/util";
import { SUBSCRIPTION_ITEM } from "Helpers/Constants";

export function useProductManage() {
  const formik = useFormikContext();
  const { active, module } = useOrderAttributeProvider();

  const deleteProduct = (i, id) => {
    let recipients = [...formik.values.recipients];
    let currentR = recipients[active];
    let deleted_product_ids = currentR.deleted_product_ids;
    if (module === "edit-order") {
      if (currentR.products[i]?.sql_id) {
        deleted_product_ids.push(currentR.products[i]?.sql_id);
      }
    }

    currentR.products = deleteProds(currentR.products, i);

    //Remove store id from product_variation_storeIds if products is deleted
    let product_variation_storeIds = formik.values.cart_products.product_variation_storeIds;
    product_variation_storeIds = deleteProductStoreIds(product_variation_storeIds, i);
   
    formik.setValues((v) => {
      return {
        ...v,
        recipients,
        cart_products:{
          ...v.cart_products,
          product_variation_storeIds
        }
      };
    });
  };


  const editProduct = (data) => {
    let recipients = [...formik.values.recipients];
    let currentR = recipients[active];
    currentR.products[data.index].selectedVariant.price = Number(data.price);
    currentR.products[data.index].selectedVariant.sale_price = Number(data.price);
    currentR.products[data.index].total_price = Number(data.price * currentR.products[data.index].qty);
    
    console.log("recipients",formik.values)

    formik.setValues((v) => ({
      ...v,
      recipients,
    }));

  };



  const increaseProductQty = (i) => {
    let recipients = [...formik.values.recipients];
    recipients[active].products[i] = increment(recipients[active].products[i]);
    formik.setValues((v) => {
      return {
        ...v,
        recipients,
      };
    });
  };

  const decreaseProductQty = (i) => {
    let recipients = [...formik.values.recipients];
    recipients[active].products[i] = decrement(recipients[active].products[i]);
    formik.setValues((v) => {
      return {
        ...v,
        recipients,
      };
    });
  };

  const deleteAddon = (i) => {
    let recipients = [...formik.values.recipients];
    let currentR = recipients[active];
    // currentR.addons = deleteProds(currentR.addons, i);
    let deleted_product_ids = currentR.deleted_product_ids;
    if (module === "edit-order") {
      if (currentR.addons[i]?.sql_id) {
        deleted_product_ids.push(currentR.addons[i]?.sql_id);
      }
    }
    currentR.addons = deleteProds(currentR.addons, i);
    //Remove store id from product_variation_storeIds if addons is deleted
    let product_variation_storeIds = formik.values.cart_products.product_variation_storeIds;
    product_variation_storeIds = deleteProductStoreIds(product_variation_storeIds, i);

    formik.setValues((v) => {
      return {
        ...v,
        recipients,
        cart_products:{
          ...v.cart_products,
          product_variation_storeIds
        }
      };
    });
  };


  const editAddon = (data) => {
    let recipients = [...formik.values.recipients];
    let currentR = recipients[active];
    console.log("currentR",currentR)
    currentR.addons[data.index].selectedVariant.price = Number(data.price);
    currentR.addons[data.index].selectedVariant.sale_price = Number(data.price);
    currentR.addons[data.index].total_price = Number(data.price * currentR.addons[data.index].qty);

    console.log("recipients",recipients)
    formik.setValues((v) => ({
      ...v,
      recipients,
    }));
  };



  const increaseAddonQty = (i) => {
    let recipients = [...formik.values.recipients];
    recipients[active].addons[i] = increment(recipients[active].addons[i]);
    formik.setValues((v) => {
      return {
        ...v,
        recipients,
      };
    });
  };

  const decreaseAddonQty = (i) => {
    let recipients = [...formik.values.recipients];
    recipients[active].addons[i] = decrement(recipients[active].addons[i]);
    formik.setValues((v) => {
      return {
        ...v,
        recipients,
      };
    });
  };


  const deleteAllProduct = () => {

    let recipients = [...formik.values.recipients];

    recipients.forEach((rec) => {
      let currentR = rec;
      let deleted_product_ids = currentR.deleted_product_ids;
      if (module === "edit-order") {

        currentR.products?.forEach((prod) => {
          if (prod?.sql_id) {
            deleted_product_ids.push(prod?.sql_id);
          }
        });

      }
    })


  };

  /**
   * Update the product details in formik values based on store product and latest product data 
   * @param {object} product - product object
   * @param {object} values - formik values
   * @param {string} module - module name
   * @param {boolean} isLocal - to check if local order or not
   * @returns {object} - updated formik values
   * @returns {object} - updated product_variation_storeIds
   */
  const updateCartProductDetails = (products, values, module, isLocal) => {
    //delivery address is local or not
    const isLocalZipcode = Boolean(values.delivery_information?.is_local);

    let product_variation_storeIds = [];
    let recipients = values.recipients?.map((recipient, recIndex) => {
      //Update product details as per new address and store id
      //Filtered the products if data not found in the products list and available in the recipient products formik context
      //Filter the products based on slug and sku if product is subscription item
      const recipientProduct = recipient.products?.filter((p) => 
        products?.find((prod) => prod?.id === p?.id && 
        prod?.product_variations.find((v) => v?.id === p?.selectedVariant?.id))
      );

      const prods = recipientProduct?.map((rcProd, prodIndex) => {
        let prod = products?.find(
          (p) =>
            p.id === rcProd.id &&
            p.product_variations.find((v) => v.id === rcProd.selectedVariant.id)
        );

        //find the selected variant from the product variations as per the selected variant id 
        //Or if subscription item then find the selected variant based on sku
        let selectedVariant = prod?.product_variations?.find(
          (v) => rcProd.selectedVariant.id === v.id
        );

        selectedVariant.price = selectedVariant?.sale_price;

        return {
          ...prod,
          sql_id: rcProd.sql_id,
          qty: rcProd.qty,
          selectedVariant,
          total_price: +selectedVariant.sale_price * rcProd.qty,
          custom_product_flag: rcProd.custom_product_flag,
          product_key:
            rcProd.product_key ||
            `prod_${rcProd.id}_${
              rcProd.selectedVariant.id
            }_${new Date().getTime()}`,
        };
      });
      //Update addons details as per new address and store id
      //Filtered the Addons if data not found in the Addons list and available in the recipient Addons formik context
      const recipientAddons =recipient?.addons?.filter((p) => 
        products?.find((prod) => prod?.id === p?.id && 
        prod?.product_variations?.find((v) => v?.id === p?.selectedVariant?.id))
      );
      
      const addons = recipientAddons?.map((rcAddon, prodIndex) => {
        let addon = products?.find((p) => p.id === rcAddon.id);
        let selectedVariant = addon?.product_variations?.find(
          (v) => v.id === rcAddon.selectedVariant.id
        );
        selectedVariant.price = selectedVariant?.sale_price;
        
        return {
          ...addon,
          sql_id: rcAddon.sql_id,
          qty: rcAddon.qty,
          selectedVariant,
          total_price: +selectedVariant.sale_price * rcAddon.qty,
          custom_product_flag: rcAddon.custom_product_flag,
          product_key:
            rcAddon.product_key ||
            `prod_${rcAddon.id}_${
              rcAddon.selectedVariant.id
            }_${new Date().getTime()}`,
        };
      });

      //if new order,duplicate and replace order then filter products and addons based on status of product and selected variant
      //if module is not edit-order or is local order
      if(module !== 'edit-order' || isLocal) {
        const customProduct = prods.filter((p) => p.slug === 'custom-product');
        let filteredProds = prods.filter((p) => p.status === 1 && p.selectedVariant.status === 1 && p.slug !== 'custom-product');
        const filteredAddons = addons.filter((p) => p.status === 1 && p.selectedVariant.status === 1);
        filteredProds  = filteredProds.concat(customProduct)
        const removedProducts = recipient.products.filter((p) => !filteredProds.some((fp) => fp.id === p.id))?.map((p) => p.sql_id);
        const removedAddons = recipient.addons.filter((p) => !filteredAddons.some((fp) => fp.id === p.id))?.map((p) => p.sql_id);
        let deleted_product_ids = recipient.deleted_product_ids;
        deleted_product_ids = deleted_product_ids.concat(removedProducts, removedAddons);
        deleted_product_ids = deleted_product_ids.filter((value, index, self) => self.indexOf(value) === index);
        const store_product_variations = filteredProds.map((p) => p.selectedVariant?.store_product_variations[0]?.store_id);
        const store_product_variations_addons = filteredAddons.map((p) => p.selectedVariant?.store_product_variations[0]?.store_id);
        product_variation_storeIds = product_variation_storeIds.concat(store_product_variations, store_product_variations_addons);
        return {
          ...recipient,
          products: filteredProds,
          addons: filteredAddons,
          deleted_product_ids: module === 'edit-order' ? deleted_product_ids : [],
        };
      }else {
        //In case of edit order Do not remove inactive/deleted products and addons from the order
        const store_product_variations = prods.map((p) => p.selectedVariant?.store_product_variations[0]?.store_id);
        const store_product_variations_addons = addons.map((p) => p.selectedVariant?.store_product_variations[0]?.store_id);
        product_variation_storeIds = product_variation_storeIds.concat(store_product_variations, store_product_variations_addons);

        return {
          ...recipient,
          products: prods,
          addons: addons,
        };
      }
    });
    return {
      recipients,
      product_variation_storeIds,
      is_non_local_only: !isLocalZipcode ? 1 : 0,
    };
  };

  const validateLocalNonLocalProducts = (products) => {
    const isNonLocalOnly = products.some((p) => p.is_non_local_only);
    const isLocalOnly = products.some((p) => !p.is_non_local_only);
    const cart_products = formik.values.cart_products;

    if(isNonLocalOnly && !isLocalOnly && cart_products.is_non_local_only === 1){
      return 1;
    }else {
      return 0;
    }
  }

  return {
    updateCartProductDetails,
    validateLocalNonLocalProducts,
    deleteProduct,
    increaseProductQty,
    decreaseProductQty,
    decreaseAddonQty,
    increaseAddonQty,
    deleteAddon,
    deleteAllProduct,
    editProduct,
    editAddon
  };
}
