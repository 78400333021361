import { makeStyles, Tab, Tabs } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { ReactComponent as ReactLogo } from "Assets/images/add-recipient.svg";
import { useFormikContext } from "formik";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { newRecipient, useOrderAttributeProvider } from "../context/order";
import Recipent from "./recipent";
import { DEFAULT_STORE_ID, ORDER_SOURCE, PAYMENT_METHOD_DETAIL, PAYMENT_METHODS } from "Helpers/Constants";
import Confirmation from "Components/Modal/Confirmation/Confirmation";
import { useProductManage } from "../hooks/useProductMange";
import { getValidatedProduct } from "api/product";
import { AppContext } from "Context/AppContext";
import { useDeliveryAddress } from "Context/DeliveryAddress/usedeliveryAddress";
import { OrderConfigContext } from "Context/OrderConfigContext";
const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    marginTop: "3rem",
  },
  mapWrap: {
    border: "1px solid #ccc",
    padding: "8px",
    iframe: {
      display: "block",
    },
  },
});

const Recipents = () => {
  const classes = useStyles();
  const formik = useFormikContext();

  const { active, setActive, module } = useOrderAttributeProvider();
  const { updateCartProductDetails } = useProductManage();
  const [showModel, setShowModel] = useState(false);
  const [isLocal, setIsLocal] = useState(false);
  const { showToastMessage, setIsLoading } = useContext(AppContext);
  const { setDeliveryAddress,deliveryAddressState } = useDeliveryAddress();
  const { storeList } = useContext(OrderConfigContext);

  const handleChange = (event, newValue) => {
    setActive(newValue);
  };
  const handleClose = useCallback(
    (event, index) => {
      
      // stop event from propagating to the target element's parent
      event.stopPropagation();
      let re = [...formik.values.recipients];
      
      if (re.length === 1) return;
      let deleted_recipient_ids = [...formik.values.deleted_recipient_ids];

      if (module === "edit-order") {
        if (re[index]?.sql_id) {
          deleted_recipient_ids.push(re[index].sql_id);
        }
      }
      re.splice(index, 1);
      console.log(re);
      setActive(0);
      formik.setValues((v) => {
        return {
          ...v,
          recipients: re,
          deleted_recipient_ids,
        };
      });

      // if (index === 0) {
      //   return;
      // } else {

      // }
    },
    [active, formik.values.recipients]
  );

  /**
 * @param {*} values
 * @param {*} newStoreId
 * @returns {boolean}
 * @description step1 : get all products and addons selected storeId
 * @description step2 : check all products and addons store_id is same or not
 * @description step3 : if all products and addons store_id is same then return false
 * @description step4 : if all products and addons store_id is not same then return true
 * */
 const validateStoreProduct = (values, newStoreId = 0) => {
  const selectedProductsStoreIds = values?.cart_products?.product_variation_storeIds;
    if (!selectedProductsStoreIds || selectedProductsStoreIds.length === 0)
      return false;
      const isStoreIdsNotSame = [...selectedProductsStoreIds]?.some(
        (storeId) => storeId !== newStoreId
      );
      if (isStoreIdsNotSame) {
        return true;
      }
    return false;
  };

  useEffect(() => {
    if(formik.values.billing_info?.zip_code_store_id){
      //Validate store product and addons for same store
      const storeId = formik.values.billing_info.zip_code_store_id;
      const isStoreProductNotSame = validateStoreProduct(formik.values, storeId)
      //is_local = 1 means local order is_local = 0 means non local order
      const isLocalZipcode = formik.values.delivery_information?.is_local;
      //is_non_local_only = 1 means non local product, is_non_local_only = 0 means local product
      const isNonLocalProduct = formik.values.cart_products.is_non_local_only ? 0 : 1;
      //Check if product in cart and non local and address in non local then address changes to local, in this case don't open pop up.
      //Check is store product and addons are same or not
      if(isStoreProductNotSame){
        setShowModel(true);
        setIsLocal(false);
      }else if(storeId === DEFAULT_STORE_ID && 
          formik.values.cart_products.product_variation_storeIds.length > 0 && 
          isLocalZipcode !== isNonLocalProduct && isNonLocalProduct === 1){
        setShowModel(true);
        setIsLocal(true);
      } else{
        setDeliveryAddress(formik.values.delivery_information);
        setShowModel(false);
        setIsLocal(false);
      }
    }
  },[
    formik.values.delivery_information.order_type,
    formik.values.billing_info?.zip_code_store_id,
    formik.values.delivery_information.is_local,
  ]);

  //Call API for get all products and addons based on selected storeId
  const getValidatedProducts = async () => {
    const productIds = formik.values?.recipients?.map((reci) => {
      const products = reci?.products?.map((z=> z.id));
      const addons = reci?.addons?.map((z) => z.id);
      return [...products, ...addons];
    }).flat(1);
     //delivery type then pass zipcode and store id if pickup order
    let extraData = {};
    if(formik.values.delivery_information.order_type === 0){
      extraData['zipcode'] = formik.values.delivery_information.zipcode;
    }else{
      extraData['selectedStore'] = formik.values.billing_info?.zip_code_store_id;
    }
    const prResp = await getValidatedProduct({ 
      ids: productIds || [],
      ...extraData,
    });
    if(!prResp.data){
      return [];
    }
    return prResp.data.data.rows;
  }

  //Handle Cancel Click when CSR click on cancel button then set delivery address as per previous storeId
  //Do not take any action on cart product details
  const handleCancelClick = () => {
    setShowModel(false);
    setIsLocal(false);
    const storeDetails = storeList.find((store) => store.id === deliveryAddressState.storeId);
    formik.setValues((v) => {
      return {
        ...v,
        delivery_information:deliveryAddressState,
        billing_info: {
          ...v.billing_info,
          zip_code_store_id: deliveryAddressState.storeId,
          payment_method: PAYMENT_METHOD_DETAIL[deliveryAddressState.storeId]
        },
        payabli_paypoint: storeDetails?.payabli_paypoint || "",
      };
    });
  };
  
  //Handle Continue Click when CSR click on continue button then call new API for get all products and addons based on selected storeId
  //update cart product details and set delivery address as per API response
  const handleContinueClick = async () => {
    setShowModel(false);
    setIsLoading(true);
    const validatedProducts = await getValidatedProducts();
    const cartDetails = updateCartProductDetails(validatedProducts, formik.values, module, isLocal);
    const storeDetails = storeList.find((store) => store.id === formik.values.billing_info.zip_code_store_id);
    formik.setValues((v) => {
      return {
        ...v,
        delivery_information: {
          ...v.delivery_information,
          storeId: formik.values.billing_info.zip_code_store_id,
        },
        recipients: cartDetails.recipients,
        cart_products:{
          is_non_local_only: cartDetails.is_non_local_only,
          product_variation_storeIds: cartDetails.product_variation_storeIds,
        },
        payabli_paypoint: storeDetails?.payabli_paypoint || "",
      };
    });
    setDeliveryAddress({
      ...formik.values.delivery_information,
      storeId: formik.values.billing_info.zip_code_store_id,
    });
    setIsLocal(false);
    setIsLoading(false);
  };

  //Reset zip_code_store_id to null if order_type delivery and zipcode length less than 5
  useEffect(()=>{
    const zipcode = formik.values.delivery_information.zipcode
    const orderType = formik.values.delivery_information.order_type;
    if(orderType === 0 && zipcode.length < 5){
      formik.setValues((v) => {
        return {
          ...v,
          billing_info: {
            ...v.billing_info,
            zip_code_store_id: null,
            payment_method: null,
          },
        };
      });
    }
  },[
    formik.values.delivery_information.zipcode,
    formik.values.delivery_information.order_type
  ])

  // we are Manage Order Transection data and filter 0 order id transection
  const order_payment_transactions_data =  formik.values?.order_payment_transactions?.filter((val) => val.order_id === 0) || []
  const payment_method = order_payment_transactions_data?.length > 0 && order_payment_transactions_data[0]?.payment_method
  const isDisebled = formik.values.source === ORDER_SOURCE.WALKIN.toLowerCase() && payment_method === PAYMENT_METHODS.PAYABLI ? true : false
  return (
    <div className={classes.root}>
      <div className="d-flex align-items-center order-container">
        <Confirmation
          btn1_title={"Continue"}
          btn2_title={"Cancel"}
          handleCancelClick={handleCancelClick}
          showModal={showModel}
          handleSubmitClick={handleContinueClick}
          message={"Please be aware that some products may not be available in the new store's catalog, and pricing may differ accordingly. Would you like to proceed?"}
          title={"Confirmation"}
          key={"confirmation"}
        />
        <div className="d-flex align-items-center mr-5">
          <h3 className="bold my-0 mr-2">Recipient Information</h3>
           {/* we are manage fields using Order payment_method and order Type */}
          {isDisebled ? null : (!formik.values.is_subscribe_order || module != "new-order" ) && (formik.values.is_burq_order !== 1 &&
            <ReactLogo
              style={{ cursor: "pointer" }}
              width="30px"
              height="30px"
              onClick={() => {
                let re = [...formik.values.recipients];
                re.push({
                  recipient_id: 0,
                  sql_id: 0,
                  products: [],
                  addons: [],
                  first_name: "",
                  last_name: "",
                  video_url: "",
                  video_message: "",
                  video_message_pass_code: "",
                  video_message_qr_code: "",
                  whats_the_occasion: "",
                  whats_the_occasion_id: null,
                  greetings_on_card: "",
                  card_signature: "",
                  deleted_product_ids: [],
                });

                formik.setValues((v) => {
                  return {
                    ...v,
                    recipients: re,
                  };
                });
              }}
            />
          )}
          
        </div>
        <div style={{ flex: "1 1 0px" }}>
          <Tabs
            style={{ borderBottom: "1px solid #f2f3f5" }}
            value={active}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
          >
            {formik.values.recipients.map((c, i) => (
              <Tab
                key={i}
                //  we are manage fields using Order payment_method and order Type 
                disabled={isDisebled || formik.values.is_burq_order === 1}
                label={
                  <span className="border-1 d-flex justify-content-between align-items-center w-100">
                    {c.first_name?.trim().length !== 0
                      ? c.first_name + " " + c.last_name
                      : `Recipient ${i + 1}`}

                    <Close
                      onClick={(event) => handleClose(event, i)}
                      style={{
                        width: 25,
                        height: 15,
                        marginLeft: "2rem",
                        border: "none",
                        boxShadow: "none",
                      }}
                    />
                  </span>
                }
              ></Tab>
            ))}
          </Tabs>
        </div>
      </div>
      <div className="row">
        <div className="col-md-8 my-3">
          {formik.values.recipients.map((r, i) => (
            <Recipent
              key={i}
              className={active === i ? "" : "d-none"}
              index={i}
              {...r}
            />
          ))}
        </div>
        <div className="col-md-4 my-3">
          <div className={classes.mapWrap}>
            <iframe
              width="100%"
              height="366"
              frameBorder="0"
              title="AddressMaps"
              scrolling="no"
              src={`https://maps.google.com/maps?q=Los%20Angeles+(My%20Business%20Name)&output=embed`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Recipents;
