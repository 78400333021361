import { DEFAULT_COUNTRY_CODE } from "Helpers/Constants";

export const deliveryAddressInitalState = {
  not_deliverable_products: [],
  address_type_id: null,
  address_type: "",
  address_line_1: "",
  address_line_2: "",
  frequency: null,
  phone: "",
  alt_phone: "",
  city: "",
  state: "",
  attn: "",
  zipcode: "",
  country: "usa",
  is_local: 0,
  storeId: 0,
  delivery_date: null,
  delivery_time_id: 0,
  delivery_time: "",
  delivery_date_time: "",
  delivery_time_type: "",
  delivery_type: "Delivery Service",
  actual_delivered_time: "",
  charge: 0,
  upcharge_id: 0,
  upcharge_amount: 0,
  driver_instruction: "",
  designer_instruction: "",
  delivery_type_id: 2,
  order_type: 0,
  shop_code: "",
  shop_name: "",
  person_name: "",
  external_order_number: "",
  phone_number: "",
  phone_country_code: DEFAULT_COUNTRY_CODE,
  alt_phone_country_code: DEFAULT_COUNTRY_CODE,
};
