import { useEffect, useState } from "react";

const CustomZendeskButton = ({ user }) => {
  const [isZendeskOpen, setIsZendeskOpen] = useState(false);

  useEffect(() => {
    // Load Zendesk script dynamically
    const script = document.createElement("script");
    script.id = "ze-snippet";
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${process.env.REACT_APP_ZENDESK_KEY}`;
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      if (window.zE) {
        // Hide default Zendesk button
        window.zE("webWidget", "hide");

        // Listen for widget events
        window.zE("webWidget:on", "open", () => setIsZendeskOpen(true));
        window.zE("webWidget:on", "close", () => setIsZendeskOpen(false));

        // Prefill user details
        if (user.userProfile) {
          window.zE("webWidget", "prefill", {
            name: { value: user.userProfile.full_name, readOnly: true },
            email: { value: user.userProfile.email, readOnly: true },
          });

          // Set user identity
          window.zE("webWidget", "identify", {
            name: user.userProfile.full_name,
            email: user.userProfile.email,
          });
        }
      }
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (window.zE) {
      if (isZendeskOpen) {
        window.zE("webWidget", "show");
        window.zE("webWidget", "open");
      } else {
        window.zE("webWidget", "hide");
      }
    }
  }, [isZendeskOpen]);

  // Function to open Zendesk ticket form
  const openZendeskTicket = () => {
    if (window.zE) {
      setIsZendeskOpen(true);
      window.zE("webWidget", "show");
      window.zE("webWidget", "open");
      window.zE("webWidget", "prefill", {
        name: { value: user.userProfile.full_name, readOnly: true },
        email: { value: user.userProfile.email, readOnly: true },
      });
    }
  };

  return (
    <>
      {!isZendeskOpen && (
        <button
          onClick={openZendeskTicket}
          style={{
            position: "fixed",
            bottom: "20px",
            left: "20px",
            background: "#cb998d",
            color: "white",
            border: "none",
            padding: "10px 20px",
            borderRadius: "20px",
            fontSize: "16px",
            cursor: "pointer",
            zIndex: "10000",
            fontWeight: "bold",
          }}
        >
          Need help? Click here
        </button>
      )}
    </>
  );
};

export default CustomZendeskButton;
