import { Button, TextField } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import React, { useContext, useEffect, useState } from "react";
import RadioBox from "Components/FormControl/RadioBox/RadioBox";
import TextArea from "Components/FormControl/TextArea/TextArea";
// SCSS
import styles from "./cancelNote.module.scss";
import { useFormikContext } from "formik";
import { AppContext } from "Context/AppContext";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const RefundPriceModel = ({
  showModal,
  pageTitle,
  handleCancelClick,
  handleSubmitClick,
  placeholder,
  totalRefundAmount = 0,
  summary,
  setModal,
}) => {
  const [refundAmount, setRefundAmount] = useState(0);

  useEffect(() => {
    if (pageTitle === 'Refund') {
      if (summary?.refund) {
        setRefundAmount(summary?.refund);
      }
    } else {
      if (totalRefundAmount) {
        setRefundAmount(totalRefundAmount);
      }
    }

  }, [summary?.refund, totalRefundAmount.pageTitle]);

  const submit = (e) => {
    // set default refund flag is 1 as partial refund
    let refundOptions = 1;
    let total_amount = +parseFloat(totalRefundAmount).toFixed(2); // total_fund_amount - total_refund_amount
    let refund_Amount = +parseFloat(summary?.refund).toFixed(2); // refund amount
    // check total amount and refund amount if both are same then set refund flag is 2 as full refund
    if (total_amount > 0 && refund_Amount > 0 && total_amount === refund_Amount) {
      refundOptions = 2;
    }
    console.log("RefundPriceModel ==>", total_amount, refund_Amount, refundOptions, typeof total_amount, typeof refundOptions);
    handleSubmitClick(refundOptions, refundAmount);
  };


  return (
    <React.Fragment>
      <Dialog
        open={showModal}
        TransitionComponent={Transition}
        onClose={() => { handleCancelClick() }}
        classes={{
          paper: styles.modalDialog,
        }}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogContent className={`${styles.modalContent} px-0 py-0`}>
          <div className={`text-center ${styles.modalBody}`}>
            <h5 className={`my-3 text-center ${styles.modalTitle}`}>
              {pageTitle}
            </h5>
            <div className="row">
              <div className={`col-md-4 col-xs-12 mt-5 ${styles.marginAuto}`}>
                <TextField
                  type="text"
                  disabled={true}
                  label={pageTitle}
                  value={refundAmount}
                  required={true}
                  onChange={(e) => { }}
                />
              </div>
            </div>

            <span
              data-dismiss="modal"
              onClick={() => { submit() }}
              className="btn gray-btn d-inline-block mt-5 mb-5 mr-2 mt-5 cursor-pointer"
            >
              Save
            </span>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default RefundPriceModel;
