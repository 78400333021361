import { useContext } from "react";
import { ProductContext } from "./productContext";
import { ProductActions } from "./productAction";

export function useProduct() {
  const { productState, dispatch } = useContext(ProductContext);
  const setActiveIndexVariant = (index) => {
    dispatch({ type: ProductActions.SET_ACTIVE_INDEX, payload: index });
  };
  const setProductPrimaryData = (values) => {
    dispatch({ type: ProductActions.SET_PRIMARY_INFO, payload: values });
  };
  const checkProductPrimaryData = (values) => {
    dispatch({ type: ProductActions.CHECK_PRIMARY_INFO, payload: values });
  };
  const setProductNavigationData = (values) => {
    dispatch({ type: ProductActions.SET_NAVIGATION_INFO, payload: values });
  };
  const checkProductNavigationData = (values) => {
    dispatch({ type: ProductActions.CHECK_NAVIGATION_INFO, payload: values });
  };
  const setProductVariantData = (values) => {
    dispatch({ type: ProductActions.SET_VARIANT_INFO, payload: values });
  };
  const checkProductVariantData = (values) => {
    dispatch({ type: ProductActions.CHECK_VARIANT_INFO, payload: values });
  };
  const deleteDataVariant = (values) => {
    dispatch({ type: ProductActions.DELETE_PRODUCT_VARIANT, payload: values });
  };
  const setProductFlagData = (values) => {
    dispatch({ type: ProductActions.SET_PRODUCT_FLAG_INFO, payload: values });
  };
  const setIsLocal = (values) => {
    dispatch({ type: ProductActions.SET_IS_LOCAL_VALUE, payload: values });
  };
  const setIsNonLocal = (values) => {
    dispatch({ type: ProductActions.SET_IS_NON_LOCAL_VALUE, payload: values });
  };
  const setSeoData = (values) => {
    dispatch({ type: ProductActions.SET_SEO_DATA, payload: values });
  };
  const setProductNoteData = (values) => {
    dispatch({ type: ProductActions.SET_PRODUCT_NOTE_INFO, payload: values });
  };
  const deleteDataNote = (values) => {
    dispatch({ type: ProductActions.DELETE_PRODUCT_NOTE, payload: values });
  };
  const setActiveIndexNote = (index) => {
    dispatch({ type: ProductActions.SET_ACTIVE_INDEX_NOTE, payload: index });
  };
  const deleteNotesData = (values) => {
    dispatch({ type: ProductActions.DELETE_PRODUCT_NOTE, payload: values });
  };
  const clearProductState = () => {
    dispatch({ type: ProductActions.CLR_DATA_PRODUCT });
  };
  const setProductAllData = (index) => {
    dispatch({ type: ProductActions.SET_ALL_PRODUCT_DATA, payload: index });
  };
  const checkSeoData = (payload) => {
    dispatch({ type: ProductActions.CHECK_SEO_DATA, payload });
  };
  const setOgInfoData = (values) => {
    dispatch({ type: ProductActions.UPDATE_OG_INFO_DATA, payload: values });
  };
  const checkOgInfoData = (payload) => {
    dispatch({ type: ProductActions.CHECK_OG_INFO_DATA, payload });
  };
  const setTwitterInfoData = (values) => {
    dispatch({
      type: ProductActions.UPDATE_TWITTER_INFO_DATA,
      payload: values,
    });
  };
  const checkTwitterInfoData = (payload) => {
    dispatch({ type: ProductActions.CHECK_TWITTER_INFO_DATA, payload });
  };
  const setSchemaData = (values) => {
    dispatch({ type: ProductActions.UPDATE_SCHEMA_DATA, payload: values });
  };
  const checkSchemaData = (payload) => {
    dispatch({ type: ProductActions.CHECK_SCHEMA_DATA, payload });
  };
  const setSimilarProductData = (values) => {
    dispatch({ type: ProductActions.SET_SIMILAR_PRODUCT, payload: values });
  };
  const setActiveIndexSimilarProduct = (index) => {
    dispatch({
      type: ProductActions.SET_SIMILAR_PRODUCT_INDEX,
      payload: index,
    });
  };
  const deleteSimilarProduct = (values) => {
    dispatch({ type: ProductActions.DELETE_SIMILAR_PRODUCT, payload: values });
  };
  const setProductImageData = (values) => {
    dispatch({ type: ProductActions.SET_PRODUCT_IMAGE_DATA, payload: values });
  };
  const updateProductImageData = (values) => {
    dispatch({ type: ProductActions.UPDATE_PRODUCT_IMAGE_DATA, payload: values });
  };
  const setEditVariantProductIndex = (values) => {
    dispatch({
      type: ProductActions.SET_EDIT_VARIANT_PRODUCT_INDEX,
      payload: values,
    });
  };
  const setUserData = (values) => {
    dispatch({ type: ProductActions.SET_USER_DATA_INFO, payload: values });
  };
  const setPageForView = (values) => {
    dispatch({ type: ProductActions.SET_PAGE_FOR_VIEW, payload: values });
  };
  const setCategoryHierarchyData = (values) => {
    dispatch({
      type: ProductActions.SET_CATEGORY_HIERARCHY_DATA,
      payload: values,
    });
  };
  const deleteCategoryHierarchyData = (values) => {
    dispatch({
      type: ProductActions.DELETE_CATEGORY_HIERARCHY_DATA,
      payload: values,
    });
  };
  const deleteProductImageData = (values) => {
    dispatch({
      type: ProductActions.DELETE_PRODUCT_IMAGE_DATA,
      payload: values,
    });
  };
  const setDeleteProductImageData = (values) => {
    dispatch({
      type: ProductActions.SET_DELETE_PRODUCT_IMAGE_DATA,
      payload: values,
    });
  };
  const addNewBanner = (values) => {
    dispatch({ type: ProductActions.ADD_NEW_BANNER });
  };
  const removeBanner = (index) => {
    dispatch({ type: ProductActions.REMOVE_BANNER, payload: index });
  };
  const setBannerData = (values) => {
    dispatch({ type: ProductActions.UPDATE_BANNER_DATA, payload: values });
  };
  const checkBannerData = (payload) => {
    dispatch({ type: ProductActions.CHECK_BANNER_DATA, payload });
  };
  const setProductBannerData = (payload) => {
    dispatch({ type: ProductActions.SET_PRODUCT_BANNER_DATA, payload });
  };
  const addSimilarProductData = (payload) => {
    dispatch({
      type: ProductActions.ADD_SIMILAR_PRODUCT_DATA,
      payload,
    });
  };
  const deleteSimilarProductData = (payload) => {
    dispatch({
      type: ProductActions.DELETE_SIMILAR_PRODUCT_DATA,
      payload,
    });
  };
  const checkProductNoteData = (values) => {
    dispatch({ type: ProductActions.CHECK_PRODUCT_NOTE_DATA, payload: values });
  };
  const setSimilarProductArray = (payload) => {
    dispatch({
      type: ProductActions.SET_SIMILAR_PRODUCTPRODUCT_ARRAY,
      payload,
    });
  };
  const clearProductVariationData = (payload) => {
    dispatch({ type: ProductActions.CLR_PRODUCT_VARIANT_DATA });
  };
  return {
    productState,
    setSimilarProductArray,
    setActiveIndexVariant,
    setProductPrimaryData,
    checkProductPrimaryData,
    setProductNavigationData,
    checkProductNavigationData,
    setProductVariantData,
    checkProductVariantData,
    deleteDataVariant,
    setProductFlagData,
    setIsLocal,
    setIsNonLocal,
    setSeoData,
    setProductNoteData,
    deleteDataNote,
    setActiveIndexNote,
    deleteNotesData,
    clearProductState,
    setProductAllData,
    checkSeoData,
    setOgInfoData,
    checkOgInfoData,
    setTwitterInfoData,
    checkTwitterInfoData,
    setSchemaData,
    checkSchemaData,
    setSimilarProductData,
    setActiveIndexSimilarProduct,
    deleteSimilarProduct,
    setProductImageData,
    setEditVariantProductIndex,
    setUserData,
    setPageForView,
    setCategoryHierarchyData,
    deleteCategoryHierarchyData,
    deleteProductImageData,
    setDeleteProductImageData,
    addNewBanner,
    removeBanner,
    setBannerData,
    checkBannerData,
    setProductBannerData,
    addSimilarProductData,
    deleteSimilarProductData,
    checkProductNoteData,
    clearProductVariationData,
    updateProductImageData
  };
}
