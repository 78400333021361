import React from "react";
import Text from "../FormControl/Text/TextInput";
import styles from "./index.module.scss";

import { ProductNavigationInfoForm } from "../../Hooks/product/navigationFormFromik";

import CardContent from "@material-ui/core/CardContent";
import TextArea from "../../Components/FormControl/TextArea/TextArea";

const Navigation = (props) => {
  let productNavigationInfoValue = {
    slug: props.slugData ?? "",
    blurb: props.blurbData ?? "",
  };
  const submit = (values) => {};
  const cbSubmit = (values) => {
    props.setNavigationData(values);
  };

  const { formik: ProductNavigationInfoFormik } = ProductNavigationInfoForm(
    submit,
    productNavigationInfoValue,
    cbSubmit,
    props.checkNavigationData
  );

  return (
    <div className={`mt-4 mb-2 ${styles.productCard}`} id="navigation">
      <CardContent>
        <div className={`${styles.productsWrapper}`}>
          <div className={`mt-2 mb-4 ${styles.title}`}>Navigation</div>
          <form
            onSubmit={(e) => {
              ProductNavigationInfoFormik.handleSubmit(e);
            }}
            className={`row`}
          >
            <div className={`col-md-12`}>
              <Text
                type="text"
                label="Slug"
                value={ProductNavigationInfoFormik.values.slug}
                required={true}
                onChange={(data) =>
                  ProductNavigationInfoFormik.setValues((v) => {
                    return { ...v, slug: data };
                  })
                }
                // disabled={false}
                disabled={
                  ProductNavigationInfoFormik.values.slug !==
                    "subscription-category" &&
                  ProductNavigationInfoFormik.values.slug !==
                    "custom-category" &&
                  ProductNavigationInfoFormik.values.slug !==
                    "custom-product" &&
                  ProductNavigationInfoFormik.values.slug !==
                    "subscription-item" &&
                  !props.isDisableFields
                    ? false
                    : true
                }
                error={ProductNavigationInfoFormik.errors.slug}
                touched={ProductNavigationInfoFormik.touched.slug}
                id="slug"
              />
            </div>
            <div className={`col-md-12`}>
              <TextArea
                label="Blurb"
                value={ProductNavigationInfoFormik.values.blurb}
                required={true}
                onChange={(data) =>
                  ProductNavigationInfoFormik.setValues((v) => {
                    return { ...v, blurb: data };
                  })
                }
                disabled={props.isDisableFields}
                error={ProductNavigationInfoFormik.errors.blurb}
                touched={ProductNavigationInfoFormik.touched.blurb}
                id="blurb"
              />
            </div>
            <button
              className="d-none"
              id="navigation_info_submit_button"
              type="submit"
            ></button>
          </form>
        </div>
      </CardContent>
    </div>
  );
};

export default Navigation;
