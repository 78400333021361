import { getAccessByModuleName } from "./../../../Routes/Routes";
import { MODULES } from "./../../../Helpers/Constants";
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";

// Components
import Header from "./../../../Components/Header/Header";
import ReactTable from "./../../../Components/Table/Table";
import ManageRole from "./../../../Components/Modal/User/Roles/Manage";
import DeleteConfirmation from "./../../../Components/Modal/DeleteConfirmation/DeleteConfirmation";

// Services
import RolesApi from "./../../../Services/Roles/Roles";
import { getRoles, deleteRole, getModulesData } from "api/roles";

//scss
import styles from "./../List/index.module.scss";

// Context
import { AppContext } from "./../../../Context/AppContext";

// Images
import EditIcon from "./../../../Assets/images/edit.svg";
import DeleteIcon from "./../../../Assets/images/delete.svg";

let initDeleteValues = {
  open: false,
  id: null,
};

let initManageValues = {
  open: false,
  data: {},
};

const Roles = (props) => {
  let [modules, setModules] = useState([]),
    [deleteModal, setDeleteModal] = useState(initDeleteValues),
    [manageModal, setManageModal] = useState(initManageValues),
    [access, setAccess] = useState({});

  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(1);

  const [defaultFilter, setDefaultFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
    sortBy: [],
    filters: [],
    globalFilter: "",
  });

  const { showToastMessage, setIsLoading, modules: moduleContext } = useContext(
    AppContext
  );
  let rolesAccess = getAccessByModuleName(moduleContext, MODULES.ROLES_RIGHTS);
  const columns = React.useMemo(
    () => [
      {
        Header: "Role",
        accessor: "title",
        isVisible: true,
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: "No. of Users ",
        accessor: "user_count",
        isVisible: true,
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: "Created",
        accessor: "createdAt",
        isVisible: true,
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ value }) => moment(value).utc().format("ddd MMM DD, YYYY"),
      },
      {
        Header: "Updated",
        accessor: "updatedAt",
        isVisible: true,
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ value }) => moment(value).utc().format("ddd MMM DD, YYYY"),
      },
      {
        Header: "Actions",
        accessor: "id",
        isVisible: true,
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ value, row, column }) => {
          return (
            <React.Fragment>
              {rolesAccess && rolesAccess.permissions.write_access && (
                <img
                  onClick={(e) => openManageModal(e, row.original)}
                  className={`${styles.searchIcon} mr-4`}
                  src={EditIcon}
                  alt=""
                />
              )}
              {!row.original.is_default &&
                row.original.user_count === 0 &&
                rolesAccess &&
                rolesAccess.permissions.delete_access && (
                  // !row.original.is_default && row.original.user_count === 0 && access.delete_access &&
                  <img
                    onClick={() => onDeleteOpen(row.original.id)}
                    className={styles.searchIcon}
                    src={DeleteIcon}
                    alt=""
                  />
                )}
            </React.Fragment>
          );
        },
      },
    ],
    []
  ); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchData(defaultFilter);
    getModules();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /* Check Module Access */
  useEffect(getModuleAccess, [moduleContext]);

  const fetchData = async ({
    pageIndex,
    pageSize,
    sortBy,
    globalFilter,
    filters,
  }) => {
    setDefaultFilter({ pageSize, pageIndex, filters, globalFilter, sortBy });
    setLoading(true);
    setIsLoading(true);

    const postData = {
      currentPage: pageIndex,
      itemsPerPage: pageSize,
      globalSearch: globalFilter,
      filters,
      sortBy,
      includePermissions: true,
    };
    // const response = await new RolesApi().getRoles(postData);
    const response = await getRoles(postData);

    if (response && response.status === 200) {
      setData(response.data.data.rows);
      setPageCount(Math.ceil(response.data.data.count / pageSize));
    } else showToastMessage(undefined, response?.data?.message, "error", false);

    setLoading(false);
    setIsLoading(false);
  };

  async function getModules() {
    const postData1 = {
      itemsPerPage: 500,
    };
    // const response = await new RolesApi().getModules({
    //   itemsPerPage: 50,
    // });
    const response = await getModulesData(postData1);

    if (response && response.status === 200) {
      let modules = response?.data?.data?.rows.filter(filter => filter.name !== MODULES.ADMINISTRATIVE_REMINDERS).map((module) => {
        return {
          ...module,
          permissions: {
            module_id: module.id,
            read_access: false,
            write_access: false,
            delete_access: false,
          },
        };
      });
      setModules(modules);
    }
  }

  function getModuleAccess() {
    let access = getAccessByModuleName(moduleContext, MODULES.ROLES_RIGHTS);
    if (access) setAccess(access.permissions);
    if (access && !access.permissions.read_access) {
      showToastMessage(
        undefined,
        "You are not authorized to access.",
        "error",
        false
      );
      props.history.push("/users");
    }
  }

  function openManageModal(event, data = {}) {
    if (event) event.preventDefault();

    setManageModal({ data, open: true });
  }

  function closeManageModel() {
    setManageModal({ ...initManageValues });
  }

  function onManageSubmit() {
    closeManageModel();
    fetchData(defaultFilter);
  }

  function onDeleteOpen(id) {
    setDeleteModal({ id, open: true });
  }

  function closeDeleteModel() {
    setDeleteModal({ ...initDeleteValues });
  }

  async function onDelete(event) {
    if (event) event.preventDefault();
    setIsLoading(true);

    // let response = await new RolesApi().deleteRole(deleteModal.id);
    let response = await deleteRole(deleteModal.id);

    closeDeleteModel();
    if (response && response.status === 200) {
      showToastMessage(undefined, response.data.message, "success", false);
      fetchData(defaultFilter);
    } else showToastMessage(undefined, response.data.message, "error", false);

    setIsLoading(true);
  }

  return (
    <React.Fragment>
      <Header
        title="Roles & Rights"
        hasAddButton={access.write_access}
        handleAddClick={openManageModal}
      />

      <div id="MainContent" className="main-content">
        {access.read_access && (
          <ReactTable
            columns={columns}
            data={data}
            fetchData={fetchData}
            loading={loading}
            pageCount={pageCount}
            noDataText={"There is no data to display"}
            defaultFilter={defaultFilter}
            displayGlobalSearch={false}
          />
        )}
      </div>

      {manageModal.open && access.write_access && (
        <ManageRole
          showModal={manageModal.open}
          data={manageModal.data}
          modules={modules}
          handleSubmitClick={onManageSubmit}
          handleCancelClick={closeManageModel}
        />
      )}

      {access.delete_access && (
        <DeleteConfirmation
          showModal={deleteModal.open}
          title="Delete Role"
          message="Are you sure want to delete this role?"
          handleCancelClick={closeDeleteModel}
          handleSubmitClick={onDelete}
        />
      )}
    </React.Fragment>
  );
};

export default Roles;
