import { getOrderList } from "api/order";
import { useLastOrderEditPath } from "Hooks/useLastOrderEditPath";
import { DateTime } from "luxon";
import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router";
import EditIcon from "../../../Assets/images/edit.svg";
import ViewIcon from "../../../Assets/images/error-outline.svg";
// Components
import Header from "../../../Components/Header/Header";
import ReactTable from "../../../Components/Table/Table";
import { AppContext } from "../../../Context/AppContext";
import { MODULES, ORDER_STATUS_FILTER } from "../../../Helpers/Constants";
import { formatUsPhone } from "../../../Hooks/commanUtility";
// Context
import { getAccessByModuleName } from "../../../Routes/Routes";
const Orders = (props) => {
  const { showToastMessage, modules } = useContext(AppContext);
  const { id, name } = props.match.params;

  // const statusList = [
  //   { id: "", title: "All" },
  //   { id: "pending", title: "Pending" },
  //   { id: "processing", title: "Processing" },
  //   { id: "in_design", title: "In Design" },
  //   { id: "design_completed", title: "Design Completed" },
  //   { id: "out_for_delivery", title: "Out for Delivery" },
  //   { id: "delivered", title: "Delivered" },
  //   { id: "cancel", title: "Cancel" },
  //   { id: "failed", title: "Failed" },
  // ];
  const statusList = ORDER_STATUS_FILTER;

  // Add / Edit
  const [access, setAccess] = useState({});
  const [customerId, setCustomerId] = useState(id);
  const [customerName, setCustomerName] = useState(name);

  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(1);

  const [defaultFilter, setDefaultFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
    sortBy: [],
    filters: [{ id: "order_status", value: "" }],
    globalFilter: "",
  });

  // const ordersService = useMemo(() => new OrdersApi(), []);

  const fetchData = async ({
    pageIndex,
    pageSize,
    sortBy,
    globalFilter,
    filters,
  }) => {
    setDefaultFilter({ pageSize, pageIndex, filters, globalFilter, sortBy });
    setLoading(true);

    const postData = {
      currentPage: pageIndex,
      itemsPerPage: pageSize,
      globalSearch: globalFilter,
      filters,
      sortBy,
      customer_id: customerId,
      is_not_customer_account_list:true
    };

    let orderAccess = getAccessByModuleName(modules, MODULES.ORDER_LOOKUP);
    if (orderAccess && orderAccess.permissions.read_access) {
      const response = await getOrderList(postData);
      if (response && response.status === 200) {
        setData(response.data.data.rows);
        setPageCount(Math.ceil(response.data.data.count / pageSize));
      } else {
        showToastMessage(undefined, response.data.message, "error", false);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!customerId) props.history.push(`/admin/customers/list`);
  }, [customerId]);

  /* Check Module Access */
  useEffect(getModuleAccess, [modules]);
  useLastOrderEditPath();

  function getModuleAccess() {
    let access = getAccessByModuleName(modules, MODULES.ORDER_LOOKUP);
    if (access) setAccess(access.permissions);
    if (access && !access.permissions.read_access) {
      showToastMessage(
        undefined,
        "You are not authorized to access.",
        "error",
        false
      );
      props.history.push(`/users`);
    }
  }

  let viewOrderAccess = getAccessByModuleName(modules, MODULES.VIEW_ORDER);
  let editOrderAccess = getAccessByModuleName(modules, MODULES.EDIT_ORDER);
  const columns = React.useMemo(
    () => [
      {
        Header: "Order No",
        accessor: "order_number",
        isVisible: true,
        placeholder: "Order No",
      },
      {
        Header: "Recipient Name",
        accessor: "recipient_name",
        isVisible: true,
        placeholder: "Recipient Name",
        Cell: ({ value, row, column }) => {
          return `${row.original.order_recipients[0].recipient_name}`;
        },
      },
      {
        Header: "Customer Phone",
        accessor: "customer_phone",
        isVisible: true,
        placeholder: "Customer Phone",
        Cell: ({ value, row, column }) => {
          return `${
            row.original.order_transaction.customer_phone
              ? formatUsPhone(row.original.order_transaction.customer_phone)
              : ""
          }`;
        },
      },
      {
        Header: "Recipient Phone",
        accessor: "recipient_phone",
        isVisible: true,
        placeholder: "Recipient Phone",
        Cell: ({ value, row, column }) => {
          return `${
            row.original.order_recipients[0].recipient_phone
              ? formatUsPhone(row.original.order_recipients[0].recipient_phone)
              : ""
          }`;
        },
      },
      {
        Header: "Delivery Date",
        accessor: "delivery_date_time",
        isVisible: true,
        placeholder: "Delivery Date",
        Cell: ({ value, row, column }) => {
          return `${
            row.original.delivery_date_time &&
            row.original.delivery_date_time !== null
              ? DateTime.fromISO(row.original.delivery_date_time)
                  .setZone("America/Los_Angeles")
                  .toFormat("ccc LLL dd, yyyy")
              : ""
          }`;
        },
      },
      {
        Header: "Price",
        accessor: "total",
        placeholder: "Price",
        Cell: ({ value, row, column }) => {
          return `$${row.original.total.toFixed(2)}`;
        },
      },
      {
        Header: "Customer Email",
        accessor: "customer_email",
        placeholder: "Customer Email",
        Cell: ({ value, row, column }) => {
          return `${row.original.order_transaction.customer_email}`;
        },
      },
      {
        Header: "Customer Address",
        accessor: "customer_address",
        placeholder: "Customer Address",
        Cell: ({ value, row, column }) => {
          return `${row.original.order_transaction.customer_address}`;
        },
      },
      {
        Header: "Recipient Address",
        accessor: "recipient_address",
        placeholder: "Recipient Address",
        Cell: ({ value, row, column }) => {
          return `${row.original.order_recipients[0].recipient_address}`;
        },
      },
      {
        Header: "Status",
        accessor: "order_status",
        isVisible: true,
        placeholder: "Status",
        isSelectFilter: true,
        selectFilterOption: statusList,
        colWidth: 160,
        Cell: ({ value, row, column }) => {
          let orderStatusData = statusList.filter(
            (status) => status.id === row.original.order_status
          );
          return `${
            orderStatusData.length ? orderStatusData[0]["title"] : "-"
          }`;
        },
      },
      {
        Header: "Type",
        accessor: "source",
        placeholder: "Type",
      },
      {
        Header: "External Order Number",
        accessor: "external_order_number",
        placeholder: "External Order Number",
      },
      {
        Header: "Shop Code",
        accessor: "shopcode",
        placeholder: "Shop Code",
      },
      {
        Header: "Actions",
        accessor: "id",
        isVisible: true,
        disableSortBy: true,
        Cell: ({ value, row, column }) => {
          let orderStatusDataArryy = statusList.filter(
            (status) => status.id === row.original.order_status
          );
          return (
            <div>
              {viewOrderAccess && viewOrderAccess.permissions.read_access ? (
                <img
                  className={`cursor-pointer mr-2 view-icon`}
                  src={ViewIcon}
                  alt="View Order"
                  onClick={(e) => {
                    e.preventDefault();
                    props.history.push(`/admin/orders/view/${row.original.id}`);
                  }}
                />
              ) : (
                <span style={{ width: 34, height: 34, display: "block" }} />
              )}
              {(orderStatusDataArryy[0]["title"] === "Processing" ||
                orderStatusDataArryy[0]["title"] === "Pending") &&
              editOrderAccess.permissions.write_access ? (
                <img
                  className={`cursor-pointer`}
                  src={EditIcon}
                  alt="Edit Order"
                  onClick={(e) => {
                    e.preventDefault();
                    props.history.push(`/admin/orders/edit/${row.original.id}`);
                  }}
                />
              ) : (
                <span style={{ width: 34, height: 34, display: "block" }} />
              )}
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <Header
        title={`Order History ${customerName ? "- " + customerName : ""}`}
      />

      <div id="MainContent" className="main-content">
        {access.read_access && (
          <ReactTable
            columns={columns}
            data={data}
            fetchData={fetchData}
            loading={loading}
            pageCount={pageCount}
            noDataText={"There is no data to display"}
            defaultFilter={defaultFilter}
            displayGlobalSearch={false}
            displayColumnVisibility={true}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default withRouter(Orders);
