import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import CardContent from "@material-ui/core/CardContent";
import Seo from "../../../Components/Cms/Seo";
import OGInfo from "../../../Components/Cms/OgInfo";
import Twitterinfo from "../../../Components/Cms/TwitterInfo";
// import Editer from "../../../Components/Cms/Schema";
import Card from "@material-ui/core/Card";
import Editer from "../../../Components/Cms/Schema";
import Header from "../../../Components/Header/Header";
import Banner from "../../../Components/Cms/Banner";
import FeaturedProduct from "../../../Components/Cms/FeaturedProduct";
import FeaturedCategory from "../../../Components/Cms/FeaturedCategory";
import Text from "../../../Components/FormControl/Text/TextInput";
import Goto from "../../../Components/Cms/Goto";
import PartnersLogo from "../../../Components/Cms/PartnersLogo";
import Certification from "../../../Components/Cms/Certification";

// Context
import { AppContext } from "./../../../Context/AppContext";
import { getAccessByModuleName } from "./../../../Routes/Routes";
import { DEFAULT_STORE_ID, MODULES } from "./../../../Helpers/Constants";
import { homePageDataCall } from "../../../Hooks/cms/homePageDataCall";
import HomePageProvider from "../../../Context/Cms-home-page/homePageContext";

import { useHomePage } from "../../../Context/Cms-home-page/useHomePage";

import HomePageApi from "../../../Services/HomePage/HomePage";
import UtilsApi from "../../../Services/Utils/Utils";
import { getFeaturedProduct } from "../../../api/utils";
import { getHomePage, updateHomePage } from "../../../api/homePage";
import classes from "./index.module.scss";

const HomePage = (props) => {
  const gotoList = [
    { id: "scrollable-text-section", title: "Scrollable Text" },
    { id: "banner-section", title: "Banner Style" },
    { id: "featured-product-section", title: "Featured Product" },
    { id: "featured-category-section", title: "Featured Category" },
    { id: "seo-section", title: "SEO" },
  ];
  const { showToastMessage, setIsLoading, modules } = useContext(AppContext);

  const [access, setAccess] = useState({});
  const [scrollableText, setScrollableText] = useState("");
  const [frontendTopBannertext, setFrontendTopBannertext] = useState("");
  const [scrollableError, setScrollableError] = useState("");
  const homePageService = new HomePageApi();
  const utilsService = new UtilsApi();

  const {
    homePageState,
    getHomePageDetails,
    getHomePageProductCategories,
    addNewBanner,
    removeBanner,
    setBannerData,
    checkBannerData,
    addNewPartner,
    removePartner,
    addNewCertification,
    removeCertification,
    setSeoData,
    checkSeoData,
    setOgInfoData,
    checkOgInfoData,
    setTwitterInfoData,
    checkTwitterInfoData,
    setSchemaData,
    checkSchemaData,
    setFeaturedProductData,
    checkFeaturedProductData,
    setFeaturedCategoryData,
    checkFeaturedCategoryData,
    addFeaturedProduct,
    deleteFeaturedProduct,
    addFeaturedCategory,
    deleteFeaturedCategory,
    setFeaturedProductSequence,
    setFeaturedCategorySequence,
  } = useHomePage();

  /* Check Module Access */
  useEffect(getModuleAccess, [modules]);

  function getModuleAccess() {
    let access = getAccessByModuleName(modules, MODULES.HOME_PAGE);
    if (access) setAccess(access.permissions);
    if (access && !access.permissions.write_access) {
      showToastMessage(
        undefined,
        "You are not authorized to access.",
        "error",
        false
      );
      props.history.push(`/users`);
    }
  }

  useEffect(() => {
    (async () => {
      let homePageAccess = getAccessByModuleName(modules, MODULES.HOME_PAGE);
      if (homePageAccess && homePageAccess.permissions.write_access) {
        await handleGetHomePage();
        await handleGetFeaturedProduct();
      }
    })();
  }, []);

  // get home page detail
  const handleGetHomePage = async () => {
    setIsLoading(true);
    // const response = await homePageService.getHomePage();
    const response = await getHomePage();

    if (response && response.status === 200) {
       //console.log('handleGetHomePage ===>', response.data.data);
      let customText = JSON.parse(response.data.data.scrollable_text)
      setScrollableText(customText.scrollable_text);
      setFrontendTopBannertext(customText.frontend_top_banner_text);
      
      getHomePageDetails(response.data.data);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
    setIsLoading(false);
  };

  // get delivery type list
  const handleGetFeaturedProduct = async () => {
    setIsLoading(true);
    // const response = await utilsService.getFeaturedProduct(1, "home-page");
    const response = await getFeaturedProduct(1, "home-page");

    if (response && response.status === 200) {
      // console.log("handleGetFeaturedProduct ===>", response.data.data);
      // getHomePageProductCategories(response.data.data);

      let temp_product_categories =
        response.data.data.length > 0 ? response.data.data : [];
      let product_categories = [];
      for (var i = 0; i < 4; i++) {
        let categoryData = temp_product_categories[i]
          ? temp_product_categories[i]
          : {
              is_main_section: false,
              id: 0,
              parent_id: 0,
              module: "home-page",
              sequence_number: 1,
              name: "",
              button_label: "",
              button_url: "",
              theme_color: "",
              description: "",
              main_parent_id: "",
              FeatureProducts: [],
              children: [],
            };
        let products = [];
        categoryData.FeatureProducts.forEach((productData) => {
          let product = {
            product_id: productData.product_id,
            sequence_number:
              productData.sequence_number > 0 ? productData.sequence_number : 1,
            id: productData.product.id,
            title: productData.product.title,
            product_images: productData.product.product_images,
          };
          products.push(product);
        });

        let children = [];
        if (i === 0) {
          for (var j = 0; j < 4; j++) {
            let childrenData = categoryData.children[j]
              ? categoryData.children[j]
              : {
                  is_main_section: false,
                  id: 0,
                  parent_id: 0,
                  module: "home-page",
                  sequence_number: 1,
                  name: "",
                  button_label: "",
                  button_url: "",
                  theme_color: "",
                  description: "",
                  main_parent_id: "",
                  FeatureProducts: [],
                };

            let childrenProducts = [];
            childrenData.FeatureProducts.forEach((productData) => {
              let childrenProduct = {
                product_id: productData.product_id,
                sequence_number:
                  productData.sequence_number > 0
                    ? productData.sequence_number
                    : 1,
                id: productData.product.id,
                title: productData.product.title,
                product_images: productData.product.product_images,
              };
              childrenProducts.push(childrenProduct);
            });

            let fproduct = {
              is_main_section: childrenData.is_main_section,
              id: childrenData.id,
              parent_id: childrenData.parent_id,
              module: childrenData.module,
              sequence_number:
                childrenData.sequence_number > 0
                  ? childrenData.sequence_number
                  : 1,
              name: childrenData.name,
              button_label: childrenData.button_label,
              button_url: childrenData.button_url,
              theme_color: childrenData.theme_color,
              description: childrenData.description,
              main_parent_id: childrenData.main_parent_id,
              products: childrenProducts,
            };
            children.push(fproduct);
          }
        }

        let fCategory = {
          is_main_section: categoryData.is_main_section,
          id: categoryData.id,
          parent_id: categoryData.parent_id,
          module: categoryData.module,
          sequence_number:
            categoryData.sequence_number > 0 ? categoryData.sequence_number : 1,
          name: categoryData.name,
          button_label: categoryData.button_label,
          button_url: categoryData.button_url,
          theme_color: categoryData.theme_color,
          description: categoryData.description,
          main_parent_id: categoryData.main_parent_id,
          products: products,
          children: children,
        };
        product_categories.push(fCategory);
      }

      // console.log(
      //   "product_categories ===>",
      //   temp_product_categories,
      //   product_categories
      // );
      getHomePageProductCategories(product_categories);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
    setIsLoading(false);
  };

  const handleScrollableText = (data) => {
    setScrollableText(data);
    if (!data || !data === null) {
      setScrollableError("Scrollable text required");
    } else {
      setScrollableError("");
    }
  };
  const handleFrontendTopBannertext = (data) => {
    setFrontendTopBannertext(data);
  };
  

  const handleSubmitClick = async (event) => {
    event.preventDefault();

    let showBannerError = false;
    homePageState.banners.forEach((bannerData, index) => {
      const bannerSubmit = document.getElementById(
        `banner-submit-button-${index}`
      );
      if (bannerSubmit) {
        bannerSubmit.click();
      }
      if (!bannerData.image) {
        showBannerError = true;
      }
    });

    let bannerRequired = false;
    if (homePageState.banners.length === 0) {
      bannerRequired = true;
    }

    let showProductError = false;
    homePageState.product_categories[0].children.forEach(
      (productData, index) => {
        const fProductSubmit = document.getElementById(
          `featured-product-submit-button-${index}`
        );
        if (fProductSubmit) {
          fProductSubmit.click();
        }
        // if(!productData.name) {
        //   showProductError = true;
        // }
      }
    );

    let showCategoryError = false;
    homePageState.product_categories.forEach((categoryData, index) => {
      const fCategorySubmit = document.getElementById(
        `featured-category-submit-button-${index}`
      );
      if (fCategorySubmit) {
        fCategorySubmit.click();
      }
      if (!categoryData.name) {
        showCategoryError = true;
      }
    });

    // const seoSubmit = document.querySelector(".seo-submit-button");
    // if (seoSubmit) {
    //   seoSubmit.click();
    // }

    // const ogInfoSubmit = document.querySelector(".og-info-submit-button");
    // if (ogInfoSubmit) {
    //   ogInfoSubmit.click();
    // }

    // const twitterSubmit = document.querySelector(".twitter-submit-button");
    // if (twitterSubmit) {
    //   twitterSubmit.click();
    // }

    // const schemaSubmit = document.querySelector(".schema-submit-button");
    // if (schemaSubmit) {
    //   schemaSubmit.click();
    // }

    // console.log('homePageState ===> ', homePageState);
    if (scrollableError) {
      showToastMessage(
        undefined,
        `Please enter required scrollable text`,
        "error",
        false
      );
    } else if (homePageState.banners.length > 0 && showBannerError) {
      showToastMessage(
        undefined,
        `Please enter required banner details`,
        "error",
        false
      );
    } else if (bannerRequired) {
      showToastMessage(
        undefined,
        `Please enter minimum 1 banner details`,
        "error",
        false
      );
    } else if (showProductError) {
      showToastMessage(
        undefined,
        `Please enter required featured product details`,
        "error",
        false
      );
    } else if (showCategoryError) {
      showToastMessage(
        undefined,
        `Please enter required featured category details`,
        "error",
        false
      );

      // }else if (homePageState.seo === null) {
      //   showToastMessage(
      //     undefined,
      //     `Please enter required seo details`,
      //     "error",
      //     false
      //   );
      // } else if (homePageState.seoError) {
      //   showToastMessage(
      //     undefined,
      //     `Please enter required seo details`,
      //     "error",
      //     false
      //   );
      // } else if (homePageState.ogInfoError) {
      //   showToastMessage(
      //     undefined,
      //     `Please enter required og info details`,
      //     "error",
      //     false
      //   );
      // } else if (homePageState.twitterError) {
      //   showToastMessage(
      //     undefined,
      //     `Please enter required twitter info details`,
      //     "error",
      //     false
      //   );
      // } else if (homePageState.schemaError) {
      //   showToastMessage(
      //     undefined,
      //     `Please enter required schema details`,
      //     "error",
      //     false
      //   );
    } else {
      const reqBody = homePageDataCall(homePageState, scrollableText,frontendTopBannertext);
      // console.log('handleSubmitClick ===> ', reqBody);
      // showToastMessage(undefined, "form submit successfully", "success", false);
      setIsLoading(true);
      // let response = await homePageService.updateHomePage(reqBody);
      let response = await updateHomePage(reqBody);
      if (response && response.status === 200) {
        showToastMessage(undefined, response.data.message, "success", false);
        await handleGetHomePage();
        await handleGetFeaturedProduct();
      } else {
        showToastMessage(undefined, response.data.message, "error", false);
      }
      setIsLoading(false);
    }
  };

  //Get current selected store Id
  let selectedStore = localStorage.getItem("selectedStore");

  // console.log('homePageState ===>', homePageState);
  return (
    <HomePageProvider>
      <React.Fragment>
        <Header title="CMS-Home Page" />
        <div className="row">
          <div id="MainContent" className="col-8">
            <div className={`${classes.cardBody}`}>
              {access.write_access && (
                <CardContent>
                  <div id={`scrollable-text-section`}>
                    <Text
                      type="text"
                      label="Scrollable Text"
                      value={scrollableText}
                      required={true}
                      onChange={(data) => handleScrollableText(data)}
                      touched={scrollableError ? true : false}
                      error={scrollableError}
                      disabled={selectedStore != DEFAULT_STORE_ID}
                    />
                  </div>
                  <div id={`homepage-top-banner-text-section`}>
                    <Text
                      type="text"
                      label="Frontend Top Banner Text"
                      value={frontendTopBannertext}
                      required={false}
                      onChange={(data) => handleFrontendTopBannertext(data)}
                      disabled={selectedStore != DEFAULT_STORE_ID}
                    />
                  </div>
                  
                  <Banner
                    bannerModule="home-page"
                    bannersData={homePageState.banners}
                    addNewBanner={addNewBanner}
                    removeBanner={removeBanner}
                    setBannerData={setBannerData}
                    checkBannerData={checkBannerData}
                    uploadImageType="HOME_PAGE_BANNER"
                    isDisableFields={selectedStore != DEFAULT_STORE_ID}
                  />
                  <FeaturedProduct
                    productsData={
                      homePageState?.product_categories[0]?.children
                    }
                    setFeaturedProductData={setFeaturedProductData}
                    checkFeaturedProductData={checkFeaturedProductData}
                    addProductList={(data) => addFeaturedProduct(data)}
                    removeProductList={(data) => deleteFeaturedProduct(data)}
                    setProductSequence={(data) =>
                      setFeaturedProductSequence(data)
                    }
                    isDisableFields={selectedStore != DEFAULT_STORE_ID}
                  />
                  <FeaturedCategory
                    categoriesData={homePageState.product_categories}
                    setFeaturedCategoryData={setFeaturedCategoryData}
                    checkFeaturedCategoryData={checkFeaturedCategoryData}
                    addProductList={(data) => addFeaturedCategory(data)}
                    removeProductList={(data) => deleteFeaturedCategory(data)}
                    setProductSequence={(data) =>
                      setFeaturedCategorySequence(data)
                    }
                    isDisableFields={selectedStore != DEFAULT_STORE_ID}
                  />
                  <PartnersLogo
                    partnersData={homePageState.worked_with}
                    addNewLogo={(data) => {
                      addNewPartner(data);
                    }}
                    removeLogo={removePartner}
                    isDisableFields={selectedStore != DEFAULT_STORE_ID}
                  />
                  <Certification
                    certificationsData={homePageState.certifications}
                    addNewLogo={(data) => {
                      addNewCertification(data);
                    }}
                    removeLogo={removeCertification}
                    isDisableFields={selectedStore != DEFAULT_STORE_ID}
                  />
                  <Seo
                    data={homePageState.seo}
                    setSeoData={setSeoData}
                    checkSeoData={checkSeoData}
                    isDisableFields={selectedStore != DEFAULT_STORE_ID}
                  />
                  <OGInfo
                    data={homePageState.seo}
                    setOgInfoData={setOgInfoData}
                    checkOgInfoData={checkOgInfoData}
                    isDisableFields={selectedStore != DEFAULT_STORE_ID}
                  />
                  <Twitterinfo
                    data={homePageState.seo}
                    setTwitterInfoData={setTwitterInfoData}
                    checkTwitterInfoData={checkTwitterInfoData}
                    isDisableFields={selectedStore != DEFAULT_STORE_ID}
                  />
                  {/* <Editer
                  data={homePageState.seo}
                  setSchemaData={setSchemaData}
                  checkSchemaData={checkSchemaData}
                /> */}
                </CardContent>
              )}
            </div>
          </div>
          <div className="col-4">
            <Goto handleSubmitClick={handleSubmitClick} gotoList={gotoList} isDisableFields={selectedStore != DEFAULT_STORE_ID}/>
          </div>
        </div>
      </React.Fragment>
    </HomePageProvider>
  );
};

export default withRouter(HomePage);
