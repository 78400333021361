import moment from "moment";
import React, { useContext, useState } from "react";
import { getFailedRefundReport } from "../../../api/report";
import CalendarIcon from "../../../Assets/images/calendar.svg";
import Date from "../../../Components/FormControl/Date/Date";
import Header from "../../../Components/Header/Header";
import ReactTable from "../../../Components/Table/Table";
import { AppContext } from "../../../Context/AppContext";
import { MODULES } from "./../../../Helpers/Constants";
import { getAccessByModuleName } from "./../../../Routes/Routes";
import styles from "./index.module.scss";
import CloseIcon from "../../../Assets/images/close.svg";
import SearchIcon from "../../../Assets/images/search.svg";
import { DateTime } from "luxon";
import { PST_ZONE } from "Pages/Order/Order/utility/util";
import ReactTooltip from "react-tooltip";
const FailedRefund = () => {
  const { showToastMessage, modules } = useContext(AppContext);
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [defaultFilter, setDefaultFilter] = React.useState({
    pageIndex: 0,
    pageSize: 10,
    filters: [],
    sortBy: [
      {
        id: "updatedAt",
        desc: "desc",
      },
    ],
  });
  let defaultStartDate = moment()
    .subtract(7, "days")
    .startOf("day")
    .format("ddd MMM DD, YYYY");
  let defaultEndDate = moment().endOf("day").format("ddd MMM DD, YYYY");
  const [fromDate, setFromDate] = useState(defaultStartDate);
  const [toDate, setToDate] = useState(defaultEndDate);

  // const [firstTimePageLoad, setFirstTimePageLoad] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(1);

  const handleClearSearch = async (event) => {
    event.preventDefault();
    setFromDate(defaultStartDate);
    setToDate(defaultEndDate);
    fetchData({
      ...defaultFilter,
      fromDateDefault: defaultStartDate,
      toDateDefault: defaultEndDate,
    });
  };

  // Date Validation
  const dateValidation = (fromDate, toDate) => {
    let startDate = moment(fromDate).format("x");
    let endDate = moment(toDate).format("x");
    if (startDate > endDate) {
      showToastMessage(
        undefined,
        "Start date should not be greater than from end date",
        "error",
        false
      );
      return false;
    }
    return true;
  };

  const fetchData = async ({
    pageIndex,
    pageSize,
    filters,
    fromDateDefault,
    toDateDefault,
    sortBy,
  }) => {
    if (
      !dateValidation(
        fromDateDefault ? fromDateDefault : fromDate,
        toDateDefault ? toDateDefault : toDate
      )
    ) {
      return false;
    }
    let startDate = fromDateDefault ? fromDateDefault : fromDate;
    startDate = moment(startDate).format("YYYY-MM-DD");
    let endDate = toDateDefault ? toDateDefault : toDate;
    endDate = moment(endDate).format("YYYY-MM-DD");
    setDefaultFilter({ pageSize, pageIndex, filters });
    setLoading(true);
    const postData = {
      currentPage: pageIndex,
      itemsPerPage: pageSize,
      start_date: startDate,
      end_date: endDate,
      filters,
      sortBy,
    };

    let accountAccess = getAccessByModuleName(modules, MODULES.REPORTS);
    if (accountAccess && accountAccess.permissions.read_access) {
      const response = await getFailedRefundReport(postData);
      if (response && response.status === 200) {
        setData(response.data.data.rows);
        setPageCount(Math.ceil(response.data.data.count / pageSize));
      } else {
        showToastMessage(undefined, response?.data?.message, "error", false);
      }
    }
    setLoading(false);
    setDefaultFilter({ pageSize, pageIndex, filters, sortBy });
  };

  const columns = React.useMemo(() => [
    {
      Header: "Order Number",
      accessor: "order_number",
      isVisible: true,
      Cell: ({ value, row, column }) => {
        return `${row.original?.order?.order_number ?? ""}`;
      },
    },
    {
      Header: "Refund Amount",
      accessor: "total_amount",
      isVisible: true,
    },
    {
      Header: "Failure Reason",
      accessor: "description",
      isVisible: true,
      disableFilters: false,
      disableSortBy: true,
      Cell: ({ value, row, column }) => {
        return row.original?.description?.length < 75 ? (
          `${row.original?.description ?? ""}`
        ) : (
          <div data-tip data-for="description">
            {row.original?.description.slice(0, 75) + "...."}
            <ReactTooltip
              id="description"
              effect="solid"
              place="top"
              arrowColor="#000"
              //   backgroundColor="#000"
              aria-haspopup="true"
              className="extraClass"
              delayHide={1000}
              delayShow={500}
              delayUpdate={500}
            >
              <p className="py-1 text-center">{row.original?.description}</p>
            </ReactTooltip>
          </div>
        );
      },
    },
    {
      Header: "Created On",
      accessor: "createdAt",
      colWidth: 280,
      isVisible: true,
      disableFilters: true,
      disableSortBy: false,
      Cell: ({ value, row, column }) => {
        return `${
          row.original.createdAt && row.original.createdAt !== null
            ? DateTime.fromISO(row.original.createdAt)
                .setZone(PST_ZONE)
                .toFormat("ccc LLL dd, yyyy, hh:mm a")
            : ""
        }`;
      },
    },
    {
      Header: "Failed On",
      accessor: "updatedAt",
      colWidth: 280,
      isVisible: true,
      disableFilters: true,
      Cell: ({ value, row, column }) => {
        return `${
          row.original.updatedAt && row.original.updatedAt !== null
            ? DateTime.fromISO(row.original.updatedAt)
                .setZone(PST_ZONE)
                .toFormat("ccc LLL dd, yyyy, hh:mm a")
            : ""
        }`;
      },
    },
  ]);

  return (
    <div>
      <Header title="Failed Refund" />
      <div className="row mb-5">
        <div className="col-sm-4 col-xs-12">
          <Date
            label="Start Date"
            value={fromDate}
            dateFormat="ddd MMM DD, YYYY"
            type="date"
            fieldIcon={CalendarIcon}
            onChange={(fromDate) => {
              setFromDate(moment(fromDate).format("ddd MMM DD, YYYY"));
            }}
          />
        </div>
        <div className="col-sm-4 col-xs-12">
          <Date
            label="End Date"
            value={toDate}
            dateFormat="ddd MMM DD, YYYY"
            type="date"
            fieldIcon={CalendarIcon}
            onChange={(toDate) => {
              setToDate(moment(toDate).format("ddd MMM DD, YYYY"));
            }}
          />
        </div>
        <div className="col-sm-2 col-xs-12">
          <div className="td-actions pt-4">
            <span
              onClick={(e) => fetchData(defaultFilter)}
              className={`link cursor-pointer ${styles.searchIconWrapper}`}
            >
              <img className={styles.searchIcon} src={SearchIcon} alt="" />
            </span>
            <span
              onClick={(e) => handleClearSearch(e)}
              className={`link cursor-pointer ml-3 ${styles.clearIconWrapper}`}
            >
              <img className={styles.clearIcon} src={CloseIcon} alt="" />
            </span>
          </div>
        </div>
      </div>
      <div>
        <ReactTable
          columns={columns}
          data={data}
          loading={loading}
          fetchData={fetchData}
          pageCount={pageCount}
          defaultFilter={defaultFilter}
          noDataText={"There is no data to display"}
          displayGlobalSearch={false}
          displayColumnVisibility={false}
          displayLastColumnSorting={true}
        />
      </div>
    </div>
  );
};
export default FailedRefund;
