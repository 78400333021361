import React from "react";
import styles from "./index.module.scss";
import FeaturedCategoryFrom from "./FeaturedCategoryCard";

const FeaturedCategory = (props) => {
  const categoriesData  = props.categoriesData ? props.categoriesData : [];
  // console.log('categoriesData ===>', categoriesData);

  return (
    <React.Fragment>
      <div
        className={`mb-2 ${styles.featuredTitle}`}
        id={`featured-category-section`}
      >
        Featured Category
      </div>
      {categoriesData && categoriesData?.map((category, index) => {
        return (
          <FeaturedCategoryFrom
            index={index}
            categoryData={category}
            setFeaturedCategoryData={props.setFeaturedCategoryData}
            checkFeaturedCategoryData={props.checkFeaturedCategoryData}
            addProductList={(data) => {
              props.addProductList(data);
            }}
            removeProductList={(data) => {
              props.removeProductList(data);
            }}
            setProductSequence={(data) => {
              props.setProductSequence(data);
            }}
            isDisableFields={props.isDisableFields}
          />
        )
      })}
    </React.Fragment>
  );
};
export default FeaturedCategory;