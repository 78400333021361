import {
  callDeleteApiv2,
  callGetApiv2,
  callPostApiv2,
  callPutApiv2,
} from "./api";

export async function getProducts(data, notoken) {
  return callPostApiv2("products", data, {}, notoken);
}

export async function getValidatedProduct(data, notoken) {
  return callPostApiv2("products/validate-product", data, {}, notoken);
}

export async function getProductsNormal() {
  return callPostApiv2("products/normal-list");
}

export async function getProductsWithSequence(data) {
  return callPostApiv2("products/product-sequence", data);
}

export async function setProductsWithSequence(data) {
  return callPostApiv2("products/set-product-sequence", data);
}

export async function getSaleProductsWithSequence() {
  return callPostApiv2("products/sale-product-sequence");
}

export async function setSaleProductsWithSequence(data) {
  return callPostApiv2("products/set-sale-product-sequence", data);
}

export async function getProductDetail(productId) {
  return callGetApiv2("products/" + productId);
}

export async function getProductDetailMinData(productId) {
  return callGetApiv2("products/productDetail/" + productId);
}

export async function getMenus() {
  return callGetApiv2("menus?menuFor=admin");
}

export async function getMenusNew(postData) {
  return callPostApiv2("menus/menu-admin",postData);
}

export async function createProduct(postData) {
  return callPostApiv2("products/add", postData);
}

export async function deleteProduct(id) {
  return callDeleteApiv2("products/" + id);
}

export async function updateProduct(id, postData) {
  return callPutApiv2("products/" + id, postData);
}

export async function getSimilarProductDetail(productId) {
  return callGetApiv2("products/similar/" + productId);
}
export async function updatesequnceProduct(post_data) {
  return callPostApiv2("products/update-sequence", post_data);
}

export async function getProductsListAdmin(data) {
  return callPostApiv2("products/admin-list", data);
}

export async function updateProductInventory(id, postData) {
  return callPutApiv2("products/product-inventory/update", postData);
}

export async function getProductsNewList(data, notoken) {
  return callPostApiv2("products/new-list", data, {}, notoken);
}
