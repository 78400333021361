import React from "react";
import { Bar } from "react-chartjs-2";
import ChartHeading from "./ChartHeading";

const CustomerGrowth = ({ customerGrowthData }) => {
  const data = {
    labels: customerGrowthData.label,
    datasets: [
      {
        label: "Repeat Customers",
        data: customerGrowthData.oldData,
        backgroundColor: "rgb(228,224,229)",
      },
      {
        label: "New Customer",
        data: customerGrowthData.newData,
        backgroundColor: "#c6c6c6",
      },
    ],
  };

  return (
    <>
      <div className="header">
        <ChartHeading heading="New vs Repeat Buyers" />
      </div>
      <Bar
        data={data}
        style={{ backgroundColor: "#FAFAFB", padding: "20px" }}
      />
    </>
  );
};

export default CustomerGrowth;
