// Packages
import axios from "axios";

// Cookies
import Cookies from "universal-cookie";
import jwt from "jsonwebtoken";
const cookies = new Cookies();

class Interceptors {
  constructor() {
    // Add a request interceptor

    axios.interceptors.request.use(
      function (config) {
        const accessToken = cookies.get("accessToken");
        if (accessToken) config["headers"]["authorization"] = accessToken;
        // config["headers"]["x-token"] = acc;
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    // Add a response interceptor
    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (error.response?.status === 401) {
          cookies.remove("role", { path: "/" });
          cookies.remove("defaultTimezone", { path: "/" });
          cookies.remove("accessToken", { path: "/" });
          window.location.href = "/login";
        }

        return Promise.reject(error);
      }
    );
  }

  GET(url) {
    let key = "!T7X!gsfE9Q&U!gk5V#kwJfYZSYHGB";
    let payload = {
      iss: "ffv2ApiAccess",
    };
    let acc = jwt.sign(payload, key, { expiresIn: 60 });
    return axios
      .get(process.env.REACT_APP_API_URL + url, { headers: { "x-token": acc , "requestfrom":"admin" } })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  POST(url, data) {
    let key = "!T7X!gsfE9Q&U!gk5V#kwJfYZSYHGB";
    let payload = {
      iss: "ffv2ApiAccess",
    };
    let acc = jwt.sign(payload, key, { expiresIn: 60 });
    //pass selected store id in header
    let headers = {}
    if(data?.selectedStore){
      headers["selectedstoreid"] = data?.selectedStore;
      delete data?.selectedStore;
    }
    return axios
      .post(process.env.REACT_APP_API_URL + url, data, {
        headers: { "x-token": acc , "requestfrom":"admin", ...headers},
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  PUT(url, data) {
    let key = "!T7X!gsfE9Q&U!gk5V#kwJfYZSYHGB";
    let payload = {
      iss: "ffv2ApiAccess",
    };
    let acc = jwt.sign(payload, key, { expiresIn: 60 });
    return axios
      .put(process.env.REACT_APP_API_URL + url, data, {
        headers: { "x-token": acc , "requestfrom":"admin" },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  DELETE(url) {
    let key = "!T7X!gsfE9Q&U!gk5V#kwJfYZSYHGB";
    let payload = {
      iss: "ffv2ApiAccess",
    };
    let acc = jwt.sign(payload, key, { expiresIn: 60 });
    return axios
      .delete(process.env.REACT_APP_API_URL + url, {
        headers: { "x-token": acc , "requestfrom":"admin" },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  PUT_FILE(url, data) {
    let key = "!T7X!gsfE9Q&U!gk5V#kwJfYZSYHGB";
    let payload = {
      iss: "ffv2ApiAccess",
    };
    let acc = jwt.sign(payload, key, { expiresIn: 60 });
    return axios
      .put(process.env.REACT_APP_API_URL + url, this.jsonToFormData(data), {
        headers: { "x-token": acc , "requestfrom":"admin" },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  jsonToFormData(data) {
    const formData = new FormData();
    this.buildFormData(formData, data);
    return formData;
  }

  buildFormData(formData, data, parentKey = "") {
    const refThis = this;
    if (
      data &&
      typeof data === "object" &&
      !(data instanceof Date) &&
      !(data instanceof File) &&
      !(data instanceof Blob)
    ) {
      Object.keys(data).forEach((key) => {
        refThis.buildFormData(
          formData,
          data[key],
          parentKey ? `${parentKey}[${key}]` : key
        );
      });
    } else {
      const value = data == null ? "" : data;

      formData.append(parentKey, value);
    }
  }
}

export default Interceptors;

export class NewInterceptors {
  GET(url) {
    let key = "!T7X!gsfE9Q&U!gk5V#kwJfYZSYHGB";
    let payload = {
      iss: "ffv2ApiAccess",
    };
    let acc = jwt.sign(payload, key, { expiresIn: 60 });
    return axios
      .get(process.env.REACT_APP_API_URL + url, { headers: { "x-token": acc , "requestfrom":"admin" } })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  POST(url, data) {
    let key = "!T7X!gsfE9Q&U!gk5V#kwJfYZSYHGB";
    let payload = {
      iss: "ffv2ApiAccess",
    };
    let acc = jwt.sign(payload, key, { expiresIn: 60 });
    let headers = { "x-token": acc , "requestfrom":"admin" };
    if(data?.selectedStore){
      headers["selectedstoreid"] = data?.selectedStore;
      delete data?.selectedStore;
    }else if(data?.zipcode){
      headers["zipcode"] = data?.zipcode;
      delete data?.zipcode;
    }
    return axios
      .post(process.env.REACT_APP_API_URL + url, data, {
        headers: headers,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  PUT(url, data) {
    let key = "!T7X!gsfE9Q&U!gk5V#kwJfYZSYHGB";
    let payload = {
      iss: "ffv2ApiAccess",
    };
    let acc = jwt.sign(payload, key, { expiresIn: 60 });
    return axios
      .put(process.env.REACT_APP_API_URL + url, data, {
        headers: { "x-token": acc , "requestfrom":"admin" },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  DELETE(url) {
    let key = "!T7X!gsfE9Q&U!gk5V#kwJfYZSYHGB";
    let payload = {
      iss: "ffv2ApiAccess",
    };
    let acc = jwt.sign(payload, key, { expiresIn: 60 });
    return axios
      .delete(process.env.REACT_APP_API_URL + url, {
        headers: { "x-token": acc , "requestfrom":"admin" },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  PUT_FILE(url, data) {
    let key = "!T7X!gsfE9Q&U!gk5V#kwJfYZSYHGB";
    let payload = {
      iss: "ffv2ApiAccess",
    };
    let acc = jwt.sign(payload, key, { expiresIn: 60 });
    return axios
      .put(process.env.REACT_APP_API_URL + url, this.jsonToFormData(data), {
        headers: { "x-token": acc , "requestfrom":"admin" },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  jsonToFormData(data) {
    const formData = new FormData();
    this.buildFormData(formData, data);
    return formData;
  }

  buildFormData(formData, data, parentKey = "") {
    const refThis = this;
    if (
      data &&
      typeof data === "object" &&
      !(data instanceof Date) &&
      !(data instanceof File) &&
      !(data instanceof Blob)
    ) {
      Object.keys(data).forEach((key) => {
        refThis.buildFormData(
          formData,
          data[key],
          parentKey ? `${parentKey}[${key}]` : key
        );
      });
    } else {
      const value = data == null ? "" : data;

      formData.append(parentKey, value);
    }
  }
}
