/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import AddIcon from "../../Assets/images/add.svg";
// SCSS
import styles from "./index.module.scss";

import { useProduct } from "../../Context/ProductCrud/useProduct";

import Products from "../Products/Products";
import { DEFAULT_STORE_ID } from "Helpers/Constants";

function SimilarProduct(props) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { productState } = useProduct();

  const addFeaturedCategory = () => {
    // console.log("Hello World");

    setIsDrawerOpen(true);
  };

  const handleCloseProduct = () => {
    setIsDrawerOpen(false);
  };

  //Get current selected store Id
  let selectedStore = localStorage.getItem("selectedStore");

  return (
    <div className={`${styles.productsWrapper}`} id="similar-products">
      <div className="">
        {props.offSet ? (
          ""
        ) : (
          <div className="">
            <div className={styles.title}>Similar Products</div>
            {productState.setPageForView === 1 ? (
              <span></span>
            ) : (
              selectedStore == DEFAULT_STORE_ID &&
                <span
                  className={`link cursor-pointer ml-2 ${styles.searchIconWrapper}`}
                  onClick={(e) => {
                    e.preventDefault();
                    addFeaturedCategory();
                  }}
                >
                  <img className={styles.searchIcon} src={AddIcon} alt="" />
                </span>
            )}
          </div>
        )}
      </div>
      <Products
        isVarianNeeded={false}
        hideFeaturedProduct={true}
        showSequenceNumber={false}
        addProduct={isDrawerOpen}
        openAddProductDrawer={setIsDrawerOpen}
        handleCloseProduct={handleCloseProduct}
        productList={props.productList}
        removeProductList={(data) => {
          props.removeProductList(data);
        }}
        addProductList={(data) => {
          props.addProductList(data);
        }}
        setPageForView={props.setPageForView}
      />
    </div>
  );
}

export default SimilarProduct;
