import { useContext } from "react";
import { DeliveryAddressContext } from "./deliveryAddressContext";
import * as deliveryAddressActions from "./deliveryAddressActions";

export function useDeliveryAddress() {
  const { deliveryAddressState, dispatch } = useContext(DeliveryAddressContext);

  const setDeliveryAddress = (values) => {
    dispatch({
      type: deliveryAddressActions.deliveryAddressActions.ADD_UPDATE_ADDRESS,
      payload: {
        ...values,
      },
    });
  };

  return {
    deliveryAddressState,
    setDeliveryAddress,
  };
}
