import { callGetApi, callPostApiv2 } from "api/api";
import { AppContext } from "Context/AppContext";
import { useFormikContext } from "formik";
import { DEFAULT_STORE_ID } from "Helpers/Constants";
import jwt from "jsonwebtoken";
import { useContext, useEffect, useState } from "react";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const accessToken = cookies.get("accessToken");

export function useProductCall() {
  const { setIsLoading, showToastMessage } = useContext(AppContext);
  const [render, setRender] = useState(0);
  const [currentSelected, setCurrentSelected] = useState(null);
  const [productsToAdd, setProductsToAdd] = useState([]);
  const [productList, setProductList] = useState([]);
  const [menu, setMenu] = useState([]);
  const [firstLoad, setFirstLoad] = useState(true);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [urlSlugData, setUrlSlugData] = useState(null);
  let itemsPerPage = 30;
  const [filter, setFilter] = useState({
    id: "url-slugs",
    value: ["flowers"],
  });
  const formik = useFormikContext();
  const [sort, setSort] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      
      let adminMenu = await localStorage.getItem("adminMenuData");
      if (adminMenu && adminMenu != null && adminMenu !== "") {
        setMenu(JSON.parse(adminMenu));
      } else {
        let postMenuData = {
          menuFor: "admin",
          position: "TOP",
        };

        await callPostApiv2("menus/menu-admin", postMenuData).then((c) => {
          setMenu(c.data.data);
          localStorage.removeItem("adminMenuData");
          localStorage.setItem("adminMenuData", JSON.stringify(c.data.data));
        });
      }

      // await callGetApi({ url: "menus?menuFor=admin" }).then((c) => {
      //   setMenu(c.data.data);
      // });
      getProducts();
      // console.log(data);

      // await getMenu();
      // await getProducts();
    })();
    setFirstLoad(false);
  }, []);

  useEffect(() => {
    if (!firstLoad) {
      (async () => {
        setIsLoading(true);
        await getProducts();
        setIsLoading(false);
      })();
    }
  }, [render]);

  const getProducts = async (query = "", page = 0) => {
    // let nonlocalOnly = !formik.values.delivery_information.is_local
    //   ? {
    //       id: "is_local_only",
    //       value: 0,
    //     }
    //   : null;
    let nonlocalOnly = null;
    let filters = [];
    let sortBy = [];
    filters.push({
      id: "type",
      value: "NON_ADD_ONS",
    });

    if (nonlocalOnly) {
      filters.push(nonlocalOnly);
    }

    if(!query){
      filters.push(filter);
    }

    sortBy.push({
      id: "sequence_number",
      asc: "asc",
    });

    if (sort) {
      sortBy.push(sort);
    }

    let postData = {
      currentPage: page,
      itemsPerPage,
      globalSearch: query,
      sortBy: sortBy,
      urlSlugData,
      filters: filters,
    };
    // const response = await axios.post(
    //   process.env.REACT_APP_API_URL + "products",
    //   postData,
    //   { headers: { authorization: "" } }
    // );
    let key = "!T7X!gsfE9Q&U!gk5V#kwJfYZSYHGB";
    let payload = {
      iss: "ffv2ApiAccess",
    };
    //delivery type then pass zipcode and store id if pickup order
    const orderType = formik.values.delivery_information.order_type;
    let extraData = {}
    if(orderType === 0){
      extraData['zipcode'] = formik.values.delivery_information.zipcode;
    }else{
      extraData['selectedstoreid'] = formik.values.billing_info.zip_code_store_id;
    }
    let acc = jwt.sign(payload, key, { expiresIn: 60 });
    const response = await fetch(process.env.REACT_APP_API_URL + "products", {
      method: "POST",
      body: JSON.stringify(postData),
      headers: {
        "Content-Type": "application/json",
        "x-token": acc,
        "requestfrom":"admin",
        authorization: accessToken,
        ...extraData

      },
    }).then((c) => c.json());
    response.data = { data: { ...response.data } };
    console.log(response);
    if (page) {
      setProductList([...productList, ...response.data.data.rows]);
    } else {
      setProductList(response.data.data.rows);
      setTotalCount(response.data.data.count);
      if (response?.data?.data?.urlSlugInfo) {
        setUrlSlugData({
          categoryHierarchyIds:
            response?.data?.data?.urlSlugInfo?.categoryHierarchyIds,
          hasLocation: response.data.data.urlSlugInfo.hasLocation,
        });
      }
    }
    setIsLoading(false);
  };
  return {
    menu,
    setFilter,
    setPage,
    render,
    setRender,
    setIsLoading,
    getProducts,
    setSort,
    page,
    totalCount,
    productsToAdd,
    productList,
    showToastMessage,
    setCurrentSelected,
    currentSelected,
    setProductsToAdd,
  };
}
