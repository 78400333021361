import React from "react";
import styles from "./index.module.scss";
import DeleteIcon from "../Assets/images/delete.svg";
import MultipalImage from "../Components/Cms/MultipalImage";
const ImageGallery = (props) => {
  let items = props.imageData;
  return (
    <React.Fragment>
      <div>
        <div className={`mt-4 ${styles.imageSize}`}>
          <div className="d-flex flex-wrap">
            {items.map((p, i) => (
              <div
                style={{ width: "180px", height: "220px" }}
                className="mb-3 px-2 position-relative"
                key={i}
              >
                {props.setPageForView === 1 || props.isDisableFields ? (
                  ''
                ) : (
                  <img
                    onClick={(e) => {
                      let it = items.filter((c, idx) => i !== idx);
                      if (it.length > 0)
                        if (p.is_default_selected)
                          it[0].is_default_selected = true;

                      props.handleRemoveImage(e, i);
                    }}
                    src={DeleteIcon}
                    style={{
                      position: "absolute",
                      top: "5px",
                      right: "14px",
                      cursor: "pointer",
                    }}
                    width="18px"
                    height="18px"
                    alt="DELETE"
                  />
                )}
                {props.isDisableFields ? (
                  <img
                    className="img-fluid"
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                    }}
                    src={p.src}
                    alt="Imag"
                  />
                ):(
                  <img
                    onClick={() => {
                      let it = [...items];
                      let updateItems = it.map((item, ind) => ({
                        ...item,
                        is_default_selected: ind === i ? true : false,
                      }));
                      props.updateProductImageData(updateItems);
                    }}
                    className="img-fluid"
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                      border: p.is_default_selected ? "3px solid #c3897b" : "none",
                    }}
                    src={p.src}
                    alt="Imag"
                  />
                )}
              </div>
            ))}
            {props.setPageForView === 1 || props.isDisableFields ? (
              <div></div>
            ) : (
              <div
                style={{ display: "grid", placeItems: "center" }}
                className={`mb-3 px-2 position-relative ${styles.marginImage}`}
              >
                <MultipalImage
                  multiple
                  accept="image/*"
                  handleUploadImage={props.handleUploadImage}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default ImageGallery;
