import { DEFAULT_COUNTRY_CODE, MODULES, STATUS_FILTER, DEFAULT_STORE_ID } from "./../../../Helpers/Constants";
import { getAccessByModuleName } from "./../../../Routes/Routes";
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
// Components
import ManageUserModal from "./../../../Components/Modal/User/Add/Add";
import DeleteConfirmation from "./../../../Components/Modal/DeleteConfirmation/DeleteConfirmation";
import LogoutAllUsersConfirmation from "./../../../Components/Modal/LogoutAllUsersConfirmation/LogoutAllUserConfirmation";
import StatusConfirmation from "../../../Components/Modal/StatusConfirmation/StatusConfirmation";
import Header from "./../../../Components/Header/Header";
import ReactTable from "./../../../Components/Table/Table";
import ProductOrderFilter from "../../../Components/ProductOrderFilter/ProductOrderFilter";

// Services
// import UsersApi from "./../../../Services/Users/Users";
import {
  getUserList,
  add,
  updateUser,
  deleteUser,
  logoutAllUsers,
} from "./../../../api/user";

import { getRoles } from "api/roles";
//scss
import styles from "./index.module.scss";

// Context
import { AppContext } from "./../../../Context/AppContext";
import { USER_TYPES } from "../../../Helpers/Constants";

// Images
import EditIcon from "./../../../Assets/images/edit.svg";
import DeleteIcon from "./../../../Assets/images/delete.svg";
import ActivatedIcon from "./../../../Assets/images/activated.svg";
import { updateStatus } from "api/utils";
import { storeList } from "api/stores";
import { setAllStore } from "Helpers/setAllStore";

const statusList = STATUS_FILTER;

let storeDefaultDesigner;

const Users = (props) => {
  const { showToastMessage, setIsLoading, modules } = useContext(AppContext);
  const [rolesList, setRolesList] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  //store
  const[storelist,setStorelist] = useState([]);
  const[userStore,setUserStore] = useState([]);
  const[userAssignStore,setUserAssignStore] = useState([])
  const [checkShowAll,setCheckShowAll] = useState(false);
  const [showLogoutAllUserModal, setShowLogoutAllUserModal] = useState(false);

  // Date Range Filter
  const [fromDate, setFromDate] = useState(
    moment().startOf("isoWeek").format("ddd MMM DD, YYYY")
    );
    const [toDate, setToDate] = useState(
    moment().endOf("isoWeek").format("ddd MMM DD, YYYY")
  );
  const [selectedPeriod, setSelectedPeriod] = React.useState("weekly");
  const [selectedPeriodLabel, setSelectedPeriodLabel] = React.useState(
    "By Week"
  );

  // Add / Edit
  const [username, setUsername] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [password, setPassword] = useState(null);
  const [email, setEmail] = useState(null);
  const [role, setRole] = useState(1);
  const [mobile, setMobile] = useState(null);
  const [countryCode, setCountryCode] = useState(DEFAULT_COUNTRY_CODE);
  const [active, setActive] = useState(0);
  const [userType, setUserType] = useState("sales_representative");
  const [productivity_low, setProductivityLow] = useState(null);
  const [productivity_high, setProductivityHigh] = useState(null);
  const [parentStore, setParentStore] = useState(DEFAULT_STORE_ID);

  const [deleteUserId, setDeleteUserId] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [manageUserPageTitle, setManageUserPageTitle] = useState("");

  const [access, setAccess] = useState({});

  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(1);

  const [defaultFilter, setDefaultFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
    sortBy: [],
    filters: [],
    globalFilter: "",

    // Date Range Filter
    fromDateDefault : "",
    toDateDefault : ""
  });

  // const usersService = new UsersApi();
  const [statusUserId, setStatusUserId] = useState(false);
  const [statusOfUser, setStatusOfUser] = useState();
  const [showStatusModal, setShowStatusModal] = useState(false);

  const fetchData = async() => {
    handleGetUsers({
      ...defaultFilter,
      fromDateDefault: moment(fromDate).format("YYYY-MM-DD"),
      toDateDefault: moment(toDate).format("YYYY-MM-DD"),
    });
  }

  const handleLogoutAllUserClick = async(event) => {
    event.preventDefault();
    setShowLogoutAllUserModal(true);
  }

  const handleAllUserLogout = async(event) => {
    event.preventDefault();
    setIsLoading(true);
    setAllStore(checkShowAll);
    const response = await logoutAllUsers();
    if (response && response.status === 200) {
      setShowLogoutAllUserModal(false);
      showToastMessage(undefined, response.data.message, "success", false);
    } else {showToastMessage(undefined, response.data.message, "error", false);}
    if(checkShowAll){ setAllStore(false) }
    setIsLoading(false);
  }

  const handleGetUsers = async ({
    pageIndex,
    pageSize,
    sortBy,
    globalFilter,
    filters,
    fromDateDefault,
    toDateDefault,
  }) => {
    setDefaultFilter({ pageSize, pageIndex, filters, globalFilter, sortBy, fromDateDefault, toDateDefault });
    setLoading(true);
    const reqBody = {
      currentPage: pageIndex,
      itemsPerPage: pageSize,
      globalSearch: globalFilter,
      filters,
      sortBy,
      start_date: fromDateDefault ? moment(fromDate).format("YYYY-MM-DD") : "",
      end_date: toDateDefault ? moment(toDate).format("YYYY-MM-DD") : "",
    };
    setIsLoading(true);
    let userAccess = getAccessByModuleName(modules, MODULES.USER);
    if (userAccess && userAccess.permissions.read_access) {
      //   const response = await usersService.getUserList(reqBody);
      const response = await getUserList(reqBody);

      if (response && response.status === 200) {
        setData(response.data.data.rows);
        storeDefaultDesigner = response.data.data.defaultStoresDesigners
        setPageCount(Math.ceil(response.data.data.count / pageSize));
      } else showToastMessage(undefined, response.data.message, "error", false);
    }
    setIsLoading(false);
    setLoading(false);
  };

  /* Check Module Access */
  useEffect(getModuleAccess, [modules]);

  // Date Range Handle Start
  const handleSelectedPeriod = async (id) => {
    if (id === "weekly") {
      setFromDate(moment().startOf("isoWeek").format("ddd MMM DD, YYYY"));
      setToDate(moment().endOf("isoWeek").format("ddd MMM DD, YYYY"));
      setSelectedPeriodLabel("By Week");
    } else if (id === "monthly") {
      setFromDate(moment().startOf("month").format("ddd MMM DD, YYYY"));
      setToDate(moment().endOf("month").format("ddd MMM DD, YYYY"));
      setSelectedPeriodLabel("By Month");
    } else if (id === "yearly") {
      setFromDate(moment().startOf("year").format("ddd MMM DD, YYYY"));
      setToDate(moment().endOf("year").format("ddd MMM DD, YYYY"));
      setSelectedPeriodLabel("By Year");
    }
  };
  const handleFromDate = async (data) => {
    if (selectedPeriod === "weekly") {
      setToDate(moment(data).add(7, "days").utc().format("ddd MMM DD, YYYY"));
    } else if (selectedPeriod === "monthly") {
      setToDate(moment(data).add(1, "months").utc().format("ddd MMM DD, YYYY"));
    } else if (selectedPeriod === "yearly") {
      setToDate(moment(data).add(1, "years").utc().format("ddd MMM DD, YYYY"));
    }
  };
  const handleToDate = async (data) => {
    if (selectedPeriod === "weekly") {
      setFromDate(
        moment(data).subtract(7, "days").utc().format("ddd MMM DD, YYYY")
      );
    } else if (selectedPeriod === "monthly") {
      setFromDate(
        moment(data).subtract(1, "months").utc().format("ddd MMM DD, YYYY")
      );
    } else if (selectedPeriod === "yearly") {
      setFromDate(
        moment(data).subtract(1, "years").utc().format("ddd MMM DD, YYYY")
      );
    }
  };
  const handleClearSearch = async (event) => {
    event.preventDefault();
    setFromDate(moment().startOf("isoWeek").format("ddd MMM DD, YYYY"));
    setToDate(moment().endOf("isoWeek").format("ddd MMM DD, YYYY"));
    setSelectedPeriod("weekly");
    handleGetUsers({
      ...defaultFilter,
      fromDateDefault: "",
      toDateDefault: "",
    });
  };
  // Date Range Handle End

  const handleGetRoles = async () => {
    
    const postData = {
      currentPage: 0,
      itemsPerPage: 100,
    };
    const response = await getRoles(postData);

    if (response && response.status === 200) {
      setRolesList(response.data.data.rows);
    } else {
      showToastMessage(undefined, response?.data?.message, "error", false);
    }
  };

  //get store
  const handleStore = async()=>{
    const res = await storeList();
    let storeListArr = [];
    res.data.data.rows.map((elem)=>{
      const storeObj = {
        id:elem.id,
        title:elem.title
      }
    storeListArr.push(storeObj)
    })
    setStorelist(storeListArr);
  }

  useEffect(() => {
    handleStore();
    let rolesAccess = getAccessByModuleName(modules, MODULES.ROLES_RIGHTS);
    if (rolesAccess && rolesAccess.permissions.read_access) {
      handleGetRoles();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function getModuleAccess() {
    let access = getAccessByModuleName(modules, MODULES.USER);
    let rolesAccess = getAccessByModuleName(modules, MODULES.ROLES_RIGHTS);
    if (access) setAccess(access.permissions);
    if (access && !access.permissions.read_access) {
      showToastMessage(
        undefined,
        "You are not authorized to access.",
        "error",
        false
      );
      window.location.href = "/dashboard";
    } else {
      if (rolesAccess && !rolesAccess.permissions.read_access) {
        showToastMessage(
          undefined,
          "You are not authorized to access.",
          "error",
          false
        );
        window.location.href = "/dashboard";
      }
    }
  }

  const handleManageUserClick = async (event, type = "add", user = {}) => {
    event.preventDefault();

    setUserDetails(user);
    setShowAddModal(true);
    setManageUserPageTitle("Create User");
    setUserAssignStore([])
    setUserStore([]);
    if (type === "edit") {
      setManageUserPageTitle("Edit User");
      setUsername(user.username);
      setFirstName(user.first_name);
      setLastName(user.last_name);
      setPassword(user.password);
      setMobile(user.mobile);
      setCountryCode(user.country_code);
      setEmail(user.email);
      setActive(user.status);
      setUserType(user.user_type);
      setRole(user.role_id);
      setProductivityLow(user.productivity_low);
      setProductivityHigh(user.productivity_high);
      setParentStore(user.store_id);
      
      let assignStore=[];
      const userStore = user.user_assign_stores.map((item)=>{
      return assignStore.push(item.store)
    })
      setUserAssignStore(assignStore)
      setUserStore(assignStore.map((obj) => {return obj.id }))
    }
  };

  const handleManageUser = async (event) => {
    if (mobile && mobile.length <= 10 && mobile.length >= 15) {
      showToastMessage(
        undefined,
        "Please enter valid mobile number",
        "error",
        false
      );
      event.preventDefault();
    }else if(!userStore.length){
      event.preventDefault();
      showToastMessage(
        undefined,
        "Please select access store",
        "error",
        false
      );
    } else {
      event.preventDefault();
      setSubmitStatus(true);
      if (
        username &&
        email &&
        password &&
        mobile &&
        productivity_low &&
        productivity_high &&
        firstName &&
        lastName &&
        role &&
        userType &&
        countryCode &&
        parentStore
      ) {
        setIsLoading(true);
        let reqBody = {
          username,
          email,
          password,
          mobile,
          productivity_low,
          productivity_high,
          first_name: firstName,
          last_name: lastName,
          role_id: role,
          status: active,
          user_type: userType,
          country_code: countryCode,
          user_assign_stores:userStore,
          store_id: parentStore,
        };
        let response;
        if (userDetails.id) {
          reqBody.id = userDetails.id;

          //   response = await usersService.updateUser(reqBody);
          response = await updateUser(reqBody);
        } else response = await add(reqBody);

        if (response && response.status === 200) {
          showToastMessage(undefined, response.data.message, "success", false);
          setShowAddModal(false);
          setSubmitStatus(false);
          handleGetUsers(defaultFilter);

          // Clear all value
          setUserDetails({});
          setFirstName(null);
          setLastName(null);
          setUsername(null);
          setPassword(null);
          setMobile(null);
          setCountryCode(DEFAULT_COUNTRY_CODE);
          setEmail(null);
          setActive(0);
          setUserType("sales_representative");
          setProductivityLow(null);
          setProductivityHigh(null);
          setRole(1);
          setParentStore(DEFAULT_STORE_ID);
        } else {
          showToastMessage(undefined, response.data.message, "error", false);
        }
        setIsLoading(false);
      }
    }
  };

  const handleDeleteUserClick = async (event, id) => {
    event.preventDefault();

    setDeleteUserId(id);
    setShowDeleteModal(true);
  };

  const handleDeleteUser = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    // const response = await usersService.delete(deleteUserId);
    const response = await deleteUser(deleteUserId);

    if (response && response.status === 200) {
      setDeleteUserId(null);
      setShowDeleteModal(false);
      showToastMessage(undefined, response.data.message, "success", false);
      handleGetUsers(defaultFilter);
    } else showToastMessage(undefined, response.data.message, "error", false);

    setIsLoading(false);
  };

  const handleStatusUserClick = async (event, id, status) => {
    event.preventDefault();

    setStatusUserId(id);
    setStatusOfUser(status);
    setShowStatusModal(true);
  };

  const handleUserStatus = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    let postData = {
      status: statusOfUser === 1 ? 0 : 1,
      id: statusUserId,
      table: "users",
    };

    // const response = await utilsService.updateStatus(postData);
    const response = await updateStatus(postData);
    if (response && response.status === 200) {
      setStatusUserId(null);
      setStatusOfUser(null);
      setShowStatusModal(false);
      showToastMessage(undefined, response.data.message, "success", false);
      handleGetUsers(defaultFilter);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }

    setIsLoading(false);
  };

  let userAccess = getAccessByModuleName(modules, MODULES.USER);
  const columns = React.useMemo(
    () => [
      {
        Header: "Username",
        accessor: "username",
        isVisible: true,
        placeholder: "Username",
      },
      {
        Header: "First Name",
        accessor: "first_name",
        isVisible: true,
        placeholder: "First Name",
      },
      {
        Header: "Last Name",
        accessor: "last_name",
        isVisible: true,
        placeholder: "Last Name",
      },
      {
        Header: "Email",
        accessor: "email",
        isVisible: true,
        placeholder: "Email",
      },
      {
        Header: "Role",
        accessor: "role",
        isVisible: true,
        placeholder: "Role",
        Cell: ({ value }) => {
          return value.title;
        },
      },
      {
        Header: "User Type",
        accessor: "user_type",
        isVisible: true,
        placeholder: "User Type",
        Cell: ({ value }) => {
          // return value ;
          if (value !== null) {
            let userType = USER_TYPES.filter((data) => data.id === value);
            return userType[0].title;
          } else {
            return "-";
          }
        },
      },
      {
        Header: "Mobile",
        accessor: "mobile",
        isVisible: true,
        placeholder: "Mobile",
        Cell: ({ value, row }) => {
          return row.original.country_code + " " + value;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        isVisible: true,
        placeholder: "Status",
        isSelectFilter: true,
        selectFilterOption: statusList,
        Cell: ({ value, row, column }) => {
          return (
            <div className={styles.status}>
              {row.original.status === 1 ? (
                <div className={styles.Active}>Active</div>
              ) : (
                <div className={styles.Inactive}>Inactive </div>
              )}
            </div>
          );
        },
      },
      {
        Header: "Actions",
        accessor: "id",
        isVisible: true,
        disableSortBy: true,
        Cell: ({ value, row, column }) => {
          
          return (
            <div>
              {userAccess && userAccess.permissions.write_access && (
                <img
                  onClick={(e) =>
                    handleManageUserClick(e, "edit", row.original)
                  }
                  className={`${styles.searchIcon} mr-4`}
                  src={EditIcon}
                  alt=""
                />
              )}
              
              {userAccess && userAccess.permissions.delete_access && !storeDefaultDesigner?.includes(value) && (
                <img
                  onClick={(e) => handleDeleteUserClick(e, value)}
                  className={`${styles.searchIcon} mr-4`}
                  src={DeleteIcon}
                  alt=""
                />
              )}

              {userAccess && userAccess.permissions.write_access ? (
                <img
                  className={`${styles.searchIcon}`}
                  src={ActivatedIcon}
                  alt=""
                  onClick={(e) =>
                    handleStatusUserClick(e, value, row.original.status)
                  }
                />
              ) : (
                <span className={styles.spacer} />
              )}
            </div>
          );
        },
      },
    ],
    []
  );
  
  return (
    <React.Fragment>
      <Header
        title="Users"
        hasAddButton={access.write_access}
        handleAddClick={handleManageUserClick}
      />

      <div id="MainContent" className="main-content" />
      {access.read_access && (
        <React.Fragment>
          <ProductOrderFilter
            selectedPeriodLabel={selectedPeriodLabel}
            selectedPeriod={selectedPeriod}
            setSelectedPeriod={setSelectedPeriod}
            handleSelectedPeriod={handleSelectedPeriod}
            fromDate={fromDate}
            setFromDate={setFromDate}
            handleFromDate={handleFromDate}
            toDate={toDate}
            setToDate={setToDate}
            handleToDate={handleToDate}
            fetchData={fetchData}
            handleClearSearch={handleClearSearch}
            defaultFilter={defaultFilter}
            AllStoreCheckbox={false}
            AllUserLogoutCheckbox={true}
            defaultSetCheckShowAll={checkShowAll}
            checkShowAll={(data)=>{setCheckShowAll(data)}}
            handleAllUserLogout={(e) => handleLogoutAllUserClick(e)}
          />
          <ReactTable
            columns={columns}
            data={data}
            fetchData={handleGetUsers}
            loading={loading}
            pageCount={pageCount}
            noDataText={"There is no data to display"}
            defaultFilter={defaultFilter}
            displayGlobalSearch={false}
          />
        </React.Fragment>
      )}

      {access.delete_access && (
        <DeleteConfirmation
          showModal={showDeleteModal}
          title="Delete User"
          message="Are you sure want to delete this user?"
          handleCancelClick={(event) => {
            event.preventDefault();
            setShowDeleteModal(false);
            setDeleteUserId(null);
          }}
          handleSubmitClick={handleDeleteUser}
        />
      )}

      {access.write_access && (
        <ManageUserModal
          storelist={storelist}
          userAssignStore={userAssignStore}
          showModal={showAddModal}
          pageTitle={manageUserPageTitle}
          rolesList={rolesList}
          username={username}
          firstName={firstName}
          lastName={lastName}
          password={password}
          email={email}
          role={role}
          mobile={mobile}
          countryCode={countryCode}
          active={active}
          userType={userType}
          submitStatus={submitStatus}
          productivity_low={productivity_low}
          productivity_high={productivity_high}
          parentStore={parentStore}
          handleCancelClick={(e) => {
            setShowAddModal(false);

            // Clear all value
            setUserDetails({});
            setFirstName(null);
            setLastName(null);
            setUsername(null);
            setPassword(null);
            setMobile(null);
            setCountryCode(DEFAULT_COUNTRY_CODE);
            setEmail(null);
            setActive(0);
            setUserType("sales_representative");
            setProductivityLow(null);
            setProductivityHigh(null);
            setSubmitStatus(null);
            setRole(1);
            setParentStore(DEFAULT_STORE_ID);
          }}
          handleSubmitClick={handleManageUser}
          onChange={(type, data) => {
            if (type === "firstName") setFirstName(data);
            if (type === "lastName") setLastName(data);
            if (type === "username") setUsername(data);
            if (type === "password") setPassword(data);
            if (type === "mobile") setMobile(data);
            if (type === "countyCode") setCountryCode(data);
            if (type === "email") setEmail(data);
            if (type === "role") setRole(data);
            if (type === "active") setActive(data);
            if (type === "userType") setUserType(data);
            if (type === "productivity_low") setProductivityLow(data);
            if (type === "productivity_high") setProductivityHigh(data);
            if (type === "storelist") setUserStore(data);
            if (type === "parentStore") setParentStore(data);
          }}
        />
      )}

      {access.write_access && (
        <StatusConfirmation
          showModal={showStatusModal}
          title={"User"}
          message={"user?"}
          buttonLabel={statusOfUser ? "Inactivate" : "Activate"}
          statusFlag={statusOfUser}
          customFlag={false}
          handleCancelClick={(event) => {
            event.preventDefault();
            setShowStatusModal(false);
            setStatusUserId(null);
            setStatusOfUser(null);
          }}
          handleSubmitClick={handleUserStatus}
        />
      )}

      {access.write_access && (
        <LogoutAllUsersConfirmation
          showModal={showLogoutAllUserModal}
          title="Logout All User"
          message="Are you sure want to logout of all users?"
          buttonLabel="Logout All"
          handleCancelClick={(event) => {
            event.preventDefault();
            setShowLogoutAllUserModal(false);
          }}
          handleSubmitClick={handleAllUserLogout}
        />
      )}
    </React.Fragment>
  );
};

export default Users;
